@import 'catalog/price';

@if($pricingEnabled) {
	.price {

    span {
      font-size: rem-calc(14);
    }

    .original-price {
      font-weight: bold;
      display: inline-block;
    }

    .offer-price,
    .range {
      font-weight: bold;
    }

    .current-price {
    }

  }
}
