$gridSearchHeaderEnabled: false !default;
$searchResultProductListTitleFontFamily: null !default;
$headerHeroDescFontSize: 13px !default;

$searchResultsShopFlowHeader: (
  'font': (
    'font-family': $body-font-family,
    'font-style': normal,
    'font-weight': 300,
    'text-transform': uppercase,
    'font-size': convert-to-em(55px),
    'letter-spacing': 0.05em
  ),
  'medium': (
    'font-size': 34px
  )
) !default;

@if($gridSearchHeaderEnabled) {
	#catalog.search {
		#master-container {
			background-color: $pageBackground;
		}

		#body-container {
			background-color: $white;
		}

		#search-results-header-container {
			@include grid-row();
			background-color: $pageBackground;
			float: none;
			margin-bottom: 15px;
			margin-left: auto;
			margin-right: auto;
			padding: 0 5px;
			position: initial;
			width: grid-column(12);

			.search-results-summary {
				@include grid-column(12);
				margin-top: 0;

				@include breakpoint(medium) {
					float: $global-left;
					padding: 0;
					width: grid-column(7.5);
				}

				.misspell,
				.did-you-mean {
					margin-bottom: 10px;
				}

			    h1 {
					@include setFontStyle($searchResultsShopFlowHeader);
				}
			}

			.options {
				@include grid-column(12);
				margin-top: 24px;
				text-align: center;

				@include breakpoint(medium) {
					float: right;
					padding-left: 0;
					padding-right: 0;
					text-align: right;
					width: grid-column(4.5);
				}

				#showProducts {
					margin-left: 0;
				}

				input {
					margin-left: 30px;
				}

				label {
					display: inline;
					padding-right: 0;
				}
			}
		}

		.page-meta h2.section-title {
			font-family: $searchResultProductListTitleFontFamily !important;
			font-style: normal;
		}
	}
}
