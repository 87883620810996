// ********************************
// *
// * Product Grid Component
// *
// * Displays CMS products in a 4-across grid.
// *
// *
// * Requires the brand-specific sass/cms/_mixins.scss
// *
// ********************************

@mixin cms-product-grid() {
	section.product-grid {
		@include component-headline();
	}
}
