$viewMoreFontColor: $hyperlinkText !default;
$viewMoreResultsFontSize: 13px !default;
$viewMoreResultsMargin: 0 !default;
$viewMoreResultsMarginMedium: null !default;
$viewMoreResultsGrid: 12 !default;
$viewMoreResultsGridMedium: null !default;

.view-more-results {
	border-top: 1px dashed $dashedRules;
	border-bottom: 1px dashed $dashedRules;
	padding: 10px 0;
	margin: $viewMoreResultsMargin;
	font-size: convert-to-em($viewMoreResultsFontSize);
	text-align: center;
	@include grid-column($viewMoreResultsGrid, 0);

	.counter:after {
		content: ' /';
	}

	.view-all {
		color: $viewMoreFontColor;
		cursor: pointer;

		&:hover {
			color: $fontLinkHover;
		}
	}
	@include breakpoint(medium) {
		margin: $viewMoreResultsMarginMedium;
		@if $viewMoreResultsGridMedium != null {
			@include grid-column($viewMoreResultsGridMedium, 0);
		}
	}
}
