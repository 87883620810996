@import 'catalog/product-block';
@import 'product/unmade-editor';

$productBlockColorSwatchHeight: rem-calc(28);
$cabanaMoreSwatchesFootprintOffset: rem-calc(5); //single side (top/left etc.)
$cabanaSwatchSizeOffsetOnMobile: rem-calc(2);
$cabanaColorSwatchTotalSize: $productBlockColorSwatchHeight + ($cabanaMoreSwatchesFootprintOffset * 2);

$cabanaPromoEyebrowVerticalMarginMobile: rem-calc(4);
$cabanaPromoEyebrowVerticalPaddingMobile: rem-calc(1);
$cabanaPromoEyebrowHorizontalPaddingMobile: rem-calc(6);
$cabanaPromoEyebrowVerticalMarginDesktop: rem-calc(5);
$cabanaPromoEyebrowVerticalPaddingDesktop: rem-calc(1);
$cabanaPromoEyebrowHorizontalPaddingDesktop: rem-calc(6);
$cabanaPromoEyebrowLineHeight: rem-calc(12.1);
$cabanaPromoEyebrowLabelVerticalPlaceholderMobile: $cabanaPromoEyebrowLineHeight + ($cabanaPromoEyebrowVerticalPaddingMobile * 2) + ($cabanaPromoEyebrowVerticalMarginMobile * 2);
$cabanaPromoEyebrowLabelVerticalPlaceholderDesktop: $cabanaPromoEyebrowLineHeight + ($cabanaPromoEyebrowVerticalPaddingDesktop * 2) + ($cabanaPromoEyebrowVerticalMarginDesktop * 2);

$cabanaPriceBlockBottomPadding: rem-calc(12);
$cabanaPriceBlockTotalHeight: ($product-block-info-line-height * 2) + $cabanaPriceBlockBottomPadding;

.product-block {
  &.cabana-style {

    padding: 0 rem-calc(2) 0 rem-calc(2);
    margin: 0 rem-calc(-4) rem-calc(17) 0;
    
    @include breakpoint(medium) {
      border: 0;    
      padding: 0 rem-calc(10) 0 rem-calc(10);
      margin: 0 rem-calc(-4) rem-calc(44) 0;
    }

    //default block dimension for desktop
    @include breakpoint(large) {    
      width: 25%;   
    }

    &:hover{
      @include breakpoint(medium) {
        border: none !important; //overwrite hover from shared product-block
      }
    }
    
    .info {
      display: flex;
      flex-direction: column;
      margin: $cabanaPromoEyebrowLabelVerticalPlaceholderMobile 0 0 0;
      text-align: left;

      @include breakpoint(medium) {
        margin: $cabanaPromoEyebrowLabelVerticalPlaceholderDesktop 0 0 0;
      }

      .price {
        align-items: flex-start;
        border-bottom: rem-calc(1) solid $light-gray;
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 rem-calc(10) 0;
        order: 3;
        padding: 0 0 rem-calc(12) 0;
        line-height: $product-block-info-line-height;
        height: $cabanaPriceBlockTotalHeight;
        span {
          font-size: rem-calc(11);
          @include breakpoint(medium) {
            font-size: rem-calc(14); 
          }
          &.only-offer-visible {
            color: $black !important;
          }
        }
        p {
          margin: 0 0 0 auto;
          text-align: right;
          line-height: $product-block-info-line-height;
        }
      }

      .original-price, .product-block-range-price:not(.current-price) {
        width: 100%;
        font-weight: 400;
      }

      .offer-price,
      .range {
        font-weight: 400;
      }

      .name {
        line-height: rem-calc(17);
        padding-bottom: rem-calc(2);
        margin-bottom: 0;
        order: 1;

        .product-block-name-wrapper {
          font-size: rem-calc(14);
          font-weight: 200;

          @include breakpoint(medium) {
            font-size: rem-calc(15);
          }
        }
      }

      .rating-container {
        height: auto;
        margin: rem-calc(4) 0 rem-calc(1) 0;
        order: 2;

        .rating {
          font-size: rem-calc(15);

          span {
            margin: 0 rem-calc(-2) 0 0;
          }
        }
      }

      @if($promoEyebrowEnabled) {
        .promo-eyebrow {
          line-height: $cabanaPromoEyebrowLineHeight;
          margin: ($cabanaPromoEyebrowVerticalMarginMobile - $cabanaPromoEyebrowLabelVerticalPlaceholderMobile) 0 $cabanaPromoEyebrowVerticalMarginMobile 0;
          padding: $cabanaPromoEyebrowVerticalPaddingMobile $cabanaPromoEyebrowHorizontalPaddingMobile;
          @include breakpoint(medium) {
            margin: ($cabanaPromoEyebrowVerticalMarginDesktop - $cabanaPromoEyebrowLabelVerticalPlaceholderDesktop) 0 $cabanaPromoEyebrowVerticalMarginDesktop 0;
            padding: $cabanaPromoEyebrowVerticalPaddingDesktop $cabanaPromoEyebrowHorizontalPaddingDesktop;
          }
        }
      }

      .color-swatches {
        display: flex;
        margin: 0;
        order: 4;
        min-height: $cabanaColorSwatchTotalSize;
        margin-top: -($cabanaMoreSwatchesFootprintOffset + $cabanaSwatchSizeOffsetOnMobile);
        height: $productBlockColorSwatchHeight;
        @include breakpoint(medium) {
          margin-top: -$cabanaMoreSwatchesFootprintOffset;
        }
        &.show-more{
          height: fit-content;
        }
      }

      .swatches-container {
        padding: ($cabanaMoreSwatchesFootprintOffset + $cabanaSwatchSizeOffsetOnMobile) 0 0 0;
        margin-left: 0;
        height: auto;
        position: unset;
        @include breakpoint(medium) {
          padding-top: $cabanaMoreSwatchesFootprintOffset;
          background-color: $productBlockHasShowMoreContainerBGColor;
        }
        .swatch {
          height: rem-calc(20) !important;
          width: rem-calc(20) !important;       
          @include breakpoint(medium) {
            height: rem-calc(25) !important;
            width: rem-calc(25) !important;
          }
        }
      }

      .has-show-more {
        .swatches-container {
          max-width: 100%;
          margin: 0;
        }

        .more-swatches {
          border: none;
          line-height: rem-calc(23);
          color: get-color(nobel);
          font-family: get-font('AkkuratPro');
          width: fit-content;
          right: rem-calc(75);
          position: initial;
          min-width: $cabanaColorSwatchTotalSize;
          height: $cabanaColorSwatchTotalSize;
          margin-left: rem-calc(5);
          user-select: none;
          font-size: rem-calc(13);

          @include breakpoint(medium) {
            font-size: rem-calc(14);
            right: rem-calc(20);
            line-height: rem-calc(29);
          }

          @include breakpoint(large) {
            margin-left: 0;
          }

          span {
            line-height: $cabanaColorSwatchTotalSize;
            font-size: rem-calc(13);
            vertical-align: middle;
           @include breakpoint(medium) {
              font-size: rem-calc(16);
            }
          }
        }
      }
    }

    .favorite-pos {
      position: absolute;
      right: rem-calc(10);
      top: rem-calc(5);

      button {
        background-color: transparent;

        &.icon-favorite-filled,
        &.icon-favorite {
          font-size: rem-calc(13);

          @include breakpoint(medium) {
            font-size: rem-calc(15);
          }
        }

        &.icon-favorite-filled {
          &:before {
            @include icomoonFont($icon-wishlist-full);
          }
        }

        &:before {
          @include icomoonFont($icon-wishlist);
        }
      }
    }
  }

  .info {

    .name {
      margin-bottom: 1em;

      a {
        text-decoration: none;
      }

      span {
        font-weight: normal;
      }
    }

    .rating-container {
      height: $iconStarContainerHeight;
      margin-top: rem-calc(10);
    }

    @if($promoEyebrowEnabled) {
      .promo-eyebrow {
        background: $black;
        color: $white;
        font-size: rem-calc(10);
        font-weight: normal;
        line-height: rem-calc(12);
        margin: rem-calc(-5) 0 rem-calc(10) 0;
        padding: rem-calc(1) rem-calc(8);
        position: static;
        text-align: left;
        text-transform: capitalize;
        width: fit-content;

        @include breakpoint(medium) {
          margin: 0 0 rem-calc(10) 0;
        }
      }
    }

    .color-swatches {
      @include breakpoint(medium) {
        min-height: $productBlockColorSwatchHeight;
      }

      &.show-more {
        @include breakpoint(medium) {
          height: fit-content;
        }
      }
    }

    .swatches-container {
      background-color: $productBlockHasShowMoreContainerBGColor;
      height: auto;
      padding: rem-calc(1);
    }

    .has-show-more {
      .more-swatches {
        span {
          font-size: rem-calc(9);
          @include breakpoint(medium) {
            font-size: rem-calc(10);
          }
        }
      }
    }
  }

  .promo-badge {
    background-color: $white;
    font-family: get-font('AkkuratPro');
    font-size: rem-calc(10);
    font-weight: $mol-media-gallery-product-views-promo-badge-font-weight;
    letter-spacing: normal;
    line-height: rem-calc(17);
    padding: 0.15rem 0.4rem 0.1rem 0.4rem;

    &.badge-visibility-hidden {
      visibility: hidden;
    }
  }

  .product-block-customise-btn {
    @include customise-secondary-btn(30px, 16px, 16px);
  }
}
