//Product List Page
.product-list-position-relative {
    position: relative;
}

#product-list {
	@extend %page-container-layout;
}

.grid,
.search {
  .page-contents {
    background: $BGSectionSeperatorA;
    margin: 0;
   }

  footer {
    margin-top: 0;
  }
}

figure {
  margin: 0;
}

.catalog {
  &.white-background-enabled {
    background-color: $white;
  }
}