//Compare Button colors
$compareBtnFontColorHover: $white !default;
$compareBtnBackgroundColorHover: $brandColorPalette1 !default;
$compareBtnBackgroundColorActive: $brandColorPalette1 !default;
$compareBtnFontColorActiveHover: $button-hover !default;
$compareBtnBackgroundColorActiveHover: $white !default;
$topFacetsEnabled: false !default;

$gridTopFilterBarBG: #2a2a2a !default;
$gridTopFilterBarDefaultFontColor: #fff !default;
$gridTopFilterBarSelectedFontColor: #2a2a2a !default;
$gridTopFilterBarSortByOptionColor: #dc241f !default;
$gridTopFilterBarSortByBorder: solid 1px #ccc !default;
$gridTopFilterBarDropdownBG: #fff !default;
$gridTopFilterBarFacetBG: #eee !default;
$gridTopFilterBarDropdownDivider: 1px solid #c7c7c7 !default;
$gridTopFilterBarAppliedFacetDrawerBG: #eee !default;
$gridTopFilterBarAppliedFacetBG: #fff !default;
$gridTopFilterBarCompareBtnBG: #000 !default;
$gridTopFilterBarSectionExpandedBG: #fff !default;
$gridTopFilterBarSectionExpandedColor: #000 !default;
$gridTopFilterBarInactiveFacetBorder: 4px solid transparent !default;
$gridTopFilterBarFacetSelectedBorder: 4px solid #c9192e !default;
$gridTopFilterBarFacetsBtnPadding: 18px 10px !default;
$gridTopFilterIconRemoveContent: $icon-close !default;
$gridTopFilterIconBackgroundColor: #eee !default;
$gridTopFilterIconColor: #000 !default;
$gridTopFilterBarSectionDivider: 1px solid #fff !default;
$gridTopFilterBarTopAndBottomPadding: 11px !default;
$sortByOptionDropdownTopPosition: 37px !default;
$facetsContainerDropdownTopPositionForMobile: $sortByOptionDropdownTopPosition !default;
$offSetfacetsbarHeightWhenDocked: 49px !default;
$topOfFacetsBar: 0 !default;


@if ($topFacetsEnabled) {

	.facets-bar {
		@include z-index(stickyfilter);
		position: relative;

		.facets-dropdown-bg {
			background-color: $gridTopFilterBarDropdownBG;
			border-bottom: $gridTopFilterBarDropdownDivider;
			position: absolute;
			z-index: 10;
		}
	}

	.facets-bar,
	.applied-facets-drawer {
		&.docked {
			position: relative;
			width: 100%;

			@include breakpoint(medium) {
				position: fixed;
				width: 93.75%;
			}
		}
	}

	.facets-bar.docked {
		@include z-index(stickyfilter);
		top: $topOfFacetsBar;
	}

	.applied-facets-drawer.docked {
		top: 0;
		z-index: 90;

		@include breakpoint(medium) {
			top: $offSetfacetsbarHeightWhenDocked;
		}
	}

	.facets-bar,
	.applied-facets-drawer,
	.applied-facets-mobile-container {
		@include grid-row();
		background-color: $gridTopFilterBarBG;

		.filter-group,
		.facets-btn,
		.sort-by-btn,
		.compare-btn {
			cursor: pointer;
			float: left;
			padding: 14px 2.3%;
			text-align: center;
		}

		.facets-bar-container {
			@include grid-row();
			position: relative;

			@include breakpoint(medium) {
				padding-left: 0;
				padding-right: 0;
			}

			&.collapsed {
				.facets-container {
					display: none;

					@include breakpoint(large) {
					display: block;
					}
				}

				.facets-btn {
					background: $gridTopFilterBarBG;
				}
			}

			.facets-btn {
				background: #fff;
				float: left;
			}
		}

		.facets-container {
			background-color: #fff;
			border-bottom: 1px solid #cbcbcb;
			margin-bottom: 20px;
			padding: 20px;
			position: absolute;
			top: $facetsContainerDropdownTopPositionForMobile;
			width: 100%;
			z-index: 100;

			@include breakpoint(medium) {
				border-bottom: 0;
				float: $global-left;
				padding: 0;
				position: absolute;
				top: 47px;
				width: grid-column(5.3);
			}

			@include breakpoint(large) {
				background-color: $gridTopFilterBarBG;
				margin: 0;
				padding: 0;
				top: 0;
				width: grid-column(9);
			}

			ul {
				list-style-type: none;
			}
		}

		.filter-group,
		.sort-by-btn {
			border-left: 0;
			border-top: $gridTopFilterBarSectionDivider;
			display: block;
			padding: $gridTopFilterBarFacetsBtnPadding;

			@include breakpoint(large) {
				border-left: $gridTopFilterBarSectionDivider;
				border-top: 0;
				padding: 14px 2.3%;
			}
		}

		.compare-btn {
			display: none;

			@include breakpoint(medium) {
				@include setFontStyle($compareBtnFontStyle);
				background-color: $gridTopFilterBarCompareBtnBG;
				display: block;
				float: $global-left;
				padding: 18px 0;
				width: grid-column(2.5);
			}

			@include breakpoint(large) {
				padding: 18.5px 0;
				width: grid-column(1.7);
			}

			&:hover {
				background-color: $compareBtnBackgroundColorHover;
				color: $compareBtnFontColorHover;
			}

			&.active {
				background-color: $compareBtnBackgroundColorActive;

				&:hover {
			 		background-color: $compareBtnBackgroundColorActiveHover;
			 		color: $compareBtnFontColorActiveHover;
				}
			}
		}

		.filter-group.expanded {
			background-color: $gridTopFilterBarSectionExpandedBG;

			.filter-title {
				color: $gridTopFilterBarSectionExpandedColor;
			}
		}

		.facets-btn {
			background-color: $gridTopFilterBarBG;
			border-top: $gridTopFilterBarSectionDivider;
			display: block;
			padding: $gridTopFilterBarTopAndBottomPadding 10px;
			text-align: left;
			width: 33%;

			@include breakpoint(medium) {
				float: $global-left;
				padding: 17px;
				width: grid-column(5.3);
			}

			@include breakpoint(large) {
				display: none;
				width: 100%;
			}

			.filter-title {
				@include setFontStyle($facetTitleFontStyle);
			}
		}

		.facet-options-container {
			background-color: $gridTopFilterBarDropdownBG;
			border-bottom: 0;
			margin: 0;
			padding-top: 0;
			position: relative;
			top: 0;
			width: 100%;
			z-index: 100;

			@include breakpoint(large) {
				border-bottom: $gridTopFilterBarDropdownDivider;
				padding-top: 20px;
				position: absolute;
				top: 50px;
				width: $global-width;
			}
		}

		.facet-option {
			cursor: pointer;
			display: table;
			float: left;
			height: 50px;
			margin-bottom: 20px;
			padding: 0;
			padding-right: 10px;
			width: 50%;

			@include breakpoint(medium) {
				padding-left: 0;
				padding-right: 2 * (0.76923em);
				width: 187px;
			}

			&.last {
				padding-right: 0;
				width: 50%;

				@include breakpoint(medium) {
					width: 168px;
				}
			}

			&:nth-child(even) {
				padding-left: 10px;
				padding-right: 0;

				@include breakpoint(medium) {
					padding-left: 0;
					padding-right: 2 * (0.76923em);
				}
			}
		}

		.facet-option-title {
			@include setFontStyle($facetOptionFontStyle);
			margin-right: 20px;
		}

		.facet-option-visual {
			border: 1px solid #fff;
			height: 23px;
			left: 0.76923em;
			position: absolute;
			top: 9px;
			width: 23px;
		}

		.centering-container {
			background-color: $gridTopFilterBarFacetBG;
			border-bottom: $gridTopFilterBarInactiveFacetBorder;
			border-top: $gridTopFilterBarInactiveFacetBorder;
			display: table-cell;
			line-height: 15px;
			padding-left: 0.76923em;
			padding-right: 0.76923em;
			padding-top: 2px;
			position: relative;
			text-transform: uppercase;
			vertical-align: middle;
			word-break: break-word;

			@include breakpoint(large) {
				padding-right: 0;
				word-break: normal;
			}

			&:hover,
			&.selected  {
				border-top: $gridTopFilterBarFacetSelectedBorder;
				position: relative;
			}
		}

		.swatch-option {
			.centering-container {
				padding-left: 42px;
			}
		}

		.filter-group-btn {
			border-top: $gridTopFilterBarSectionDivider;

			@include breakpoint(large) {
				border-top: 0;
			}

			.filter-group {
				background-color: $gridTopFilterBarBG;
				margin-bottom: 20px;
				text-align: left;
				width: 100%;

				@include breakpoint(large) {
					background-color: transparent;
					margin-bottom: 0;
					width: inherit;
				}

				&:hover {
					background-color: rgba(0, 0, 0, 0.1);
					position: relative;
				}
			}

			&.selected .filter-group {
				background-color: rgba(0, 0, 0, 0.2);
				position: relative;
			}

			.filter-title {
				@include setFontStyle($facetTitleFontStyle);
			}
		}

		.filter-group-btn.collapsed {

			.filter-group {
				background-color: $gridTopFilterBarBG;
				margin-bottom: 0;
				text-align: left;
				width: 100%;

				@include breakpoint(large) {
					background-color: transparent;
					width: inherit;
				}

				&:hover {
					background-color: rgba(0, 0, 0, 0.1);
					position: relative;
				}
			}

			.filter-title {
				@include setFontStyle($facetTitleFontStyle);
				color: $gridTopFilterBarDefaultFontColor;
			}

			.facet-options-container {
				display: none;
			}
		}

		.sort-by-btn {
			@include setFontStyle($facetTitleFontStyle);
			background-color: $gridTopFilterBarDropdownBG;
			border-left: 1px solid #fff;
			display: inline-block;
			float: right;
			padding: 0;
			position: relative;
			text-align: left;
			width: 67%;

			@include breakpoint(medium) {
				display: block;
				float: $global-left;
				width: grid-column(4.2);
			}

			@include breakpoint(large) {
				width: grid-column(2.3);
			}

			.sort-by-label {
				@include setFontStyle($sortByLabelFontStyle);
				color: $gridTopFilterBarSelectedFontColor;
				padding-bottom: 10px;
				padding-left: convertPxToVw(10px, $vwunit-base);
				padding-top: 10px;
			}

			.sort-by-option {
				padding: 5px 0;

				&:hover {
					color: $gridTopFilterBarSortByOptionColor;
				}
			}

			.sort-by-options {
				background-color: $gridTopFilterBarDropdownBG;
				border-bottom: $gridTopFilterBarSortByBorder;
				border-left: $gridTopFilterBarSortByBorder;
				border-right: $gridTopFilterBarSortByBorder;
				color: $gridTopFilterBarSelectedFontColor;
				list-style-type: none;
				padding: 15px;
				padding-bottom: 7px;
				padding-top: 0;
				position: absolute;
				right: 1px;
				text-align: left;
				top: $sortByOptionDropdownTopPosition;
				width: 100%;
				z-index: 100;

				@include breakpoint(medium) {
					right: -1px;
					top: 50px;
					width: 101%;
				}

				.sort-by-option:first-child {
					border-top: $gridTopFilterBarSortByBorder;
					padding-top: 15px;
				}
			}

			.sort-by-label,
			.sort-by-group {
				color: $gridTopFilterBarSelectedFontColor;
			}

			.sort-by-group {
				padding: $gridTopFilterBarTopAndBottomPadding;
				padding-left: convertPxToVw(3px, $vwunit-base);
				padding-right: convertPxToVw(20px, $vwunit-base);

				@include breakpoint(medium) {
					padding: 18px 17px;
				}

				@include breakpoint(large) {
					padding: 18px 0;
				}
			}

			&.collapsed {
				background-color: transparent;

				.sort-by-options {
					display: none;
				}

				.sort-by-label,
				.sort-by-group {
					color: $gridTopFilterBarDefaultFontColor;
				}
			}
		}
	}

	.facets-bar {
		@include breakpoint(medium) {
				width: 93.75%;
		}

		.applied-facets-mobile-container {
			clear: both;

			.applied-facets {
				width: 58%;
			}

			.centering-inner {
				max-width: 88%;
			}

			.facet-option,
			.facet-option.last {
				width: 100%;
			}
		}
	}

	.applied-facets-drawer,
	.applied-facets-mobile-container {
		background-color: $gridTopFilterBarAppliedFacetDrawerBG;

		@include breakpoint(medium) {
				width: 93.75%;
		}

		.applied-facets-container {
			@include grid-row();

			@include breakpoint(medium) {
				padding: 0 8px;
			}
		}

		.facet-option {
			height: 58px;
		}

		.facet-option-visual {
			top: 14px;
		}

		.applied-facets {
			float: left;
			margin: 20px 0 0;
			max-width: 100%;
			padding: 0 20px;
			position: relative;
			width: 100%;

			@include breakpoint(medium) {
				max-width: 76%;
				padding: 0;
				width: auto;
			}
		}

		.facet-option-title {
			word-wrap: break-word;
		}

		.graphic-icon-remove {
			background: $gridTopFilterIconBackgroundColor;
			border-radius: 25px;
			display: inline-block;
			height: 17px;
			position: absolute;
			right: 10px;
			top: 16px;
			width: 17px;

			&:before {
				@include icomoonFont($gridTopFilterIconRemoveContent);
				border-radius: 25px;
				color: $gridTopFilterIconColor;
		    	display: inline-block;
		    	font-size: 8px;
		    	padding: 5px;
    			position: relative;
    			transition: background 300ms ease-out;
			}

			&:hover {
				&::before {
					background: $button-hover;
					color: #fff;
		  		}
	    	}
		}

		.centering-container {
			background-color: $gridTopFilterBarAppliedFacetBG;
			padding-right: 0.76923em;
			position: relative;
		}

		.centering-inner {
			margin: 0;
		}

		.applied-facets-actions {
			clear: both;
			margin-top: 20px;
			padding: 0 16px;
			position: relative;

			@include breakpoint(medium) {
				clear: none;
			}
		}

		.clear-all-filters-btn {
			float: left;
			padding: 22px 0;
		}

		.clear-all {
			@include setFontStyle($facetClearAllFontStyle);
		}

		.filters-page-numbering {
			float: right;
			position: relative;
			right: 0;
			top: 7px;
			z-index: 1;
		}
	}

}
