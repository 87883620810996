@import 'global/z-indexes';

@mixin product-badge-module {
  $product-badge-module-display: flex !default;
  $product-badge-module-align-items: center !default;
  $product-badge-module-margin: 0 0 rem-calc(5) 0 !default;
  $product-badge-icon-height: rem-calc(20) !default;
  $product-badge-icon-width: rem-calc(20) !default;
  $product-badge-icon-margin: 0 rem-calc(5) 0 0 !default;
  $product-badge-label-font-size: rem-calc(12) !default;
  $product-badge-label-line-height: rem-calc(16) !default;
  $product-badge-label-font-family: null !default;

  .product-badge-module {
    align-items: $product-badge-module-align-items;
    display: $product-badge-module-display;
    margin: $product-badge-module-margin;

    img {
      height: $product-badge-icon-height;
      margin: $product-badge-icon-margin;
      width: $product-badge-icon-width;
    }

    figcaption,
    label {
      font-family: $product-badge-label-font-family;
      font-size: $product-badge-label-font-size;
      line-height: $product-badge-label-line-height;
    }
  }
}

@mixin plp-product-badge-mobile-overlay {
  $plp-product-badge-module-promo-badge-overlay-left: 0 !default;
  $plp-product-badge-module-promo-badge-overlay-top: rem-calc(20) !default;
  $plp-product-badge-module-promo-badge-overlay-z-index: 1 !default;

  .product-badge-module {
    display: block;
    position: relative;

    .promo-badge {
      left: $plp-product-badge-module-promo-badge-overlay-left;
      position: absolute;
      top: $plp-product-badge-module-promo-badge-overlay-top;
      z-index: $plp-product-badge-module-promo-badge-overlay-z-index;
    }
  }
}

@mixin plp-product-badge-module {
  $plp-product-badge-module-promo-badge-padding: rem-calc(2) rem-calc(5) rem-calc(2) 0 !default;
  $plp-product-badge-module-promo-badge-img-width: rem-calc(26) !default;
  $plp-product-badge-module-promo-badge-img-height: rem-calc(26) !default;
  $plp-product-badge-module-promo-badge-figcaption-font-size: rem-calc(13px) !default;
  $plp-product-badge-module-promo-badge-figcaption-letter-spacing: rem-calc(5/12) !default;
  $plp-product-badge-module-promo-badge-figcaption-line-height: rem-calc(16) !default;
  $plp-product-badge-module-promo-badge-figcaption-margin: 0 0 0 rem-calc(5) !default;
  $plp-product-badge-module-promo-badge-figcaption-font-family: null !default;
  $plp-product-badge-module-2-column-promo-badge-padding: rem-calc(8) rem-calc(5) rem-calc(8) 0 !default;
  $plp-product-badge-module-1-column-promo-badge-margin: 0 0 rem-calc(5) 0 !default;
  $plp-product-badge-module-1-column-promo-badge-img-margin: 0 rem-calc(5) 0 0 !default;
  $plp-product-badge-module-1-column-promo-badge-figcaption-margin: 0 !default;
  $plp-product-badge-module-promo-badge-figcaption-font-weight: null !default;

  .catalog-container {
    .product-badge-module {
      // Default
      display: none;

      .promo-badge {
        align-items: center;
        background-color: $white;
        display: flex;
        padding: $plp-product-badge-module-promo-badge-padding;

        img {
          height: $plp-product-badge-module-promo-badge-img-width;
          width: $plp-product-badge-module-promo-badge-img-height;
        }

        figcaption {
          display: inline;
          font-family: $plp-product-badge-module-promo-badge-figcaption-font-family;
          font-size: $plp-product-badge-module-promo-badge-figcaption-font-size;
          font-weight: $plp-product-badge-module-promo-badge-figcaption-font-weight;
          letter-spacing: $plp-product-badge-module-promo-badge-figcaption-letter-spacing;
          line-height: $plp-product-badge-module-promo-badge-figcaption-line-height;
          margin: $plp-product-badge-module-promo-badge-figcaption-margin;
        }
      }
    }

    @include breakpoint(medium) {
      @include plp-product-badge-mobile-overlay;
    }

    .column-6-layout,
    .column-2-layout,
    .column-4-layout {
      .product-badge-module {
        display: none;
      }
    }

    .column-2-layout,
    .column-4-layout {
      // Show product badge overlay in 2 and 4 column in desktop
      @include breakpoint(medium) {
        @include plp-product-badge-mobile-overlay;
      }
    }
  }

  .column-2-layout {
    .product-badge-module {
      .promo-badge {
        padding: $plp-product-badge-module-2-column-promo-badge-padding;
      }
    }
  }

  .column-1-layout {
    .product-badge-module {
      // Show product badge in 1-column layout in mobile
      display: block;
      @include breakpoint(medium) {
        display: none;
      }

      .promo-badge {
        margin: $plp-product-badge-module-1-column-promo-badge-margin;

        img {
          margin: $plp-product-badge-module-1-column-promo-badge-img-margin;
        }

        figcaption {
          margin: $plp-product-badge-module-1-column-promo-badge-figcaption-margin;
        }
      }
    }
  }
}

@mixin plp-product-badge-Secondary {
$plp-promo-badge-secondary-background: $white !default;

  .product-block-figure {
    .promo-badge {
      right: rem-calc(3);
      top: rem-calc(2);
      width: auto;
      max-width: rem-calc(41);
      z-index: z-index(badges);
      @include breakpoint(medium) {
        right: rem-calc(5);
        top: rem-calc(3);
        max-width: rem-calc(56);
      }
    }

    .promo-badge-Secondary {
      background: $plp-promo-badge-secondary-background;
      border-radius: rem-calc(9);
      font-family: get-font('Neuzeit Grotesk');
      font-size: rem-calc(12);
      font-weight: 700;
      left: rem-calc(6);
      letter-spacing: rem-calc(0.92);
      line-height: rem-calc(15);
      padding: rem-calc(3) rem-calc(7) rem-calc(2) rem-calc(7);
      position: absolute;
      top: rem-calc(11);
      width: fit-content;
      z-index: z-index(badges);
      @include breakpoint(large) {
        left: rem-calc(8);
        top: rem-calc(20);
      }
    }
  }
}
