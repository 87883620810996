$pricingEnabled: false !default;
$productInfoOriginalPriceMobileDisplay: none !default;
$product-block-price-display: inline-block !default;
$product-block-price-original-price-margin-right: 0 !default;
$product-block-price-original-price-margin-right-large: null !default;
$product-block-percentage-price-display: null !default;

@if($pricingEnabled) {
	.price {
		color: $priceFontColor;
		display: $product-block-price-display;
		font-weight: normal;

		span {
			@include setFontStyle($productInfoPriceFontStyle);
		}

		.offer-price {
			color: $offerPriceColor;
			font-weight: normal;
			margin-right: 0;
		}

		p {
			display: $product-block-percentage-price-display;
		}

		.original-price {
			color: $originalPriceColor;
			display: block;
			margin-right: $product-block-price-original-price-margin-right;
			text-decoration: line-through;

			@include breakpoint(large) {
				margin-right: $product-block-price-original-price-margin-right-large;
			}
		}

		.current-price {
			color: $currentPriceFontColor;
		}
	}
}
