$facetValueFontSize: 16px !default;
$facetValueFontFamily: null !default;
$faceValueFontWeight: null !default;
$facetSelectedBackroundColor: $white !default;
$facetBreadcrumbEnabled: false !default;
$facetBreadcrumbDisplay: none !default;
$facetFilterRemoveFloat: null !default;
$facetBreadcrumbMarginLandR: 12px !default;
$facetBreadcrumbPaddingLandR: 12px !default;
$facetBreadcrumbPaddingTandB: 16px !default;
$facetBreadcrumbLineHeight: 0 !default;

@if (facetBreadcrumbEnabled) {
	.facet-breadcrumb {
		background-color: $facetSelectedBackroundColor;
		cursor: pointer;
		display: inline-block;
		font-family: $facetValueFontFamily;
		font-size: $facetValueFontSize;
		font-weight: $faceValueFontWeight;
		line-height: $facetBreadcrumbLineHeight;
		margin: 12px 12px 0;

		@include breakpoint(medium) {
			display: $facetBreadcrumbDisplay;
		}

		&.not-reset-filters {
			margin: 12px $facetBreadcrumbMarginLandR 0;
			padding: $facetBreadcrumbPaddingTandB $facetBreadcrumbPaddingLandR;
		}

		.reset-all-filters {
			color: $filterOptionsSelectedItemFontColor;
			display: block;
			outline: none;
			padding: 16px 20px;

			&:hover {
				color: $filterOptionsSelectedItemFontColor;
			}
		}

		div {
			display: inline-block;
			padding: 0 0 0 6px;
		}
	}

	.breadcrumb-facet-list {
		margin-bottom: 0;
	}

	.filter-remove {
		float: $facetFilterRemoveFloat;
	}
}
