@mixin star-rating {
  $iconStarComponentCursor: null !default;
  $iconStarComponentFloat: null !default;
  $iconStarComponentMargin: null !default;
  $iconStarComponentMarginMedium: null !default;
  $iconStarComponentMarginLarge: null !default;

  $iconStarContainerFloat: null !default;
  $iconStarContainerHeight: 50px !default;
  $iconStarContainerPadding: 10px 0 0 0 !default;
  $iconStarContainerMargin: 0 auto !default;
  $iconStarContainerMarginMedium: null !default;
  $iconStarContainerMarginLarge: null !default;
  $iconStarContainerWidth: null !default;
  $iconStarContainerWidthMedium: null !default;
  $iconStarContainerWidthLarge: null !default;
  $iconStarContainerMinWidth: null !default;

  $iconStarBottomColor: $white !default;
  $iconStarBackgroundTop: $primary-color !default;
  $iconStarBackgroundBottom: $light-gray !default;
  $iconStarBorderRadius: 3px !default;
  $iconStarFloat: null !default;
  $iconStarFontSize: 14px !default;
  $iconStarFontSizeMedium: null !default;
  $iconStarFontSizeLarge: null !default;
  $iconStarPadding: 3px !default;
  $iconStarMargin: -1px !default;
  $iconStarTopColor: $white !default;

  $ratingNumberBorder: 1px solid $medium-gray !default;
  $ratingNumberBorderRadius: 2px !default;
  $ratingNumberContainerDisplay: null !default;
  $ratingNumberContainerHeight: null !default;
  $ratingNumberDisplay: none !default;  //hides ratings
  $ratingNumberDisplayMedium: inline-block !default;
  $ratingNumberHeight: 22px !default;
  $ratingNumberLineHeight: 10px !default;
  $ratingNumberMargin: $iconStarMargin 0 0 0 !default;
  $ratingNumberPadding: 5px !default;
  $ratingNumberTextAlign: center !default;
  $ratingNumberTop: 8px !default;
  $ratingNumberLeft: 118px !default;
  $ratingNumberLeftMedium: $ratingNumberLeft !default;
  $ratingNumberLeftLarge: $ratingNumberLeft !default;
  $ratingNumberParensWidth: 100% !default;
  $ratingNumberFontSize: rem-calc(13) !default;
  $ratingNumberFontFamily: $body-font-family !default;
  $ratingNumberColor: null !default;
  $ratingNumberFontWeight: null !default;

  $reviewsNumberBottom: 2px !default;
  $reviewsNumberDisplay: block !default;  //hides reviews
  $reviewsNumberFontFamily: $body-font-family !default;
  $reviewsNumberFontSize: null !default;
  $reviewsNumberFontSizeMedium: null !default;
  $reviewsNumberLeft: 0 !default;
  $reviewsNumberMargin: auto !default;
  $reviewsNumberMarginMedium: null !default;
  $reviewsNumberMarginLarge: null !default;
  $reviewsNumberParens: false !default;  //puts parens around reviews
  $reviewsNumberPosition: absolute !default;
  $reviewsNumberTextAlign: center !default;
  $reviewsNumberTextDecoration: null !default;
  $reviewsNumberBeforeContent: null !default;
  $reviewsNumberAfterContent: null !default;
  $reviewsNumberColor: null !default;
  $reviewsLabelDisplay: inline-block !default;
  $reviewsLabelPlacementInline: false !default;
  $iconStarComponentDisplay: null !default;
  $reviewsLabelNoRatingDisplay: null !default;


  .star-rating {
    &-component {
      cursor: $iconStarComponentCursor;
      display: $iconStarComponentDisplay;
      float: $iconStarComponentFloat;
      margin: $iconStarComponentMargin;

      @include breakpoint(medium) {
        margin: $iconStarComponentMarginMedium;
      }

      @include breakpoint(large) {
        margin: $iconStarComponentMarginLarge;
      }
    }

    &-icon-container {
      float: $iconStarContainerFloat;
      height: $iconStarContainerHeight;
      margin: $iconStarContainerMargin;
      min-width: $iconStarContainerMinWidth;
      padding: $iconStarContainerPadding;
      position: relative;
      width: $iconStarContainerWidth;

      @if $reviewsLabelPlacementInline == true {
        width: 100%;
      }

      @include breakpoint(medium) {
        margin: $iconStarContainerMarginMedium;
        width: $iconStarContainerWidthMedium;
      }

      @include breakpoint(large) {
        margin: $iconStarContainerMarginLarge;
        width: $iconStarContainerWidthLarge;
      }
    }

    &-container {
      display: block;
      position: relative;
    }

    &-star {
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;

      .icon-star {
        border-radius: $iconStarBorderRadius;
        float: $iconStarFloat;
        font-size: $iconStarFontSize;
        margin: $iconStarMargin;
        padding: $iconStarPadding;
      }

      &.top {
        z-index: 1;

        .icon-star {
          background: $iconStarBackgroundTop;

          &.active {
            color: $iconStarTopColor;
          }
        }
      }

      &.bottom {
        z-index: 0;

        .icon-star {
          background: $iconStarBackgroundBottom;

          &.active {
            color: $iconStarBottomColor;
          }
        }
      }

      @include breakpoint(medium) {
        .icon-star {
          font-size: $iconStarFontSizeMedium;
        }
      }

      @include breakpoint(large) {
        .icon-star {
          font-size: $iconStarFontSizeLarge;
        }
      }
    }

    &-number {
      border: $ratingNumberBorder;
      border-radius: $ratingNumberBorderRadius;
      color: $ratingNumberColor;
      display: $ratingNumberDisplay;
      font-family: $ratingNumberFontFamily;
      font-weight: $ratingNumberFontWeight;
      font-size: $ratingNumberFontSize;
      height: $ratingNumberHeight;
      left: $ratingNumberLeft;
      line-height: $ratingNumberLineHeight;
      margin: $ratingNumberMargin;
      padding: $ratingNumberPadding;
      position: absolute;
      text-align: $ratingNumberTextAlign;
      top: $ratingNumberTop;

      &-container {
        display: $ratingNumberContainerDisplay;
        height: $ratingNumberContainerHeight;
        line-height: $ratingNumberContainerHeight;
      }

      @include breakpoint(medium) {
        display: $ratingNumberDisplayMedium;
        left: $ratingNumberLeftMedium;
      }

      @include breakpoint(large) {
        left: $ratingNumberLeftLarge;
      }
    }

    &-reviews-number {
      bottom: $reviewsNumberBottom;
      color: $reviewsNumberColor;
      display: $reviewsNumberDisplay;
      font-family: $reviewsNumberFontFamily;
      font-size: $reviewsNumberFontSize;
      left: $reviewsNumberLeft;
      margin: $reviewsNumberMargin;
      position: $reviewsNumberPosition;
      right: 0;
      text-align: $reviewsNumberTextAlign;
      text-decoration: $reviewsNumberTextDecoration;

      &:before {
        content: $reviewsNumberBeforeContent;
      }

      &:after {
        content: $reviewsNumberAfterContent;
      }

      @if $reviewsNumberParens == true {
        width: $ratingNumberParensWidth;
      }

      .reviews {
        display: $reviewsLabelDisplay;
      }

      @include breakpoint(medium) {
        font-size: $reviewsNumberFontSizeMedium;
        margin: $reviewsNumberMarginMedium;
      }

      @include breakpoint(large) {
        margin: $reviewsNumberMarginLarge;
      }
    }

    &-component {
      @if $reviewsLabelPlacementInline == true {
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
    }

    &-number-container {
      @if $reviewsLabelPlacementInline == true {
        display: flex;
      }

      &.no-rating {
        display:  $reviewsLabelNoRatingDisplay;
      }
    }

  }

  //overrides
  .rating-container {
    display: block !important; // sass-lint:disable-line no-important
    position: relative;
  }
}
