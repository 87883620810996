// =============================================================================
// product-form.scss
// styles for product form (PDP and Quick Shop)
// =============================================================================
@import 'product/product-form';
@import 'product/unmade-editor';
@import 'global/z-indexes';

$napa-pdp-element-spacing: 10px !default;
$addFavoritesButtonWidth: 21% !default;
$addFavoritesButtonWidthMedium: rem-calc(60) !default;

.fixed-gallery {
  .product-views-images {
    position: absolute;
    width: 100%;
    @include z-index(heroviewer);

    &--fixed {
      position: fixed;
      // make sure the gallery is below everything
      @include z-index(heroviewerfixed);
    }

  }

  .product-tech-container,
  .product-template-container {
    background: $white;
  }

  .product-info-container {
    border-top: rem-calc(1) solid $light-gray;
  }

  .vfdp-s7-viewer {
    border: 0;
  }
}

.product-views-images {

  .vfdp-s7-viewer-preload-image {
    width: 100%;
  }

}

.pdp-swatches,
.pdp-drawer {
  #product-content-container {

    .pdp-fitting-info {
      font-size: rem-calc(13);

      &-text {
        font-weight: 300;
        line-height: rem-calc(20);
        margin-bottom: 0;
      }
    }

    .model-measurements-text {
      display: block;
      font-size: rem-calc(13);
      font-weight: 300;
      line-height: rem-calc(20);
      padding: 0;
    }

    #product-form {
      margin: 0 0 rem-calc(20) 0;

      .product-attr-form > section {
        border-top: 0;
        padding: 0;

        .step-header {
          .attr-selected {
            font-family: get-font("AkkuratPro");
            font-size: em-calc(12px);
            font-weight: bold;
            text-transform: none;
          }

          .attr-primary-name-label {
            padding-right: 0;
          }

          .attribute-label-separator {
            font-weight: normal;
          }

          .attr-selected-color-identifier {
            display: none;
          }

          h2 {
            font-family: get-font("AkkuratPro");
            font-size: em-calc(12px);
            font-weight: normal;
            padding-right: 0;
            text-transform: none;
          }
        }

        span {
          font-size: em-calc(12);
        }

        .size-chart-link {
          .label {
            font-size: inherit;
            text-decoration: underline;

            &:hover {
              text-decoration: none;
            }
          }
        }

        .step-content {
          margin-bottom: $napa-pdp-element-spacing*2;
          margin-top: $napa-pdp-element-spacing*2;
          min-width: 100%;
          width: 100%;
        }

        &.step-container {
          //swatches
          &.attr-color {
            border-bottom: 1px solid $light-gray;
            border-top: 0;
            margin-top: 15px;

            @include breakpoint(medium) {
              margin-top: 0;
            }

            .attr-box {
              display: inline-block;
              float: none;
              height: auto;
              padding: 3px;
              width: 50px;

              img {
                height: auto;
              }
            }

            .attr-box.selected {
              border: 1px solid get-color(burnt-orange);
            }

            .attr-container {
              @include grid-column-gutter();
              float: none;
              height: auto;
              margin-top: 0;
              width: grid-column(12);
            }
          }

          //sizing chart
          &.attr-size {
            .step-header {
              margin-top: rem-calc(25);

              .attr-primary-name-label .product-content-form-step-label {
                font-size: rem-calc(16);
              }

              .attribute-label-separator,
              .attribute-label-value {
                display: none !important; // sass-lint:disable-line no-important
              }
            }

            .attr-container {
              border-spacing: 5px 0;
              display: table;
              margin-left: rem-calc(-5);
              width: 100%;
            }

            .attr-box {
              border: 1px solid $light-gray;
              background-color: $white;
              clear: none;
              color: $black;
              float: left;
              font-size: rem-calc(12);
              font-weight: normal;
              height: rem-calc(50);
              line-height: rem-calc(50);
              margin: 0 rem-calc(9) rem-calc(9) 0;
              min-width: rem-calc(50);
              padding: 0;
              text-align: center;
              text-transform: uppercase;
              vertical-align: middle;

              @include breakpoint(medium) {
                height: 40px;
                line-height: 40px;
                margin: 0 rem-calc(7) rem-calc(7) 0;
                min-width: 40px;
              }

              &.selected {
                border-bottom-color: get-color(burnt-orange);
                color: get-color(burnt-orange);
                font-weight: bold;
              }

              &.out-of-stock {
                color: $medium-gray;

                &.selected {
                  border-color: $medium-gray;
                }
              }
            }
          }
        } //end step-container

       &.stock-availability-disclaimer {
          padding-top: 0;
        }

        &.product-actions {

          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-top: $napa-pdp-element-spacing*4;

          .product-content-form-product-actions-primary {
            order: 1;
            width: 75%;

            @include breakpoint(medium) {
              width: calc(97% - #{$addFavoritesButtonWidthMedium});
            }

            button {
              font-size: rem-calc(13);
              line-height: rem-calc(20);
              padding: rem-calc(12) 0;
              text-transform: uppercase;
              width: 100%;

              @include breakpoint(medium) {
                font-size: rem-calc(12);
              }
            }
          }

          .product-content-form-add-to-bag {
            @include primary-button;
          }

          .product-content-form-customise-btn {
            @include customise-btn;
          }

          .product-content-form-custom-reset-btn {
            border-width: 0;
            color: get-color(eclipse);
            font-size: em-calc(12px);
            margin-bottom: 0;
            padding: 10px 0;
            text-align: left;

            span {
              font-weight: 700;
            }

            &:hover {
              color: get-color(napa-orange);
              text-decoration: none;
            }
          }

          .product-actions-secondary {
            align-items: center;
            border: 1px solid $light-gray;
            display: flex;
            height: rem-calc(45);
            justify-content: center;
            margin: 0 0 0 3%;
            order: 2;
            padding: 0 !important; // sass-lint:disable-line no-important
            width: $addFavoritesButtonWidth;

            a {
              line-height: normal;
            }

            @include breakpoint(medium) {
              padding: rem-calc(8) 0 0 rem-calc(22);
              width: $addFavoritesButtonWidthMedium;
            }

            .no-reserve {
              .link-button {
                text-decoration: none;
              }

              .icon-favorite-filled,
              .icon-favorite {
                + span {
                  display: none;
                }
              }
            }

            .product-content-form-add-to-wishlist {
              text-decoration: none;

              .icon-wishlist {
                font-size: rem-calc(17);
              }

              &:hover,
              &.add-wishlist-filled {
                .icon-wishlist::before {
                  @include icomoonFont($icon-wishlist-full);
                }
              }
            }

            .link-button:first-child {
              font-size: em-calc(12px);
              margin-left: 0;
            }

            .product-content-form-add-to-wishlist {
              color: get-color(eclipse);

              &:hover {
                color: get-color(primary);
              }
            }
          }

          .apple-pay-button {
            border-radius: 0!important;
            margin: 0;
            order: 3;
            padding: rem-calc(21) 0;
            width: 100%;
          }

          .button {
            margin-bottom: $napa-pdp-element-spacing;
          }

          .delivery-information {
            float: left;
            order: 4;
            padding: rem-calc(15) 0 0 0;

            .delivery-information-image {
              display: none;
            }

            .delivery-information-content {
              font-size: rem-calc(13);
              font-weight: 200;
              line-height: rem-calc(20);

              .icon-clock2 {
                margin-left: rem-calc(7);
                &::before {
                  font-size: rem-calc(14);
                }
              }

              span {
                font-size: rem-calc(13);
                margin-right: rem-calc(15);
              }

              a {
                font-weight: bold;
                margin-left: rem-calc(15);
                text-transform: none;
              }
            }
          }
        }
      } // end product-attr-form

    } // end #product-form

    .size-chart-popup {
      font-size: em-calc(12px);
      margin-top: $napa-pdp-element-spacing;

      @include breakpoint(large) {
        float: right;
        margin-top: 0;
      }
    }
  } //end #product-detail
} // end .pdp-drawer
