// =============================================================================
// product-form.scss
// styles for product form (PDP and Quick Shop)
// =============================================================================
$colorSwitchAsButton: false !default;
$sizeChartEnabled: false !default;
$outOfStockOption: #CCCDDD !default;
$productActionButtonMobileHeight: auto !default;
$btnLargePadding: 15px 35px !default;
$productActionButtonMobilePadding: $btnLargePadding !default;
$productActionButtonPadding: null !default;
$productActionsPrimaryMarginTop: null !default;
$swatch-size: 64px !default;
$tablet-swatch-size: $swatch-size+16px !default;
$mobile-swatch-size: $swatch-size - 5px !default;
$pdpContentWidth: 5 !default;
$pdpFormWidthTablet: 7 !default;
$swatch-margin: 7px !default;
$productImgI: 51px !default;
$stockAvailabilityIcon: false !default;
$stockAvailabilityIconPadding: false !default;
$stockAvailabilityIconSize: null !default;
$stockAvailabilityIconImage: null !default;
$stockAvailabilityMsgBG: null !default;
$stockAvailabilityMsgColor: null !default;
$stockAvailabilityMsgLineHeight: null !default;
$stockAvailabilityMsgHeight: 36px !default;
$stockAvailabilityMsgFontSizeSmall: null !default;
$stockAvailabilityMsgFontFamily: null !default;
$stockAvailabilityMsgFontSizeLarge: null !default;
$stockAvailabilityMsgFontWeight: null !default;
$stockAvailabilityMsgMargin: 5px 0 !default;
$stockAvailabilityMsgPadding: 10px 5px 5px 10px !default;
$stockAvailabilityMsgTextTransform: null !default;
$stockAvailabilityMsgFirstLetterTextTransform: null !default;
$stockAvailabilityMsgWidth: 50% !default;
$btnDisabledBGColor: $fontGreyLight !default;
$btnLargeFontSize: 1em !default;
$attributeLabelFloat: left !default;
$attributeLabelTextTransform: uppercase !default;
$attributeLabelDisplay: null !default;
$attributeContainerMarginTop: $pdpSpacingLarge !default;
$attributePrimaryLabelPaddingRight: 5px !default;
$inventoryMessageFontSize: 12px !default;
$inventoryMessageLineHeight: 16px !default;
$inventoryAvailabilityTextTransform: null !default;
$pdpCrossSellBgColor: null !default;
$pdpCrossSellBorderRight: null !default;
$pdpCrossSellBorderRightMedium: null !default;
$pdpCrossSellCheckboxBorder: null !default;
$pdpCrossSellCheckboxBorderRadius: null !default;
$pdpCrossSellCheckboxBackground: null !default;
$pdpCrossSellCheckboxCheckmarkBorder: null !default;
$pdpCrossSellCheckboxCheckmarkBorderWidth: null !default;
$pdpCrossSellCheckboxCheckmarkContent: null !default;
$pdpCrossSellCheckboxCheckmarkHeight: null !default;
$pdpCrossSellCheckboxCheckmarkLeft: null !default;
$pdpCrossSellCheckboxCheckmarkTop: null !default;
$pdpCrossSellCheckboxCheckmarkTransform: null !default;
$pdpCrossSellCheckboxCheckmarkWidth: null !default;
$pdpCrossSellCheckboxHeight: null !default;
$pdpCrossSellCheckboxMarginLeft: null !default;
$pdpCrossSellCheckboxMarginRight: null !default;
$pdpCrossSellCheckboxMarginRightMedium: null !default;
$pdpCrossSellCheckboxWidth: null !default;
$pdpCrossSellContainerBorder: null !default;
$pdpCrossSellContainerMargin: null !default;
$pdpCrossSellContainerMarginMedium: null !default;
$pdpCrossSellContainerMaxWidth: null !default;
$pdpCrossSellContainerPadding: null !default;
$pdpCrossSellExtrasFontFamily: null !default;
$pdpCrossSellExtrasFontSize: null !default;
$pdpCrossSellExtrasFontSizeMedium: null !default;
$pdpCrossSellExtrasFontWeight: null !default;
$pdpCrossSellExtrasHeight: null !default;
$pdpCrossSellExtrasLetterSpacing: null !default;
$pdpCrossSellExtrasLineHeight: null !default;
$pdpCrossSellExtrasTextTransform: null !default;
$pdpCrossSellExtrasWidth: null !default;
$pdpCrossSellFlexShrinkLarge: null !default;
$pdpCrossSellFlexShrinkMedium: null !default;
$pdpCrossSellFontSize: null !default;
$pdpCrossSellFontSizeMedium: null !default;
$pdpCrossSellFontWeight: null !default;
$pdpCrossSellProductNameFontWeight: null !default;
$pdpCrossSellGridColumnEnd: null !default;
$pdpCrossSellGridColumnStart: null !default;
$pdpCrossSellImgWidth: null !default;
$pdpCrossSellImgWidthMedium: null !default;
$pdpCrossSellImgMixBlendMode: null !default;
$pdpCrossSellImgBorder: null !default;
$pdpCrossSellLabelAlignItems: null !default;
$pdpCrossSellLabelDisplay: inline-block !default;
$pdpCrossSellLabelJustifyContent: null !default;
$pdpCrossSellLabelMargin: rem-calc(10) !default;
$pdpCrossSellLabelMarginRightMedium: null !default;
$pdpCrossSellLabelTxtContainerPadding: null !default;
$pdpCrossSellLabelTxtContainerPaddingMedium: null !default;
$pdpCrossSellLabelTxtContainerAlignItems: null !default;
$pdpCrossSellLabelWidth: null !default;
$pdpCrossSellLinkColor: null !default;
$pdpCrossSellLinkFontFamily: null !default;
$pdpCrossSellLinkFontWeight: null !default;
$pdpCrossSellLinkLetterSpacing: null !default;
$pdpCrossSellLinkLineHeight: null !default;
$pdpCrossSellLinkFontSize: $pdpCrossSellFontSize !default;
$pdpCrossSellLinkFontSizeMedium: null !default;
$pdpCrossSellLinkMargin: 0 rem-calc(5) !default;
$pdpCrossSellMargin: null !default;
$pdpCrossSellTextFlex: null !default;
$pdpCrossSellMarginLeftLarge: null !default;
$pdpCrossSellMarginLeftMedium: null !default;
$pdpCrossSellMarginLeftSmall: null !default;
$pdpCrossSellMarginLeftXlarge: null !default;
$pdpCrossSellMarginMedium: null !default;
$pdpCrossSellMarginTopLarge: null !default;
$pdpCrossSellMarginTopMedium: null !default;
$pdpCrossSellMarginTopXlarge: null !default;
$pdpCrossSellModalCloseDisplay: none !default;
$pdpCrossSellModalCloseDisplayMedium: none !default;
$pdpCrossSellPadding: null !default;
$pdpCrossSellPaddingMedium: null !default;
$pdpCrossSellPriceColor: null !default;
$pdpCrossSellPriceColorDiscount: null !default;
$pdpCrossSellPriceDiscountPadding: null !default;
$pdpCrossSellPriceDisplay: null !default;
$pdpCrossSellPriceContainerDisplay: null !default;
$pdpCrossSellPriceFontFamily: null !default;
$pdpCrossSellPriceFontSize: null !default;
$pdpCrossSellPriceFontWeight: null !default;
$pdpCrossSellPriceLineHeight: null !default;
$pdpCrossSellPriceMarginLeft: null !default;
$pdpCrossSellPriceMarginRight: null !default;
$pdpCrossSellPriceMarginLeftMedium: null !default;
$pdpCrossSellPriceMarginMedium: null !default;
$pdpCrossSellTextColor: null !default;
$pdpCrossSellTextDecoration: null !default;
$pdpCrossSellTextDetailsDisplayXLarge: null !default;
$pdpCrossSellTextDetailsDisplayMedium: null !default;
$pdpCrossSellTextDetailsWidth: rem-calc(194) !default;
$pdpCrossSellTextDetailsPadding: rem-calc(10) 0 0 0 !default;
$pdpCrossSellTextDetailsDisplay: grid !default;
$pdpCrossSellTextDetailsAlignItems: null !default;
$pdpCrossSellTextDetailsGridTemplateColumns: null !default;
$pdpCrossSellTextDetailsPaddingTopMedium: null !default;
$pdpCrossSellTextDetailsPaddingXLarge: null !default;
$pdpCrossSellTextFontFamily: null !default;
$pdpCrossSellTextPadding: null !default;
$pdpCrossSellTextPaddingMedium: null !default;
$pdpCrossSellTextWidth: null !default;
$pdpCrossSellTextWidthMedium: null !default;
$pdpCrossSellTitleDisplay: null !default;
$pdpCrossSellTitleLineHeight: null !default;
$pdpCrossSellTitleFontWeight: null !default;
$pdpCrossSellTitleMargin: null !default;
$pdpCrossSellTitleMaxWidth: null !default;
$pdpCrossSellTitlePadding: null !default;
$pdpCrossSellTitleWhiteSpace: null !default;
$pdpCrossSellWhiteSpaceMedium: null !default;
$pdpCrossSellWidthMedium: null !default;
$pdpCrossSellWidth: null !default;
$pdpQuickViewCrossSellCloseButtonColor: null!default;
$pdpQuickViewCrossSellCloseButtonFontSize: rem-calc(16)!default;
$pdpQuickViewCrossSellContainerPadding: rem-calc(40)!default;
$pdpQuickViewCrossSellContentPadding: rem-calc(20)!default;
$pdpQuickViewCrossSellImageWidth: 40%!default;
$promoExcludedMessageFontSize: rem-calc(13) !default;
$promoExcludedMessageFontWeight: 700 !default;
$promoExcludedMessageLetterSpace: rem-calc(0.21) !default;
$promoExcludedMessagingLineHeight: rem-calc(17) !default;
$promoExcludedMessagingColor: $black !default;
$promoExcludedMessagingFontFamily: null !default;
$product-actions-secondary-link-button-background-color: transparent !default;
$product-actions-secondary-link-button-padding: 0 !default;
$product-actions-secondary-link-button-color: null !default;
$product-actions-secondary-link-button-color-hover: null !default;
$product-actions-secondary-link-button-letter-spacing: null !default;
$product-actions-secondary-link-button-font-weight: 500 !default;
$product-actions-secondary-order: null !default;
$pdpPowerReviewSnippetContainerWidth: null !default;
$pdpContentFormFavouritesNotificationFloat: left !default;
$pdpContentFormFavouritesNotificationPosition: null !default;
$pdpContentFormFavouritesNotificationTop: null !default;
$pdpContentFormFavouritesNotificationLeft: null !default;
$pdpContentFormFavouritesNotificationLeftMedium: null !default;
$pdpContentFormFavouritesNotificationLeftLarge: null !default;
$pdpContentFormFavouritesNotificationMarginLeft: null !default;
$pdpContentFormFavouritesNotificationMarginRight: null !default;
$pdpSizeChartTriggerMarginTop: null !default;
$history-lowest-price-show-pr-snippet-float: null !default;
$history-lowest-price-show-pr-snippet-padding: null !default;
$history-lowest-price-show-pr-snippet-review-count-padding: null !default;
$history-lowest-price-show-pr-snippet-width: null !default;
$step-header-h2-font-size: null !default;
$step-header-h2-font-size-medium: $step-header-h2-font-size !default;
$step-header-h2-margin-bottom: null !default;
$step-header-h2-margin-bottom-medium: $step-header-h2-margin-bottom !default;
$step-header-h2-margin-top: null !default;
$step-header-h2-margin-top-medium: $step-header-h2-margin-top !default;
$step-header-h2-text-transform: null !default;
$step-header-h2-color: null !default;
$productActionButtonMediumHeight: auto !default;
$pdpCrossSellOriginalPriceTextDecoration: line-through !default;
$pdpCrossSellOriginalPriceStriked: null !default;
$pdpCrossSellPriceFontSize: null !default;
$pdpCrossSellPriceOrder: null !default;

//PDP color swatches mixin
@mixin default-attr-box($size: $productImgI, $borderthickness: $facetBoxBorderThickness) {
    background: none;
    border: $borderthickness solid transparent;
    cursor: pointer;
    height: $size;
    padding: 0;
    position: relative;
    width: $size;

    &.selected {
        border: $borderthickness solid $brandColorPalette1;
    }
}


@mixin discounted-price-cross-sale-item {
    .product-price {
        align-items: center;
        display: $pdpCrossSellPriceContainerDisplay;

        .product-block-original-price {
            text-decoration: $pdpCrossSellOriginalPriceTextDecoration;
        }

        .original-price {
            text-decoration: $pdpCrossSellOriginalPriceStriked;
        }

        .product-block-current-price {
            color: $pdpCrossSellPriceColorDiscount;
            padding: $pdpCrossSellPriceDiscountPadding;
        }

        p {
            margin-bottom: 0;
        }

        .current-price {
            color: $pdpCrossSellPriceColorDiscount;
            font-size: $pdpCrossSellPriceFontSize;
            padding: $pdpCrossSellPriceDiscountPadding;
            order: $pdpCrossSellPriceOrder;
        }
    }
}

#product-info-container {
    #product-info .promo-excluded-message {
        color: $promoExcludedMessagingColor;
        font-family: $promoExcludedMessagingFontFamily;
        font-size: $promoExcludedMessageFontSize;
        font-weight: $promoExcludedMessageFontWeight;
        letter-spacing: $promoExcludedMessageLetterSpace;
        line-height: $promoExcludedMessagingLineHeight;

        &:empty {
            display: none;
        }
    }
}

#product-content-container {
    //mobile first
    .promo-eyebrow {
        color: $badgeFontColor;
        font-family: $header-font-family;
        font-weight: bold;
        text-transform: uppercase;
    }
}

//Product form
#product-form {

    @include clearfix; // REVISIT

    .product-form-wrapper {

        @include clearfix;

        .product-attr-form {
            margin: 0;
        }
    }

    .product-attr-form > section {
        @include clearfix; //step container
        border-top: $BGSectionSeperatorB;
        padding: $pdpSpacingLarge 0;

        .step-header {
            text-transform: $attributeLabelTextTransform;

            h2,
            span,
            .attr-primary-name-label {
                float: $attributeLabelFloat;
                display: $attributeLabelDisplay;
                font-family: $primaryFontBold;
                font-size: 100%;
                font-weight: bold;
                line-height: get-form-input-height();
                padding-right: $attributePrimaryLabelPaddingRight;
            }

            h2 {
                color: $step-header-h2-color;
                font-size: $step-header-h2-font-size;
                margin-top: $step-header-h2-margin-top;
                margin-bottom: $step-header-h2-margin-bottom;
                text-transform: $step-header-h2-text-transform;
                @include breakpoint(medium) {
                    font-size: $step-header-h2-font-size-medium;
                    margin-top: $step-header-h2-margin-top-medium;
                    margin-bottom: $step-header-h2-margin-bottom-medium;
                }
            }

            .attr-selected {
                font-weight: normal;
            }

            .attr-selected-color-identifier {
                font-weight: normal;
            }

            .sub-attr-selected {
                font-weight: normal;
            }
        }

        & .inventory-availability-disclaimer {
            background-color: $stockAvailabilityMsgBG;
            color: $errorText;

            span {
                font-size: $inventoryMessageFontSize;
                line-height: $inventoryMessageLineHeight;
            }
        }

        .step-content {
            select {
                margin: 0;

                option.out-of-stock {
                    color: $outOfStockOption;
                }
            }
        }
    } // end form.product-attr-form > section

    .product-attr-form {

        .size-step-container.error {
            background: $pdpSelectSizeErrorBg;
        }

        .select-size-error-message {
            @include grid-column(12, 0);
            background: $pdpSelectSizeErrorBg;

            span.error {
                display: inherit;
            }
        }

        .stock-availability-disclaimer span {
            background-color: $stockAvailabilityMsgBG;
            color: $stockAvailabilityMsgColor;
            display: none;
            float: left;
            font-family: $stockAvailabilityMsgFontFamily;
            font-size: $stockAvailabilityMsgFontSizeSmall;
            font-weight: $stockAvailabilityMsgFontWeight;
            line-height: $stockAvailabilityMsgLineHeight;
            margin: $stockAvailabilityMsgMargin;
            min-height: em-calc($stockAvailabilityMsgHeight);
            padding: $stockAvailabilityMsgPadding;
            text-transform: $stockAvailabilityMsgTextTransform;
            width: $stockAvailabilityMsgWidth;

            @if ($stockAvailabilityIcon) {
              padding: $stockAvailabilityIconPadding;
              position: relative;

              &:before {
                @include icomoonFont($stockAvailabilityIconImage);
                left: 0;
                line-height: $stockAvailabilityMsgLineHeight;
                position: absolute;
              }
            }

            &:first-letter {
              text-transform: $stockAvailabilityMsgFirstLetterTextTransform;
            }

            @include breakpoint(large) {
                font-size: $stockAvailabilityMsgFontSizeLarge;
            }
        }

        .inventory-availability-disclaimer {
            background-color: $stockAvailabilityMsgBG;
            color: $stockAvailabilityMsgColor;
            text-transform: $inventoryAvailabilityTextTransform;

            span {
                line-height: 16px;
            }
        }
    } //Color attribute section

    .product-attr-form > section.step-container.attr-color {
        border-top: $BGSectionSeperatorB;
        @if ($pdpFormWidthTablet < 12) {
            // REVISIT
            @include breakpoint(medium) {
                border-top: 0;
                padding-top: 0;
            }
        }

        .step-header,
        .step-content {
            @include grid-column(12, 0);
        }

        .step-header {
            h2,
            span,
            .attr-primary-name-label {
                line-height: 1;
            }
        }

        .attr-container {
            //mobile first
            height: $mobile-swatch-size + $swatch-margin;
            margin: $attributeContainerMarginTop (-$swatch-margin) 0 0;
            overflow: hidden;

            @include breakpoint(medium) {
                height: $tablet-swatch-size + $swatch-margin;
            }

            @include breakpoint(large) {
                height: $swatch-size + $swatch-margin;
            }
        }

        .attr-box {
            //mobile first
            @include default-attr-box($mobile-swatch-size);
            float: left;
            margin: 0 $swatch-margin $swatch-margin 0;

            &.disabled {
                cursor: default;
                opacity: 0.5;

                &:hover {
                    border: 0 none;
                }
            }

            img {
                height: 100%;
                width: 100%;
            }

            @include breakpoint(medium) {
                @include default-attr-box($tablet-swatch-size);
            }

            @include breakpoint(large) {
                @include default-attr-box($swatch-size);
            }
        } //end of attr-box

        @if ($colorSwitchAsButton) {
            .color-swatches-action {
                // mobile first
                @include default-attr-box($mobile-swatch-size);

                @include breakpoint(medium) {
                    @include default-attr-box($tablet-swatch-size);
                }

                @include breakpoint(large) {
                    @include default-attr-box($swatch-size);
                    background-color: #e9e7dd;
                    float: left;
                    margin: 0 $swatch-margin $swatch-margin 0;
                }

                a {
                    // mobile frist
                    height: $mobile-swatch-size;
                    width: $mobile-swatch-size;

                    @include breakpoint(medium) {
                        height: $tablet-swatch-size;
                        width: $tablet-swatch-size;
                        padding: 0 10px;
                    }

                    @include breakpoint(large) {
                        width: $swatch-size;
                        height: $swatch-size;
                        text-align: center;
                        display: table-cell;
                        vertical-align: middle;
                        padding: 0 5px;
                    }
                }
            } //end of color-swatches-action
        } // end @if ($colorSwitchAsButton)
    } //end of attr-color

    @if ($sizeChartEnabled) {
        section.step-container.size-step-container .step-header {
            h2,
            span,
            .attr-primary-name-label {
                line-height: 1;
            }

            .size-chart {
                padding-top: 5px;
            }
        }
    } // end @if ($sizeChartEnabled)

    section.step-container.notification {
        margin: ($pdpSpacingLarge - $swatch-margin) 0 $pdpSpacingLarge 0;
        padding: $pdpSpacingLarge 0;
        font-size: convert-to-em($notificationFontSize);
        text-align: center;
        background-color: $notificationBGColor;
    }

    section.feature-countdown {
        padding-top: 0;
    } //buttons and Links

    // Cross-sell Section
    .cross-sell-step-container {
        background-color: $pdpCrossSellBgColor;
        border: $pdpCrossSellContainerBorder;
        margin: $pdpCrossSellContainerMargin;
        max-width: $pdpCrossSellContainerMaxWidth;
        padding: $pdpCrossSellContainerPadding;
        width: $pdpCrossSellWidth;

        @include breakpoint(medium) {
            margin: $pdpCrossSellContainerMarginMedium;
            padding: $pdpCrossSellPaddingMedium;
            width: $pdpCrossSellWidthMedium;
        }

        .cross-sell {
            &-checkbox {
                border: $pdpCrossSellCheckboxBorder;
                border-radius: $pdpCrossSellCheckboxBorderRadius;
                flex-shrink: 0;
                height: $pdpCrossSellCheckboxHeight;
                margin-bottom: 0;
                margin-left: $pdpCrossSellCheckboxMarginLeft;
                margin-right: $pdpCrossSellCheckboxMarginRight;
                width: $pdpCrossSellCheckboxWidth;

                @include breakpoint(medium) {
                    margin-right: $pdpCrossSellCheckboxMarginRightMedium;
                }

                &:after {
                  background: $pdpCrossSellCheckboxBackground;
                  border: $pdpCrossSellCheckboxCheckmarkBorder;
                  border-width: $pdpCrossSellCheckboxCheckmarkBorderWidth;
                  content: $pdpCrossSellCheckboxCheckmarkContent;
                  height: $pdpCrossSellCheckboxCheckmarkHeight;
                  left: $pdpCrossSellCheckboxCheckmarkLeft;
                  top: $pdpCrossSellCheckboxCheckmarkTop;
                  transform: $pdpCrossSellCheckboxCheckmarkTransform;
                  width: $pdpCrossSellCheckboxCheckmarkWidth;
                }

                &:before {
                    background: $pdpCrossSellCheckboxBackground;
                }
            }

            &-title {
              display: $pdpCrossSellTitleDisplay;
              font-weight: $pdpCrossSellTitleFontWeight;
              line-height: $pdpCrossSellTitleLineHeight;
              margin: $pdpCrossSellTitleMargin;
              max-width: $pdpCrossSellTitleMaxWidth;
              padding: $pdpCrossSellTitlePadding;
              white-space: $pdpCrossSellTitleWhiteSpace;
            }

            &-label {
                align-items: $pdpCrossSellLabelAlignItems;
                display: $pdpCrossSellLabelDisplay;
                justify-content: $pdpCrossSellLabelJustifyContent;
                margin-right: $pdpCrossSellLabelMargin;
                width: $pdpCrossSellLabelWidth;

                img {
                  border: $pdpCrossSellImgBorder;
                  mix-blend-mode: $pdpCrossSellImgMixBlendMode;
                  width: $pdpCrossSellImgWidth;

                  @include breakpoint(medium) {
                    width: $pdpCrossSellImgWidthMedium;
                  }
                }

                @include breakpoint(medium) {
                    margin-right: $pdpCrossSellLabelMarginRightMedium;
                }
            }

            &-link {
                color: $pdpCrossSellLinkColor;
                display: inline-block;
                font-family: $pdpCrossSellLinkFontFamily;
                font-size: $pdpCrossSellLinkFontSize;
                font-weight: $pdpCrossSellLinkFontWeight;
                letter-spacing: $pdpCrossSellLinkLetterSpacing;
                line-height: $pdpCrossSellLinkLineHeight;
                margin: $pdpCrossSellLinkMargin;
                text-decoration: $pdpCrossSellTextDecoration;

                @include breakpoint(medium) {
                    font-size: $pdpCrossSellLinkFontSizeMedium;
                    margin-left: $pdpCrossSellMarginLeftMedium;
                    margin-top: $pdpCrossSellMarginTopMedium;
                }
                @include breakpoint(large) {
                    margin-left: $pdpCrossSellMarginLeftLarge;
                    margin-top: $pdpCrossSellMarginTopLarge;
                }
                @include breakpoint(xlarge) {
                    margin-left: $pdpCrossSellMarginLeftXlarge;
                    margin-top: $pdpCrossSellMarginTopXlarge;
                }
            }

            &-product-name {
              font-weight: $pdpCrossSellProductNameFontWeight;
            }

            &-text {
                border-right: $pdpCrossSellBorderRight;
                color: $pdpCrossSellTextColor;
                flex: $pdpCrossSellTextFlex;
                font-family: $pdpCrossSellTextFontFamily;
                font-size: $pdpCrossSellFontSize;
                font-weight: $pdpCrossSellFontWeight;
                grid-column-end: $pdpCrossSellGridColumnEnd;
                grid-column-start: $pdpCrossSellGridColumnStart;
                margin: $pdpCrossSellMargin;
                padding: $pdpCrossSellTextPadding;
                width: $pdpCrossSellTextWidth;

                @include breakpoint(medium) {
                    border-right: $pdpCrossSellBorderRightMedium;
                    flex-shrink: $pdpCrossSellFlexShrinkMedium;
                    font-size: $pdpCrossSellFontSizeMedium;
                    margin: $pdpCrossSellMarginMedium;
                    padding: $pdpCrossSellTextPaddingMedium;
                    white-space: $pdpCrossSellWhiteSpaceMedium;
                    width: $pdpCrossSellTextWidthMedium;
                }

                @include breakpoint(large) {
                    flex-shrink: $pdpCrossSellFlexShrinkLarge;
                }
            }

            &-extras {
                color: $pdpCrossSellTextColor;
                display: block;
                font-family: $pdpCrossSellExtrasFontFamily;
                font-size: $pdpCrossSellExtrasFontSize;
                font-weight: $pdpCrossSellExtrasFontWeight;
                height: $pdpCrossSellExtrasHeight;
                letter-spacing: $pdpCrossSellExtrasLetterSpacing;
                line-height: $pdpCrossSellExtrasLineHeight;
                padding: $pdpCrossSellPadding;
                text-transform: $pdpCrossSellExtrasTextTransform;
                width: $pdpCrossSellExtrasWidth;

                @include breakpoint(medium) {
                    font-size: $pdpCrossSellExtrasFontSizeMedium;
                    padding-right: rem-calc(50);
                    padding-top: rem-calc(14);
                }
            }

            &-price {
                color: $pdpCrossSellPriceColor;
                display: $pdpCrossSellPriceDisplay;
                font-family: $pdpCrossSellPriceFontFamily;
                font-size: $pdpCrossSellPriceFontSize;
                font-weight: $pdpCrossSellPriceFontWeight;
                line-height: $pdpCrossSellPriceLineHeight;
                margin-left: $pdpCrossSellPriceMarginLeft;
                margin-right: $pdpCrossSellPriceMarginRight;

                @include breakpoint(medium) {
                    margin-left: $pdpCrossSellPriceMarginLeftMedium;
                    margin-top: $pdpCrossSellMarginTopMedium;
                }

                @include breakpoint(large) {
                    margin: $pdpCrossSellPriceMarginMedium;
                }

                @include discounted-price-cross-sale-item;

                .product-block-price {
                    font-size: $pdpCrossSellPriceFontSize;
                }
            }

            &-label-text-container {
                display: flex;
                align-items: $pdpCrossSellLabelTxtContainerAlignItems;
                padding-top: $pdpCrossSellLabelTxtContainerPadding;

                @include breakpoint(medium) {
                  padding-top: $pdpCrossSellLabelTxtContainerPaddingMedium;
                }
            }

            &-text-details {
                display: $pdpCrossSellTextDetailsDisplay;
                align-items: $pdpCrossSellTextDetailsAlignItems;
                grid-template-columns: $pdpCrossSellTextDetailsGridTemplateColumns;
                height: rem-calc(32);
                letter-spacing: rem-calc(0.39);
                line-height: rem-calc(16);
                padding: $pdpCrossSellTextDetailsPadding;
                width: $pdpCrossSellTextDetailsWidth;

                @include breakpoint(medium) {
                    display: $pdpCrossSellTextDetailsDisplayMedium;
                    padding-top: $pdpCrossSellTextDetailsPaddingTopMedium;
                }

                @include breakpoint(xlarge) {
                    display: $pdpCrossSellTextDetailsDisplayXLarge;
                    padding: $pdpCrossSellTextDetailsPaddingXLarge;
                }
            }

            img {
              border: $pdpCrossSellImgBorder;
              width: $pdpCrossSellImgWidth;
            }
        }
    }

    .product-actions {
        .button {
            //mobile first
            font-size: $btnLargeFontSize;
            width: 100%;
            height: $productActionButtonMobileHeight;
            padding: $productActionButtonMobilePadding;
            margin-bottom: $pdpSpacingLarge;

            @include breakpoint(medium) {
                width: auto;
                height: $productActionButtonMediumHeight;
                padding: $productActionButtonPadding;
            }
        }

        .product-actions-primary {
            margin-bottom: 0;
            margin-top: $productActionsPrimaryMarginTop;

            a,
            .product-content-form-add-to-bag {
                display: block;

                @include breakpoint(medium) {
                    display: inline-block;
                }
            }
        }

        .product-actions-secondary {
          order: $product-actions-secondary-order;
        }

        .product-actions-secondary,
        .utility-actions-primary {
            //mobile first
            clear: both;
            float: none;
            margin: 0 auto;
            padding: 0;
            text-align: center;
            width: grid-column(12);

            .link-button {
                background-color: $product-actions-secondary-link-button-background-color;
                color: $product-actions-secondary-link-button-color;
                display: inline-block;
                font-weight: $product-actions-secondary-link-button-font-weight;
                letter-spacing: $product-actions-secondary-link-button-letter-spacing;
                line-height: 28px;
                margin-left: $pdpSpacingLarge;
                padding:  $product-actions-secondary-link-button-padding;

                &:hover {
                    color: $product-actions-secondary-link-button-color-hover;
                }

                &:first-child {
                    margin-left: 0;
                }

                &.find-a-store.hide {
                    display: none;
                }
            }

            .link-button.disabled {
                color: $btnDisabledBGColor;
                cursor: default;
            }

            @include breakpoint(medium) {
                float: left;
                clear: none;
                text-align: left;

                .link-button {
                    display: block;

                    &:first-child {
                        margin-left: $pdpSpacingLarge;
                    }

                    &.hide {
                        display: none;
                    }
                }
            }
        }
    } // end .product-actions
} // end #product-form

.cross-sell-quick-view-modal {
    display: none;
    position: relative;
    top: 0;
    width: 100%;

    .cross-sell-modal {
        display: flex;
        padding-top: $pdpQuickViewCrossSellContainerPadding;

        .cross-sell-modal--content {
            padding: $pdpQuickViewCrossSellContentPadding;
            width: 100%;

            h1 {
                margin-top: 0;
            }

            .cross-sell-modal--product-price {
                color: get-color(primary);
            }
        }

        .cross-sell-modal--image {
            padding: $pdpQuickViewCrossSellContentPadding;
            width: $pdpQuickViewCrossSellImageWidth;
        }
    }

    .close-button {
        color: $pdpQuickViewCrossSellCloseButtonColor;
        font-size: $pdpQuickViewCrossSellCloseButtonFontSize;
    }
}

//Wishlist notification
#wishlist-notification-panel {
    display: none;

    &.notification {
        margin: 0;
    }

    .notification-text {
        display: inline-block;
        line-height: 1.3em;
    }
}

//Size chart and return policy
.size-chart,
.return-policy,
.shipping-policy {
    color: $hyperlinkText;
    clear: both;

    span {
        display: block;
        float: none;
        cursor: pointer;

        &:hover {
            color: $black;
        }
    }
}

.pdp-true-fit-container {
    display: grid;
    grid-template-columns: rem-calc(121) rem-calc(290);
    align-items: center;
}

.size-chart-popup {
    clear: both;
    margin-top: $pdpSizeChartTriggerMarginTop;
    &.above-size-box {
        clear: none;
        float: right;
    }
}

.extra-info {
    display: none;
}

//review
.pr-snippet {
   @include grid-column(9);
   width: $pdpPowerReviewSnippetContainerWidth;
}

.history-lowest-price-show {
    .pr-snippet {
        float: $history-lowest-price-show-pr-snippet-float;
        padding: $history-lowest-price-show-pr-snippet-padding;
        width: $history-lowest-price-show-pr-snippet-width;
        .pr-snippet-review-count {
            padding: $history-lowest-price-show-pr-snippet-review-count-padding;
        }
    }
}

.pr-snippet-rating-decimal {
    display: none;
}

.product-content-form-favorites-notification-panel {
    float: $pdpContentFormFavouritesNotificationFloat;
    margin-top: 5px;
    position: $pdpContentFormFavouritesNotificationPosition;
    top: $pdpContentFormFavouritesNotificationTop;
    left: $pdpContentFormFavouritesNotificationLeft;
    margin-left: $pdpContentFormFavouritesNotificationMarginLeft;
    margin-right: $pdpContentFormFavouritesNotificationMarginRight;

    @include breakpoint(medium) {
        left: $pdpContentFormFavouritesNotificationLeftMedium;
    }

    @include breakpoint(large) {
        left: $pdpContentFormFavouritesNotificationLeftLarge;
    }

    &.callout {
        &.hide {
            display: none;
        }
    }
}

.cross-sell-modal {

    @include discounted-price-cross-sale-item;

    .cross-sell-modal--close-button {
        display: $pdpCrossSellModalCloseDisplay;

        @include breakpoint(medium) {
            display: $pdpCrossSellModalCloseDisplayMedium;
        }
    }
}
