@import 'catalog/grid-search-header';

#catalog.search {
  &[data-search-term]:not([data-search-term=""]){
    .off-canvas-filter-content{
      .product-info{
        display: flex;
      }
    }
    @include breakpoint(medium) {
      .active-filters-container {
        .product-info{
          display: flex;
        }
      }
    }
  } 
  @if($gridSearchHeaderEnabled) {

    #search-results-header-container {
      margin: rem-calc(16) auto rem-calc(10) auto;
      // This is to override incorrect padding in _shared
      padding: 0 $column-gutter/2;
    }
  }

  .product-list-header-container {
    section#search-results-header-container {
      .search-results-summary {
        min-width: 100%;
      }
    }
  }
}
