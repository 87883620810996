$headerHeroTitleFontSize: 58px !default;
$OTWHeaderTitleFontSize: 108px !default;

.grid-title-hero {
	@include grid-column-gutter();
	float: $global-left;
	margin-bottom: $vertSpace + 20px;
	padding-top: 10px;
	position: relative;
	text-align: center;
	width: grid-column(12);

	@include breakpoint(medium) {
		margin-bottom: $vertSpace;
	}

	.grid-title-hero-text {
		left: 0.625em;
		position: absolute;
		right: 0.625em;
		top: calc(50% - 87px);

		@include breakpoint(medium) {
			top: calc(50% - 49px);
		}

		span {
			@include setFontStyle($gridTitleHeroTextFontStyle);
			font-size: em-calc($headerHeroTitleFontSize);
		}

		p {
			@include setFontStyle($gridTitleHeroTextFontStyle);
			font-size: em-calc($headerHeroDescFontSize);
		}
	}

	.feature-heading-shop {
		bottom: -(65px / 2);
		display: table;
		left: calc(50% - 51px);
		margin: auto;
		position: absolute;
		z-index: 100;

		span {
			display: table-cell;
			vertical-align: middle;

			p {
				@include setFontStyle($featureHeadingShopFontStyle);
				margin: auto;
				max-width: 69%;
			}
		}
	}

	&.otw {
		.grid-title-hero-text {
			top: calc(50% - 56px);

            @include breakpoint(medium) {
            	top: calc(50% - 56px);
            }

          	span {
	            font-family: $OTWHeaderTitleFontFamily;
	            font-size: em-calc($OTWHeaderTitleFontSize);
	            font-weight: normal;
	            line-height: em-calc(16);
          	}
    	}
	}
}
