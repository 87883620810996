@import 'global/z-indexes';

.product-content-form-notify-me-form .message {
  font-size: em-calc(12);
  font-weight: bold;
  text-align: center;
}

.add-to-bag-half,
.customize-button,
.find-store-button,
.notify-me {
  float: left;
  width: 47.5%;
}

.marketing-find-store-button,
.add-to-bag {
  width: 100%;
}

.find-store-button,
.add-to-bag-half {
  margin-right: 2.5%;
}

.customize-button,
.notify-me {
  margin-left: 2.5%;
}

.notify-me-hopup {
  background: $pageBackground;
  //min-width: 560px;
}

.notify-me-header {
  @include grid-column-gutter();
  border-bottom: 3px double #e5e5e5;
  float: none;
  padding-bottom: 30px;
  padding-top: 40px;
  margin-bottom: 35px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: grid-column(12);

  @include breakpoint(medium) {
        width: grid-column(10);
      }
}

.notify-me-title {
  @include grid-column-gutter();
  float: none;
  font-size: convert-to-em(14);
  margin-left: auto;
  margin-right: auto;
  text-transform: uppercase;
  font-weight: bold;
  width: grid-column(10);

  @include breakpoint(medium) {
    font-size: convert-to-em(20);
  }

  @include breakpoint(large) {
    font-size: convert-to-em(32);
  }
}

.notify-me-form {
    margin-bottom: 0;
}

.notify-me-content {
  @include grid-column-gutter();
  float: none;
  margin-left: auto;
  margin-right: auto;
  width: grid-column(12);

	@include breakpoint(medium) {
	  width: grid-column(10);
	}

	@include breakpoint(large) {
	  width: grid-column(8);
	}

	padding-bottom: 55px;

  .odd {
      label {
		  background-color: #f9f8f4;
	  }

      input[disabled] {
		  background-color: #f9f8f4;
	  }
  }

  label {
    background-color: white;
    float: $global-left;
    font-size: 100%;
    padding: 0.7em;
    font-weight: bold;
    color: inherit;
    margin: 0;
    white-space: nowrap;
    width: grid-column(12);

    @include breakpoint(large) {
        @include grid-column-gutter();
        width: grid-column(3);
    }
  }

  input[disabled] {
    @include grid-column-gutter();
    box-shadow: none;
    float: none;
    height: 2.8125em;
    margin: 0 auto;
    border-color: #f3f1e9;
    border-top: 0;
    border-right: 0;
    background: #fff;
    width: grid-column(12);

    @include breakpoint(large) {
      float: $global-left;
      width: grid-column(9);
    }
  }

  input[type='email'] {
    border-top-color: #ebebeb;
    border-left-color: #ebebeb;
    border-bottom-color: #fff;
    border-right-color: #fff;
    border-style: solid;
    border-width: 2px;
    margin: 0;
  }

  .notify-me-note * {
    text-align: center;
  }

  .confirm-product {
    padding-top: 35px;
    padding-bottom: 15px;
    font-weight: bold;
    text-align: center;
  }

  .notify-me-size {
    border-bottom: 0 !important;
  }

  .notify-me-email {
    padding-top: 45px;
    display: inline-block;
    width: 100%;
    text-align: center;

    span {
      font-weight: bold;
    }

    label {
      display: none;
    }

    .email-container {
      margin-top: 15px;
      border: 2px solid #fff;
    }

    .error {
      border: inherit !important;
    }
  }
}

.notify-me-footer-container {
	background: #fff;
	width: 100%;
	display: inline-block;

	.notify-me-footer {
    @include grid-column-gutter();
    float: none;
    margin-left: auto;
    margin-right: auto;
    padding-top: 25px;
    padding-bottom: 25px;
    text-align: center;
    width: grid-column(12);

		@include breakpoint(medium) {
      width: grid-column(10);
		}

		@include breakpoint(large) {
      width: grid-column(5);
		}

		button {
			width: 100%;
		}
	}
}
