$resultCounterFontSize: em-calc(13px) !default;
$resultCounterFontColor: $brandColorPalette3 !default;
$resultCounterFontSizeMobile: 15px !default;
$resultCounterSepFontColor: $brandColorPalette7 !default;
$resultCounterSepFontSize: 35px !default;
$resultCounterSepFontSizeMobile: 30px !default;
$resultCounterSepContent: '/' !default;
$resultCounterLabelFontSize: 11px !default;
$resultCounterLabelFontSizeMobile: 9px !default;


$resultCounterEnabled: false !default;
@if ($resultCounterEnabled) {
	//.no-lanes {
		.filters-page-numbering {
			position: absolute;
			right: 12px;
			top: 6px;
			z-index: 10;

			@include breakpoint(medium) {
				right: 26px;
				top: 30px;
			}

			@include breakpoint(large) {
				right: 15px;
			}

			.counter-sep {
				color: $resultCounterSepFontColor;
				font-size: em-calc($resultCounterSepFontSizeMobile);
				margin-right: -6px;

				@include breakpoint(medium) {
					font-size: em-calc($resultCounterSepFontSize);
				}

				&::after {
					content: $resultCounterSepContent;
				}
			}

			.product-counter {
				color: $resultCounterFontColor;
				display: inline-block;
			}

			.item-count {
				display: block;
				font-family: $header-font-family;
				font-size: em-calc($resultCounterFontSizeMobile);
				padding-left: 2px;

				@include breakpoint(medium) {
					font-size: $resultCounterFontSize;
				}
			}

			.counter-label {
				font-family: $header-font-family;
				font-size: em-calc($resultCounterLabelFontSizeMobile);

				@include breakpoint(medium) {
					font-size: em-calc($resultCounterLabelFontSize);
				}
			}
		}
}
