//Product Block Color Swatches
@import 'catalog/color-swatches';

@if ($colorSwatchesEnabled) {
  .product-block .info {
    // Bottom-align swatches
    .swatch {
      align-items: center;
      cursor: pointer;
      display: flex;

      img {
        max-height: $productBlockColorSwatchHeightMobile;
        @include breakpoint(medium) {
          margin: 0 auto;
          max-height: 100%;
        }
      }
    }

    .more-swatches {
      cursor: pointer;
      font-size: 12px;

      span {
        font-weight: normal;
      }
    }

    .color-swatches {
      cursor: default;
      display: flex;
      min-height: auto;
    }
  }
}
