$active-facets-height: null !default;
$facet-menu-width-option-tiles: false !default;
$facet-menu-clear-all-button-font-size: null !default;
$facet-menu-clear-all-container-margin: 0 0 0 rem-calc(10) !default;
$facet-menu-clear-all-button-icon-color: null !default;
$facet-menu-clear-all-button-icon-padding: 0 rem-calc(4) 0 rem-calc(4) !default;
$facet-menu-actions-apply-font-size: null !default;
$facet-menu-actions-apply-font-size-medium: null !default;
$facet-menu-actions-apply-letter-spacing-medium: null !default;
$facet-menu-actions-apply-line-height-medium: null !default;
$facet-menu-actions-apply-line-height: rem-calc(22) !default;
$facet-menu-actions-apply-letter-spacing: rem-calc(0.4) !default;
$facet-menu-actions-apply-background: get-color(secondary)!default;
$facet-menu-actions-apply-padding-medium: null !default;
$active-facets-letter-spacing: null !default;
$active-facets-margin-bottom: 0 !default;
$facet-active-filter-color-display: null !default;
$facet-active-filter-counter-display: null !default;
$face-menu-line-height: null !default;
$face-menu-line-height-medium: null !default;
$facet-container-background: null !default;
$facet-menu-actions-clear-all-width: null !default;
$facet-menu-active-facets-border-color: null !default;
$facet-menu-active-facets-check-left: rem-calc(2) !default;
$facet-menu-active-facets-check-top: rem-calc(2) !default;
$facet-menu-background: null !default;
$facet-menu-background-active: $facet-menu-background !default;
$facet-menu-border-radius: rem-calc(2) !default;
$facet-menu-checked-before-background: get-color(secondary) !default;
$facet-menu-checked-after-font-size: null !default;
$facet-menu-actions-apply-width: 90% !default;
$facet-menu-actions-apply-min-width: null !default;
$facet-menu-actions-apply-max-width: null !default;
$facet-menu-checked-before-outline: get-color(secondary) rem-calc(1) solid !default;
$facet-menu-checked-border-color: get-color(secondary) !default;
$facet-menu-sub-menu-checkbox-margin-left: null !default;
$facet-menu-size-filter-input-display: null !default;
$facet-menu-clear-filter-bg-color: null !default;
$facet-menu-clear-filter-font-size: null !default;
$facet-menu-clear-filter-margin: null !default;
$facet-menu-clear-filter-padding: null !default;
$facet-menu-clear-filter-text-transform: none !default;
$facet-menu-clear-filter-text-transform-medium: null !default;
$facet-menu-clear-filter-text-color: null !default;
$facet-menu-clear-filter-text-color-medium: null !default;
$facet-menu-clear-filter-font-size-medium: null !default;
$facet-menu-clear-filter-letter-spacing-medium: null !default;
$facet-menu-color-active: $facet-menu-color !default;
$facet-menu-color-swatch-inverse-check-color: get-color(secondary) !default;
$facet-menu-color-swatch-label-font-size: rem-calc(12) !default;
$facet-menu-color-swatch-label-text-align: null !default;
$facet-menu-color: null !default;
$facet-menu-dropdown-active-bg-color: null !default;
$facet-menu-dropdown-active-float: none !default;
$facet-menu-dropdown-active-border-color: null !default;
$facet-menu-dropdown-active-border-radius: null !default;
$facet-menu-dropdown-active-border: null !default;
$facet-menu-dropdown-active-color: null !default;
$facet-menu-dropdown-active-container-height: rem-calc(70) !default;
$facet-menu-dropdown-active-container-min-height: rem-calc(70) !default;
$facet-menu-dropdown-active-padding: null !default;
$facet-menu-dropdown-active-display: flex !default;
$facet-menu-dropdown-active-display-hide: null !default;
$facet-menu-dropdown-color-min-width: null !default;
$facet-menu-dropdown-color-width: null !default;
$facet-menu-dropdown-color-padding-left: null !default;
$facet-menu-dropdown-disabled-color: null !default;
$facet-menu-dropdown-height: null !default;
$facet-menu-dropdown-line-separator-color: null !default;
$facet-menu-dropdown-max-number-columns: null !default;
$facet-menu-dropdown-padding-left: null !default;
$facet-menu-dropdown-padding-right: null !default;
$facet-menu-dropdown-width-per-column-color: null !default;
$facet-menu-dropdown-width-per-column: null !default;
$facet-menu-dropdown-width: null !default;
$facet-menu-dropdown-active-margin: null !default;
$facet-menu-face-close-icon-font-size: null !default;
$facet-menu-filter-checkbox-color: null !default;
$facet-menu-filter-height: null !default;
$facet-menu-font-size: null !default;
$facet-menu-active-filters-container-margin: null !default;
$facet-menu-active-filters-container-width: null !default;
$facet-menu-active-filters-decoupled-container-justify-content: null !default;
$facet-menu-active-filters-decoupled-container-min-width: null !default;
$facet-menu-active-filters-product-info-margin: rem-calc(30) 0 0 0 !default;
$facet-menu-active-filters-product-info-margin-sticky: null !default;
$facet-menu-active-filters-product-info-margin-sticky-with-filters: null !default;
$facet-menu-active-filters-product-info-mobile-grid-row: 1 !default;
$facet-menu-product-count-display: flex !default;
$facet-menu-product-count-justify-content: null !default;
$facet-menu-product-count-width: null !default;
$facet-menu-product-count-margin-top: null !default;
$facet-menu-product-count-font-weight: null !default;
$facet-menu-sort-font-color: null !default;
$facet-menu-sort-font-family: null !default;
$facet-menu-sort-font-size: 14 !default;
$facet-menu-sort-icon-margin-left: auto !default;
$facet-menu-sort-by-value-width: rem-calc(98) !default;
$facet-menu-sticky-borders: null !default;
$facet-menu-sticky-box-shadow: null !default;
$facet-menu-sticky-mobile-main-nav-offset-custom-medium: null !default;
$facet-menu-sticky-mobile-main-nav-offset-custom-search: null !default;
$facet-menu-sticky-mobile-main-nav-offset-custom: rem-calc(95) !default;
$facet-menu-sticky-mobile-main-nav-offset-to-top-custom-medium: null !default;
$facet-menu-sticky-mobile-main-nav-offset-medium: null !default;
$facet-menu-sticky-mobile-main-nav-offset: null !default;
$facet-menu-sticky-padding: null !default;
$facet-menu-sticky-position: fixed !default;
$facet-menu-sticky-transition: 0.3s top ease-in-out !default;
$facet-menu-sticky-z-index: z-index(offcanvas)-2 !default;
$facet-menu-title-text-transform: null !default;
$facet-menu-title-text-font-size-medium: null !default;
$off-canvas-color-swatch-selected-check-left: rem-calc(2) !default;
$off-canvas-color-swatch-selected-check-top: rem-calc(2) !default;
$off-canvas-facets-active-filters-close-icon-color: $light-gray !default;
$off-canvas-facets-active-filters-sticky-bkgd: null !default;
$off-canvas-facets-active-filters-sticky-height: rem-calc(72) !default;
$off-canvas-facets-sticky-product-count-display: none !default;
$off-canvas-facets-active-padding: null !default;
$off-canvas-facet-active-filter-color-display: null !default;
$off-canvas-filter-menu-category-container-width: null !default;
$facet-menu-active-filters-list-display: null !default;
$facet-menu-active-filters-list-flex-wrap: null !default;
$facet-menu-active-filters-fetching-display: null !default;
$facet-menu-active-filters-expand-background: $facet-container-background !default;
$facet-menu-active-filters-expand-border: $facet-menu-active-facets-border-color rem-calc(1) solid !default;
$facet-menu-active-filters-expand-border-radius: null !default;
$facet-menu-active-filters-expand-line-height: $facet-menu-active-facets-font-size !default;
$facet-menu-active-filters-expand-margin: 0 rem-calc(10) 0 0 !default;
$facet-menu-active-filters-expand-text-transform: null !default;
$facet-menu-active-filters-expand-letter-spacing: null !default;
$facet-menu-active-filters-expand-padding-left: rem-calc(10) !default;
$facet-menu-active-filters-expand-padding-right: rem-calc(10) !default;
$facet-menu-active-filters-expand-title-font-size: null !default;
$facet-menu-active-filters-expand-title-font-weight: null !default;
$facet-menu-active-filters-expand-title-line-height: null !default;
$facet-menu-active-filters-expand-color: get-color(secondary) !default;
$facet-menu-active-filters-expand-icon-color: $facet-menu-dropdown-line-separator-color !default;
$facet-menu-active-filters-expand-font-weight: null !default;
$facet-menu-active-filters-expand-icon-font-weight: null !default;
$facet-menu-circular-loader-display: null !default;
$facet-menu-circular-loader-active-display: null !default;
$facet-menu-star-rating-active-star-color: null !default;
$facet-menu-star-rating-circular-checkbox: false !default;
$facet-menu-star-rating-star-color: null !default;
$top-bar-facets-background: null !default;
$facet-menu-filter-right-margin: rem-calc(10) !default;
$facet-menu-filter-bottom-margin: rem-calc(0) !default;
$facet-menu-number-active-facets-order: null !default;
$facet-menu-number-active-facets-display: none !default;
$no-color-swatches-enabled-mobile: false !default;
$no-color-swatches-enabled-desktop: false !default;
$no-color-swatches-active-facet-background: rgba(get-color(primary), 0.08) !default;
$no-color-swatches-selection-margin: 0 rem-calc(5) rem-calc(10) 0 !default;
$no-color-swatches-selection-padding: rem-calc(8) 0 !default;
$no-color-swatches-label-margin: 0 !default;
$no-color-swatches-label-padding: 0 0 0 rem-calc(20) !default;
$facet-menu-filter-margin-bottom: rem-calc(0) !default;
$facet-menu-filter-margin-right: rem-calc(10) !default;
$off-canvas-filter-sort-bar-color: null !default;
$off-canvas-filter-use-grid: true !default;
$filter-menu-title-text-color: get-color(secondary) !default;
$off-canvas-facets-active-filters-display: null !default;
$off-canvas-facets-active-filters-flex-wrap: null !default;
$off-canvas-facets-active-filters-gap: null !default;
$facet-container-product-counter-color: null !default;
$facet-container-product-counter-margin-top: rem-calc(20) !default;
$facet-container-product-counter-padding-bottom: null !default;
$facet-sub-menu-options-label-padding-right: null !default;
$off-canvas-sub-menu-options-selection-min-width: rem-calc(60) !default;
$off-canvas-product-block-selected-color-swatch-box-shadow: null !default;
$off-canvas-color-swatch-selected-check-content: null !default;
$facet-menu-active-facets-check-content: null !default;
$facet-menu-filter-flex-centered-margin-left: 0 !default;
$facet-menu-filter-flex-centered-margin-top: null !default;
$facet-menu-options-selectable-color-swatches-width: null !default;
$facet-menu-sub-menu-header-font-family: null !default;
$facet-menu-sub-menu-header-font-weight: null !default;
$filter-sort-menu-border-color: $facet-menu-background !default;
$facet-menu-sort-icon: null !default;
$facet-menu-dropdown-padding-left-medium: null !default;
$facet-menu-dropdown-padding-right-medium: null !default;
$singleDropdownColumnWidth-medium: null !default;
$facet-menu-sort-icon-color: $facet-menu-background !default;
$facet-menu-dropdown-border-bottom: $facet-menu-dropdown-line-separator-color rem-calc(1) solid !default;
$facet-menu-actions-apply-width-medium: null !default;
$facet-menu-actions-apply-button-background: get-color(primary) !default;
$top-bar-facets-padding-large: null !default;
$facet-container-padding-right: rem-calc(120) !default;
$filter-menu-sub-menu-options-active-border: null !default;
$filter-menu-sub-menu-options-active-padding: null !default;
$filter-menu-sub-menu-options-margin-left: null !default;
$facet-menu-filter-sort-menu-justify-content: null !default;
$facet-menu-filter-sort-menu-width: null !default;
$facet-menu-filter-title-text-font-size: null !default;
$facet-menu-filter-title-text-font-weight: null !default;
$facet-menu-filter-title-text-width: null !default;
$facet-menu-filter-title-text-padding-right: null !default;
$facet-menu-title-text-color-medium: null !default;
$facet-menu-title-icon-color-medium: null !default;
$facet-menu-title-icon-font-size-medium: null !default;
$facet-menu-title-icon-position-medium: null !default;
$facet-menu-title-icon-top-medium: null !default;
$facet-menu-sub-menu-options-active-max-height: null !default;
$facet-menu-dropdown-white-space-medium: null !default;
$facet-menu-checkbox-checked-border-color: get-color(secondary) !default;
$facet-menu-product-sort-radio-select-margin-top: null !default;
$facet-menu-checkbox-after-color: null !default;
$facet-menu-dropdown-padding-bottom: rem-calc(15) !default;
$facet-menu-dropdown-padding-top: rem-calc(20) !default;
$facet-menu-dropdown-flex-flow: column wrap !default;
$facet-menu-filter-type-color-flex-direction: null !default;
$facet-menu-filter-type-color-selection-width: null !default;
$facet-menu-filter-type-color-flex-centered-height: null !default;
$facet-menu-filter-type-color-flex-centered-margin-top: null !default;
$facet-menu-filter-type-color-label-flex-direction: null !default;
$facet-menu-filter-type-color-label-width: null !default;
$facet-menu-filter-type-color-swatch-border: null !default;
$facet-menu-filter-type-color-swatch-border-radius: null !default;
$facet-menu-filter-type-color-swatch-margin-right: null !default;
$facet-menu-filter-type-color-text-margin-right: null !default;
$facet-menu-filter-type-color-text-padding-top: null !default;
$facet-menu-filter-type-color-text-num-products-display: null !default;
$facet-menu-filter-type-color-checkbox-display: null !default;
$facet-menu-filter-type-color-active-swatch-outline-color: null !default;
$facet-menu-filter-type-color-active-swatch-outline-width: null !default;
$facet-menu-filter-sort-menu-min-width: null !default;
$facet-menu-filter-sort-menu-padding-left: null !default;
$facet-menu-filter-title-text-before-font-size: null !default;
$facet-menu-filter-title-text-before-line-height: null !default;
$facet-menu-filter-title-text-before-position: null !default;
$facet-menu-filter-title-text-before-top: null !default;
$off-canvas-active-filters-item-count-margin-right: null !default;
$top-facets-container-active-filters-container-border-bottom: null !default;
$top-facets-container-active-filters-container-margin-top: null !default;
$facet-container-decoupled-facet-container-padding-bottom: null !default;
$facet-container-decoupled-facet-container-border-bottom: null !default;
$facet-filter-menu-title-text-color: null !default;
$facet-filter-menu-title-text-padding: rem-calc(20) !default;
$facet-menu-decoupled-selected-facets-container-align-items: center !default;
$facet-menu-sort-button-font-size: rem-calc(13) !default;
$facet-menu-sort-button-font-weight: null !default;
$facet-menu-sort-button-padding: null !default;
$facet-menu-active-filter-label-text-transform: lowercase !default;
$facet-menu-clear-all-container-border-left: $facet-menu-dropdown-line-separator-color rem-calc(1) solid !default;
$facet-menu-clear-all-container-div-color: null !default;
$facet-menu-clear-all-container-div-dfisplay: null !default;
$facet-menu-clear-all-container-div-font-family: null !default;
$facet-menu-clear-all-container-div-font-size: null !default;
$facet-menu-clear-all-container-div-jusitfy-content: null !default;
$facet-menu-clear-all-container-div-line-height: null !default;
$facet-menu-active-filters-item-count-margin-right: null !default;
$facet-menu-clear-all-button-border-left: null !default;
$facet-menu-clear-all-button-border-radius: null !default;
$facet-menu-clear-all-button-font-weight: null !default;
$facet-menu-clear-all-button-letter-spacing: null !default;
$facet-menu-clear-all-button-text-transform: null !default;
$facet-menu-filter-type-color-selection-margin: null !default;
$facet-menu-active-filters-expand-title-white-space: null !default;
$facet-sub-menu-options-label-color: null !default;
$facet-sub-menu-options-label-margin-right: null !default;
$facet-sub-menu-options-active-border-radius: null !default;
$facet-menu-title-icon-top-before-font-size: null !default;
$facet-menu-decoupled-selected-facets-container-max-width: null !default;
$facet-menu-clear-all-container-padding: 0 0 0 rem-calc(20) !default;
$facet-menu-clear-all-container-width: null !default;
$facet-menu-clear-all-container-flex-grow: null !default;
$facet-menu-active-filters-columns-width: null !default;
$facet-menu-clear-all-button-margin-left: null !default;
$facet-menu-clear-all-button-margin-right: null !default;
$top-bar-facets-margin-left-xlarge: null !default;
$top-bar-facets-max-width-xlarge: null !default;
$top-bar-facets-padding-xlarge: null !default;
$top-bar-facets-margin-left: null !default;
$refine-search-btn-container-background-image: null !default;
$refine-search-btn-container-background-image-display: null !default;
$refine-search-btn-container-background-image-height: null !default;
$refine-search-btn-container-background-image-width: null !default;
$filter-menu__sub-menu--filter-container-active-display: block !default;
$off-canvas-facet-active-filter-letter-spacing: 0.4 !default;
$off-canvas-facet-active-filter-letter-spacing-line-height: inherit !default;
$off-canvas-clear-all-letter-spacing: 0.4 !default;
$off-canvas-clear-all-line-height: 12 !default;
$off-canvas-sub-menu-options-color-swatch-box-shadow: null !default;
$facet-selectable-color-swatches-row-gap: null !default;
$facet-selectable-color-swatches-margin-top: null !default;
$off-canvas-filter-content-height: null !default;
$facet-menu-checked-icon: null !default;
$facet-menu-checked-font-size: null !default;
$facet-menu-checked-line-height: null !default;
$active-filter-icon-close: $icon-close !default;
$active-filter-size: 13 !default;

//MOBILE+TABLET STYLES
@mixin off-canvas-facets {
  $off-canvas-clear-all-background: transparent !default;
  $off-canvas-clear-all-bg: null !default;
  $off-canvas-clear-all-font-size: rem-calc(13) !default;
  $off-canvas-clear-all-font-family: null !default;
  $off-canvas-clear-all-border: 1px solid $light-gray !default;
  $off-canvas-clear-all-border-left: null !default;
  $off-canvas-clear-all-border-radius: rem-calc(10) !default;
  $off-canvas-clear-all-white-space: null !default;
  $off-canvas-filter-controls-width: null !default;
  $off-canvas-active-filter-container-flex-direction: null !default;
  $off-canvas-active-filter-container-flex-wrap: null !default;
  $off-canvas-active-filter-container-grid-template-columns: 50% 50% !default;
  $off-canvas-active-filter-container-justify-content: null !default;
  $off-canvas-active-filter-container-margin: 0 !default;
  $off-canvas-active-filter-container-width: 100% !default;
  $off-canvas-no-active-filter-container-justify-content: null !default;
  $off-canvas-active-filters-list-container-height: null !default;
  $facet-menu-active-filters-product-info-mobile-top: null !default;
  $off-canvas-filter-sub-menu-color-flex-centered-display: null !default;
  $off-canvas-filter-sub-menu-color-flex-centered-margin: null !default;
  $off-canvas-filter-sub-menu-color-label-color: null !default;
  $off-canvas-filter-sub-menu-color-label-margin: null !default;
  $off-canvas-filter-sub-menu-options-active-color-swatch-outline-color: null !default;
  $off-canvas-filter-sub-menu-options-active-color-swatch-outline-width: null !default;
  $off-canvas-sub-menu-options-label-text-margin-right: null !default;
  $off-canvas-sub-menu-options-label-text-color: null !default;
  $off-canvas-sub-menu-options-color-swatch-border: null !default;
  $off-canvas-sub-menu-options-color-swatch-border-radius: null !default;
  $off-canvas-sub-menu-options-color-swatch-height: null !default;
  $off-canvas-sub-menu-options-color-swatch-margin: null !default;
  $off-canvas-sub-menu-options-color-swatch-width: null !default;
  $facet-menu-checked-after-background: null !default;
  $facet-menu-sub-menu-checkbox-border-color: null !default;
  $facet-menu-sub-menu-checkbox-border-radius: null !default;
  $facet-menu-sub-menu-checkbox-height: null !default;
  $facet-menu-sub-menu-checkbox-width: null !default;
  $off-canvas-clear-all-color: get-color(secondary) !default;
  $off-canvas-clear-all-icon-color: $light-gray !default;
  $off-canvas-clear-all-icon-display: null !default;
  $off-canvas-clear-all-text-decoration: null !default;
  $off-canvas-content-article-padding-medium: 0 rem-calc(30) !default;
  $off-canvas-content-article-padding: 0 !default;
  $off-canvas-content-header-margin-medium: 0 rem-calc(40) !important !default;
  $off-canvas-content-header-margin: null !default;
  $off-canvas-content-width-large: null !default;
  $off-canvas-content-width: null !default;
  $off-canvas-facets-active-background: null !default;
  $off-canvas-facets-active-border-width: null !default;
  $off-canvas-facets-active-border-color: null !default;
  $off-canvas-facets-active-border-radius: null !default;
  $off-canvas-facets-active-color: null !default;
  $off-canvas-facets-active-margin: null !default;
  $off-canvas-facets-active-filters-border-bottom: 1px solid $light-gray !default;
  $off-canvas-facets-active-filters-collapsed-height: rem-calc(35) !default;
  $off-canvas-facets-active-filters-margin-top: null !default;
  $off-canvas-facets-active-filters-flex-basis-medium: auto !default;
  $off-canvas-facets-active-filters-flex-basis: 75% !default;
  $off-canvas-facets-active-filters-list-display: block !default;
  $off-canvas-facets-active-filters-list-flex-wrap: null !default;
  $off-canvas-facets-active-filters-list-justify-content: null !default;
  $off-canvas-facets-active-filters-meta-margin-left-medium: 0 !default;
  $off-canvas-facets-active-filters-meta-margin-left: auto !default;
  $off-canvas-facets-active-filters-meta-margin-right: null !default;
  $off-canvas-facets-active-filters-meta-margin-top: null !default;
  $off-canvas-facets-active-filters-separator-color: $light-gray !default;
  $off-canvas-facets-active-filters-sticky-bkgd: linear-gradient($white, $light-gray) !default;
  $off-canvas-facets-active-filters-width-medium: auto !default;
  $off-canvas-facets-active-filters-width: null !default;
  $off-canvas-facets-active-filters-padding: rem-calc(15) !default;
  $off-canvas-facets-category-border-bottom: 1px solid $light-gray !default;
  $off-canvas-filter-close-button-size: rem-calc(15) !default;
  $off-canvas-filter-close-button-content: null !default;
  $off-canvas-filter-content-margin: null !default;
  $off-canvas-filter-content-top: 0 !default;
  $off-canvas-filter-content-padding: null !default;
  $off-canvas-filter-content-button-active-bkgd-color: null !default;
  $off-canvas-filter-controls-border-bottom: null !default;
  $off-canvas-filter-controls-border-top: null !default;
  $off-canvas-filter-controls-button-background-color: get-color(secondary) !default;
  $off-canvas-filter-controls-button-color: $white !default;
  $off-canvas-filter-controls-button-filter-border-right: null !default;
  $off-canvas-filter-controls-button-font-size: 15 !default;
  $off-canvas-filter-controls-button-font-weight: 500 !default;
  $off-canvas-filter-controls-button-icon-padding-left: rem-calc(20) !default;
  $off-canvas-filter-controls-button-icon-padding-right: null !default;
  $off-canvas-filter-controls-button-justify-content: null !default;
  $off-canvas-filter-controls-button-margin: null !default;
  $off-canvas-filter-controls-button-text-transform: none !default;
  $off-canvas-filter-controls-button-width: null !default;
  $off-canvas-filter-controls-margin: rem-calc(20) rem-calc(10) 0 rem-calc(10) !default;
  $off-canvas-filter-controls-min-width: null !default;
  $off-canvas-filter-controls-transform: null !default;
  $off-canvas-filter-menu-category-font-family: null !default;
  $off-canvas-filter-menu-category-margin-bottom: null !default;
  $off-canvas-filter-menu-category-margin-top: null !default;
  $off-canvas-filter-menu-size-menu-column-count: 2 !default;
  $off-canvas-filter-menu-size-menu-max-height: rem-calc(500) !default;
  $off-canvas-filter-menu-sub-menu-header-background-color: null !default;
  $off-canvas-filter-menu-sub-menu-header-font-family: null !default;
  $off-canvas-filter-menu-sub-menu-header-font-style-params: ('size': 15, 'weight': 500, 'kerning': 0.5, 'leading': 15) !default;
  $off-canvas-filter-menu-sub-menu-header-height: null !default;
  $off-canvas-filter-menu-sub-menu-header-margin: null !default;
  $off-canvas-filter-menu-sub-menu-header-padding: rem-calc(17) 0 rem-calc(7) 0 !default;
  $off-canvas-filter-menu-sub-menu-header-type-padding: rem-calc(25) 0 rem-calc(7) 0 !default;
  $off-canvas-filter-menu-sub-menu-header-type-font-size: rem-calc(15) !default;
  $off-canvas-filter-menu-sub-menu-header-type-font-weight: null !default;
  $off-canvas-filter-menu-sub-menu-header-type-line-height: null !default;
  $off-canvas-filter-menu-sub-menu-header-type-letter-spacing: null !default;
  $off-canvas-filter-menu-sub-menu-header-text-transform: null !default;
  $off-canvas-filter-menu-sub-menu-options-label-padding-left: rem-calc(15) !default;
  $off-canvas-filter-menu-sub-menu-margin-bottom: rem-calc(10) !default;
  $off-canvas-filter-menu-sub-menu-order: true !default;
  $off-canvas-filter-menu-pin-sort: false !default;
  $off-canvas-filter-menu-sort-icon: false !default;
  $off-canvas-filter-menu-sub-menu-sort-radio-display: null !default;
  $off-canvas-filter-menu-sub-menu-sort-radio-margin-top: null !default;
  $off-canvas-filter-mobile-container-label-font-style-params: ('size': 15, 'weight': 400, 'kerning': 0.5, 'leading': 15) !default;
  $off-canvas-filter-mobile-container-padding: 0 rem-calc(25) !default;
  $off-canvas-filter-mobile-size-padding: null !default;
  $off-canvas-filter-mobile-size-white-space: null !default;
  $off-canvas-filter-sub-menu-actions-background-color-active: get-color(primary) !default;
  $off-canvas-filter-sub-menu-actions-background-color: null !default;
  $off-canvas-filter-sub-menu-actions-clear-width: null !default;
  $off-canvas-filter-sub-menu-actions-color: null !default;
  $off-canvas-filter-sub-menu-actions-secondary-color: null !default;
  $off-canvas-filter-sub-menu-actions-secondary-padding-right: null !default;
  $off-canvas-filter-sub-menu-actions-secondary-text-align: null !default;
  $off-canvas-filter-sub-menu-actions-weight: 400 !default;
  $off-canvas-filter-sub-menu-actions-padding: null !default;
  $off-canvas-filter-sub-menu-color-column-count-medium: 5 !default;
  $off-canvas-filter-sub-menu-color-column-count: 4 !default;
  $off-canvas-filter-sub-menu-color-column-gap-medium: rem-calc(30) !default;
  $off-canvas-filter-sub-menu-color-column-gap: rem-calc(30) !default;
  $off-canvas-filter-sub-menu-color-flex-order: 5 !default;
  $off-canvas-filter-sub-menu-color-margin-medium: null !default;
  $off-canvas-filter-sub-menu-color-label-font-size: null !default;
  $off-canvas-filter-sub-menu-color-margin: null !default;
  $off-canvas-filter-sub-menu-color-checkbox: none !default;
  $off-canvas-filter-sub-menu-options-padding: null !default;
  $off-canvas-filter-sub-menu-options-selection-active-bkgd-color: null !default;
  $off-canvas-filter-sub-menu-options-selection-color-margin-bottom: null !default;
  $off-canvas-filter-sub-menu-options-selection-color-margin-right: null !default;
  $off-canvas-filter-sub-menu-options-selection-color-padding: null !default;
  $off-canvas-filter-sub-menu-options-selection-color-width: null !default;
  $off-canvas-filter-sub-menu-options-selection-color-label-width: null !default;
  $off-canvas-filter-sub-menu-options-selection-icon-color: null !default;
  $off-canvas-filter-sub-menu-options-selection-icon-display-active: null !default;
  $off-canvas-filter-sub-menu-options-selection-icon-display: null !default;
  $off-canvas-filter-sub-menu-options-selection-icon-font-size: null !default;
  $off-canvas-filter-sub-menu-options-selection-icon-position: null !default;
  $off-canvas-filter-sub-menu-options-selection-icon-right: null !default;
  $off-canvas-filter-sub-menu-options-selection-input-display: null !default;
  $off-canvas-filter-sub-menu-options-selection-label-display-active: null !default;
  $off-canvas-filter-sub-menu-options-selection-swatch-display-active: null !default;
  $off-canvas-filter-sub-menu-product-info-color: get-color(secondary) !default;
  $off-canvas-filter-sub-menu-product-info-font-family: null !default;
  $off-canvas-close-button-padding-top: null !default;
  $off-canvas-close-button-right: null !default;
  $off-canvas-filter-sub-menu-product-info-display: inline-block !default;
  $off-canvas-filter-menu-title-text-color: null !default;
  $off-canvas-filter-menu-sub-menu-header-active-border-bottom: 0 !default;
  $off-canvas-active-filter-container-display: grid !default;
  $off-canvas-active-filters-meta-button-padding-right: 0 !default;
  $off-canvas-open-content-width: null !default;
  $off-canvas-active-filters-list-container-margin-bottom: null !default;
  $off-canvas-active-filters-list-container-margin-top: null !default;
  $off-canvas-active-filters-list-container-padding-left: null !default;
  $off-canvas-active-filters-list-container-max-width: 95% !default;
  $off-canvas-facets-active-font-weight: null !default;
  $filter-menu-category-background-color: null !default;
  $off-canvas-filter-sub-menu-options-selection-label-margin-active: null !default;
  $off-canvas-filter-sub-menu-options-selection-label-text-transform-active: null !default;
  $off-canvas-filter-sub-menu-options-selection-label-first-letter-text-transform-active: null !default;
  $off-canvas-active-filters-meta-button-font-size: null !default;
  $off-canvas-active-filters-meta-button-font-weight: null !default;
  $off-canvas-active-filters-meta-button-padding-left: null !default;
  $filter-menu-category-line-height: null !default;
  $filter-menu-category-font-size: null !default;
  $filter-menu-category-text-transform: null !default;
  $off-canvas-filter-mobile-container-label-font-size: null !default;
  $off-canvas-filter-sub-menu-actions-size: rem-calc(15) !default;
  $off-canvas-filter-sub-menu-actions-button-secondary-size: rem-calc(13) !default;
  $off-canvas-filter-sub-menu-actions-button-secondary-weight: 500 !default;
  $off-canvas-filter-sub-menu-actions-button-secondary-letter-spacing: rem-calc(0.3) !default;
  $off-canvas-filter-menu-title-text-font-size: null !default;
  $off-canvas-filter-menu-title-text-font-weight: null !default;
  $off-canvas-filter-menu-title-text-line-height: null !default;
  $off-canvas-filter-menu-title-text-letter-spacing: null !default;
  $off-canvas-filter-menu-title-text-align: null !default;
  $facet-menu-product-info-color: get-color(secondary) !default;
  $facet-menu-product-info-font-size: 12 !default;
  $off-canvas-filter-controls-button-padding: rem-calc(13) rem-calc(20) !default;
  $facet-menu-active-filters-container-padding: 0 rem-calc(10) !default;
  $off-canvas-filter-content-align-items: null !default;
  $off-canvas-filter-content-display: null !default;
  $off-canvas-filter-content-flex-direction: null !default;
  $off-canvas-filter-content-flex-wrap: null !default;
  $off-canvas-filter-content-width: null !default;
  $off-canvas-filter-controls-filter-width: null !default;
  $facet-menu-active-filters-product-info-mobile-font-family: null !default;
  $facet-menu-active-filters-product-info-color: null !default;
  $facet-menu-active-filters-product-info-mobile-position: null !default;
  $facet-menu-active-filters-product-info-mobile-right: null !default;
  $off-canvas-overlay-fixed-position-medium: null !default;
  $off-canvas-sub-menu-options-label-num-products-color: null !default;
  $off-canvas-sub-menu-options-label-num-products-font-size: null !default;
  $off-canvas-top-bar-facets-container-min-height: null !default;
  $off-canvas-filter-menu-container-height: null !default;
  $off-canvas-filter-close-button-color: null !default;
  $off-canvas-filter-close-button-position: null !default;
  $off-canvas-filter-close-button-right: null !default;
  $off-canvas-filter-close-button-top: null !default;
  $off-canvas-filter-mobile-container-label-font-family: null !default;
  $off-canvas-filter-sub-menu-actions-mobile-padding: null !default;
  $off-canvas-filter-menu-category-font-size: null !default;
  $off-canvas-filter-menu-sub-menu-width: null !default;
  $off-canvas-filter-menu-sub-menu-header-text-align: null !default;
  $off-canvas-filter-menu-title-icon-color: null !default;
  $off-canvas-facets-active-font-family: null !default;
  $off-canvas-facets-active-font-size: null !default;
  $off-canvas-facets-active-height: null !default;
  $off-canvas-facets-active-text-transform: null !default;
  $off-canvas-clear-all-icon-font-size: rem-calc(6) !default;
  $off-canvas-clear-all-icon-margin-right: null !default;
  $off-canvas-active-filter-remove-before-color: null !default;
  $off-canvas-active-filter-remove-before-font-size: null !default;
  $off-canvas-facets-active-filters-background: $white !default;
  $off-canvas-facets-active-filters-list-border-radius: null !default;
  $off-canvas-facets-active-filters-list-color: null !default;
  $off-canvas-facets-active-filters-list-font-family: null !default;
  $off-canvas-facets-active-filters-list-height: null !default;
  $off-canvas-facets-active-filters-list-margin: null !default;
  $off-canvas-facets-active-filters-list-padding: null !default;
  $off-canvas-facets-active-filters-list-text-transform: null !default;
  $off-canvas-facets-active-filters-list-margin-bottom: rem-calc(15) !default;
  $off-canvas-facets-active-filters-list-margin-top: null !default;
  $facet-menu-sticky-label-number-active-facets-display: null !default;
  $off-canvas-filter-sub-menu-options-display: null !default;
  $off-canvas-filter-sub-menu-options-flex-wrap: null !default;
  $off-canvas-filter-sub-menu-options-padding-mobile: null !default;
  $off-canvas-filter-sub-menu-options-width: null !default;
  $off-canvas-filter-sub-menu-options-selection-width: null !default;
  $off-canvas-sub-menu-flex-centered-margin-left: 0 !default;
  $off-canvas-filter-content-background: null !default;
  $off-canvas-filter-content-background-size: null !default;
  $off-canvas-filter-content-display: null !default;
  $off-canvas-filter-content-margin: null !default;
  $off-canvas-filter-content-padding: null !default;
  $off-canvas-filter-content-font-family: null !default;
  $off-canvas-filter-content-font-size: null !default;
  $off-canvas-filter-content-font-weight: null !default;
  $off-canvas-filter-content-text-transform: null !default;
  $off-canvas-filter-content-border: null !default;
  $off-canvas-filter-menu-checkbox-padding-left: null !default;
  $facet-menu-active-filters-list-margin-top: null !default;
  $facet-menu-product-count-text-transform: null !default;
  $off-canvas-filter-sub-menu-color-display: null !default;
  $off-canvas-facets-active-filters-list-height: null !default;
  $off-canvas-facets-active-filters-list-text-align: null !default;
  $off-canvas-filter-sub-menu-options-checkbox-container-display: null !default;
  $off-canvas-filter-sub-menu-options-checkbox-container-padding: null !default;
  $off-canvas-filter-sub-menu-options-selection-margin-bottom: null !default;
  $off-canvas-color-swatch-selected-check-font-size: null !default;
  $off-canvas-sub-menu-options-selection-margin-right: rem-calc(10) !default;
  $off-canvas-filter-sub-menu-options-checkbox-container: null !default;
  $filter-type-color-filter-menu__sub-menu-options-display: null !default;
  $off-canvas-filter-sub-menu-options-selection-margin-top: null !default;
  $facet-menu-active-filters-list-animation: null !default;
  $off-canvas-sub-menu-options-color-swatch-text-font-weight: null !default;
  $off-canvas-sub-menu-options-color-swatch-text-padding-left: null !default;
  $off-canvas-no-active-filter-container-stuck-display: null !default;
  $off-canvas-filter-sub-menu-color-padding-left: null !default;
  $off-canvas-filter-mobile-container-font-family: $font-family-secondary !default;
  $facet-menu-checked-disabled-background: null !default;
  $off-canvas-filter-content-color: null !default;
  $refine-search-btn-container-content: ' ' !default;
  $icon-filter-order: null !default;
  $icon-filter-padding-left: null !default;
  $icon-filter-content: null !default;
  $icon-filter-font-size: null !default;
  $facet-menu-hover-background-color: null !default;
  $off-canvas-sub-menu-options-color-swatch-text-color: null !default;
  $off-canvas-sub-menu-options-color-swatch-text-font-size: null !default;
  $js-off-canvas-overlay-visible-opacity: 0.4 !default;
  $filter-menu__sub-menu--filter-container-item-column-active-display: null !default;
  $facet-value-color-opacity-disabled: null !default;
  $selectable-color-swatches-padding: $off-canvas-filter-sub-menu-options-padding !default;
  $off-canvas-filter-sub-menu-options-gap: null !default;
  $active-filters-meta-display: null !default;
  $active-filters-meta-align-items: null !default;
  $active-filters-meta-margin: 0 null !default;
  $active-filters-icon-redo-margin-left: null !default;
  $active-filters-icon-redo-font-size: null !default;
  $active-filters-icon-redo-color: null !default;
  $active-filters-icon-redo-display: null !default;
  $off-canvas-facets-active-filters-padding-right: null !default;
  $active-filters__clear-all-padding: 0 rem-calc(10) !default;
  $active-filters__clear-all-margin: null !default;
  $filter-checkbox-disabled-background: null !default;
  $off-canvas-filter-content-justify-content: null !default;
  $icon-filter-margin-left: null !default;
  $off-canvas-sub-menu-options-color-swatch-text-letter-spacing: null !default;
  $active-filters-container-no-active-filters-display: null !default;
  $active-filters__clear-all-position: null !default;
  $active-filters__clear-all-z-index: null !default;
  
  .off-canvas-filter-sort-active {
    body.off-canvas-opened {
      overflow: hidden;
    }

    .off-canvas-wrapper {
      .topnav {
        position: absolute;
        top: -1000px;

        &.active {
          top: 0;
        }
      }
    }

    .off-canvas.is-closed {
      .icon-plus,
      .icon-minus {
        display: none;
      }
    }

    //main site content
    .off-canvas-content {
      .off-canvas-filter-controls {
        align-items: center;
        border-bottom: $off-canvas-filter-controls-border-bottom;
        border-top: $off-canvas-filter-controls-border-top;
        display: flex;
        justify-content: space-between;
        margin: $off-canvas-filter-controls-margin;
        min-width: $off-canvas-filter-controls-min-width;
        transform: $off-canvas-filter-controls-transform;
        width: $off-canvas-filter-controls-width;

        &-filter,
        &-sort {
          @include font-styling($size: $off-canvas-filter-controls-button-font-size, $weight: $off-canvas-filter-controls-button-font-weight, $kerning: 0.4, $leading: inherit);
          align-items: center;
          background-color: $off-canvas-filter-controls-button-background-color;
          color: $off-canvas-filter-controls-button-color;
          display: flex;
          justify-content: $off-canvas-filter-controls-button-justify-content;
          margin: $off-canvas-filter-controls-button-margin;
          padding: $off-canvas-filter-controls-button-padding;
          text-transform: $off-canvas-filter-controls-button-text-transform;
          width: $off-canvas-filter-controls-button-width;

          .icon-filter,
          .icon-sort {
            padding-left: $off-canvas-filter-controls-button-icon-padding-left;
            padding-right: $off-canvas-filter-controls-button-icon-padding-right;
          }
        }

        &-filter {
          border-right: $off-canvas-filter-controls-button-filter-border-right;
        }

        .view-layout-switch {
          li:first-child {
            margin-right: rem-calc(6);
          }

          button {
            font-size: rem-calc(14);
            height: rem-calc(35);
            width: rem-calc(35);
          }
        }
      }

      .active-filters-display {
        flex-basis: $off-canvas-facets-active-filters-flex-basis;
        order: 0;
        padding-left: 0;
        padding-right: $off-canvas-facets-active-filters-padding-right;
        width: $off-canvas-facets-active-filters-width;

        @include breakpoint(medium) {
          flex-basis: $off-canvas-facets-active-filters-flex-basis-medium;
          width: $off-canvas-facets-active-filters-width-medium;
        }

        .active-filters-list {
          @include off-canvas-active-filters;

          display: $off-canvas-facets-active-filters-display;
          flex-wrap: $off-canvas-facets-active-filters-flex-wrap;
          column-gap: $off-canvas-facets-active-filters-gap;

          @include breakpoint(medium) {
            line-height: 0;

            &:after {
              color: $off-canvas-facets-active-filters-separator-color;
              content: '|';
            }
          }

          li {
            margin-bottom: rem-calc(10);
            margin-left: 0;
          }

          &.collapsed {
            height: $off-canvas-facets-active-filters-collapsed-height;
            margin-bottom: 0;
            margin-top: $off-canvas-facets-active-filters-margin-top;
            overflow: hidden;
          }

          &.expanded {
            height: auto;
            margin-bottom: 0;
            margin-top: $off-canvas-facets-active-filters-margin-top;
            max-height: 100%;
            overflow: visible;
          }
        }
      }

      .active-filters-meta {
        flex-basis: auto;
        margin-left: $off-canvas-facets-active-filters-meta-margin-left;
        margin-right: $off-canvas-facets-active-filters-meta-margin-right;
        margin-top: $off-canvas-facets-active-filters-meta-margin-top;
        order: 1;

        @include breakpoint(medium) {
          margin-left: $off-canvas-facets-active-filters-meta-margin-left-medium;

          button {
            line-height: 0;
          }
        }

        .active-filters__clear-all {
          @include font-styling($size: $off-canvas-clear-all-font-size, $weight: 400, $kerning: $off-canvas-clear-all-letter-spacing, $leading: $off-canvas-clear-all-line-height);
          align-items: center;
          background: $off-canvas-clear-all-bg;
          background-color: $off-canvas-clear-all-background;
          border: $off-canvas-clear-all-border;
          border-left: $off-canvas-clear-all-border-left;
          border-radius: $off-canvas-clear-all-border-radius;
          color: $off-canvas-clear-all-color;
          display: flex;
          font-family: $off-canvas-clear-all-font-family;
          height: rem-calc(25);
          margin: $active-filters__clear-all-margin;
          padding: $active-filters__clear-all-padding;
          position: $active-filters__clear-all-position;
          text-decoration: $off-canvas-clear-all-text-decoration;
          text-transform: none;
          white-space: $off-canvas-clear-all-white-space;
          width: auto;
          z-index: $active-filters__clear-all-z-index;

          &:after {
            @include icomoonFont($icon-close);
            color: $off-canvas-clear-all-icon-color;
            display: $off-canvas-clear-all-icon-display;
            font-size: $off-canvas-clear-all-icon-font-size;
            margin-left: rem-calc(5);
            margin-right: $off-canvas-clear-all-icon-margin-right;
          }
        }

        .active-filters__active-text {
          display: none;
        }
      }

      .active-filters-container {
        display: flex;
        flex-wrap: wrap;
        margin: $facet-menu-active-filters-container-margin;
        padding: $facet-menu-active-filters-container-padding;
        width: $facet-menu-active-filters-container-width;
        &.no-active-filters {
          display: $active-filters-container-no-active-filters-display;
        }

        &.decoupled-selected-facets-container {
          .product-info {
            justify-content: $facet-menu-active-filters-decoupled-container-justify-content;
            min-width: $facet-menu-active-filters-decoupled-container-min-width;
          }
        }

        .product-info {
          @include font-styling($size: $facet-menu-product-info-font-size, $weight: 500, $kerning: 0.4, $leading: 12);
          color: $facet-menu-product-info-color;
          display: $facet-menu-product-count-display;
          font-family: $font-family-secondary;
          font-weight: $facet-menu-product-count-font-weight;
          justify-content: $facet-menu-product-count-justify-content;
          margin-left: auto;
          margin-top: $facet-menu-product-count-margin-top;
          order: 2;
          text-transform: $facet-menu-product-count-text-transform;
          width: $facet-menu-product-count-width;

          .active-filters__item-count {
            margin-right: rem-calc(5);
          }

          .circular-loader {
            display: $facet-menu-circular-loader-display;
          }

          &.fetching-products {
            .active-filters {
              &__item-count,
              &__counter-label {
                display: $facet-menu-active-filters-fetching-display;
              }
            }

            .circular-loader {
              display: $facet-menu-circular-loader-active-display;
            }
          }
        }

        .active-filters-list {
          animation: $facet-menu-active-filters-list-animation;
          display: $facet-menu-active-filters-list-display;
          flex-wrap: $facet-menu-active-filters-list-flex-wrap;
          margin-left: 0;
          margin-top: $facet-menu-active-filters-list-margin-top;
        }
      }

      .topnav {
        height: rem-calc(95);
      }

      .product-list-header-container {
        clear: both;
      }
    }

    .js-off-canvas-overlay.is-visible {
      background-color: $black;
      opacity: $js-off-canvas-overlay-visible-opacity;
    }

    .off-canvas-filter-content {
      align-items: $off-canvas-filter-content-align-items;
      display: $off-canvas-filter-content-display;
      flex-direction: $off-canvas-filter-content-flex-direction;
      flex-wrap: $off-canvas-filter-content-flex-wrap;
      margin: $off-canvas-filter-content-margin;
      padding: $off-canvas-filter-content-padding;
      top: $off-canvas-filter-content-top;
      transition: 0s top ease-in-out;
      width: $off-canvas-filter-content-width;

      .off-canvas-filter-controls-filter {
        background: $off-canvas-filter-content-background;
        background-size: $off-canvas-filter-content-background-size;
        color: $off-canvas-filter-content-color;
        display: $off-canvas-filter-content-display;
        font-family: $off-canvas-filter-content-font-family;
        font-size: $off-canvas-filter-content-font-size;
        font-weight: $off-canvas-filter-content-font-weight;
        justify-content: $off-canvas-filter-content-justify-content;
        height: $off-canvas-filter-content-height;
        margin: $off-canvas-filter-content-margin;
        padding: $off-canvas-filter-content-padding;
        text-transform: $off-canvas-filter-content-text-transform;
        border: $off-canvas-filter-content-border;
        width: $off-canvas-filter-controls-filter-width;
        &::before {
          background-image: $refine-search-btn-container-background-image;
          content: $refine-search-btn-container-content;
          display: $refine-search-btn-container-background-image-display;
          height: $refine-search-btn-container-background-image-height;
          width: $refine-search-btn-container-background-image-width;
        }
        .icon-filter {
          font-size: $icon-filter-font-size;
          margin-left: $icon-filter-margin-left;
          order: $icon-filter-order;
          padding-left: $icon-filter-padding-left;
          &::before {
            content: $icon-filter-content;
          }
        }
      }

      .off-canvas-filter-controls-filter.active,
      .off-canvas-filter-controls-sort.active {
        background-color: $off-canvas-filter-content-button-active-bkgd-color;
      }

      .active-filters-container {
        display: $off-canvas-active-filter-container-display;
        flex-direction: $off-canvas-active-filter-container-flex-direction;
        flex-wrap: $off-canvas-active-filter-container-flex-wrap;
        grid-template-columns: $off-canvas-active-filter-container-grid-template-columns;
        justify-content: $off-canvas-active-filter-container-justify-content;
        margin: $off-canvas-active-filter-container-margin;
        width: $off-canvas-active-filter-container-width;
        &.stuck-to-top {
          display: $off-canvas-no-active-filter-container-stuck-display;
        }
        &.no-active-filters {
          justify-content: $off-canvas-no-active-filter-container-justify-content;
        }

        .active-filters-list-container {
          grid-column: 1;
          grid-row: 1;
          height: $off-canvas-active-filters-list-container-height;
          margin-bottom: $off-canvas-active-filters-list-container-margin-bottom;
          margin-top: $off-canvas-active-filters-list-container-margin-top;
          padding-left: $off-canvas-active-filters-list-container-padding-left;

          .active-filters-list__active-filter {
            max-width: $off-canvas-active-filters-list-container-max-width;

            .active-filter-remove {
              &:before {
                color: $off-canvas-active-filter-remove-before-color;
                font-size: $off-canvas-active-filter-remove-before-font-size;
              }
            }
          }
        }

        .active-filters-meta {
          align-items: $active-filters-meta-align-items;
          display: $active-filters-meta-display;
          grid-column: 2;
          grid-row: 1;
          margin: $active-filters-meta-margin;

          button {
            font-size: $off-canvas-active-filters-meta-button-font-size;
            font-weight: $off-canvas-active-filters-meta-button-font-weight;
            padding-left: $off-canvas-active-filters-meta-button-padding-left;
            padding-right: $off-canvas-active-filters-meta-button-padding-right;
          }
          a.icon-redo {
            color: $active-filters-icon-redo-color;
            display: $active-filters-icon-redo-display;
            font-size: $active-filters-icon-redo-font-size;
            margin-left: $active-filters-icon-redo-margin-left;
          }
        }

        .product-info {
          color: $facet-menu-active-filters-product-info-color;
          font-family: $facet-menu-active-filters-product-info-mobile-font-family;
          grid-column: 2;
          grid-row: $facet-menu-active-filters-product-info-mobile-grid-row;
          margin: $facet-menu-active-filters-product-info-margin;
          position: $facet-menu-active-filters-product-info-mobile-position;
          right: $facet-menu-active-filters-product-info-mobile-right;
          top: $facet-menu-active-filters-product-info-mobile-top;
          display: $facet-active-filter-counter-display;
        }
      }

      .active-filters-display {
        @include see-more-button;

        button {
          background: $off-canvas-facets-active-background;
          border-color: $off-canvas-facets-active-border-color;
          border-radius: $off-canvas-facets-active-border-radius;
          border-width: $off-canvas-facets-active-border-width;
          color: $off-canvas-facets-active-color;
          font-family: $off-canvas-facets-active-font-family;
          font-size: $off-canvas-facets-active-font-size;
          font-weight: $off-canvas-facets-active-font-weight;
          height: $off-canvas-facets-active-height;
          margin: $off-canvas-facets-active-margin;
          padding: $off-canvas-facets-active-padding;
          text-transform: $off-canvas-facets-active-text-transform;
        }
      }

      &.stuck-to-top .active-filters-container {
        .product-info {
          margin: $facet-menu-active-filters-product-info-margin-sticky-with-filters;
        }

        &.no-active-filters .product-info {
          margin: $facet-menu-active-filters-product-info-margin-sticky;
        }
      }
    }

    .stuck-to-top {
      background-color: $white;
      background-image: $off-canvas-facets-active-filters-sticky-bkgd;
      border-bottom: $facet-menu-sticky-borders;
      border-top: $facet-menu-sticky-borders;
      box-shadow: $facet-menu-sticky-box-shadow;
      margin-top: 0;
      padding: $facet-menu-sticky-padding;
      position: $facet-menu-sticky-position;
      top: $facet-menu-sticky-mobile-main-nav-offset;
      transition: $facet-menu-sticky-transition;
      width: 100%;
      z-index: $facet-menu-sticky-z-index;

      .label-number-active-facets {
        display: $facet-menu-sticky-label-number-active-facets-display;
      }

      &.stuck-to-top-custom {
        top: $facet-menu-sticky-mobile-main-nav-offset-custom;
        transition: 0s top ease-in-out;

        @include breakpoint(medium) {
          top: $facet-menu-sticky-mobile-main-nav-offset-to-top-custom-medium;
        }
      }

      &.stuck-to-top-search-modal {
        top: rem-calc($facet-menu-sticky-mobile-main-nav-offset + $facet-menu-sticky-mobile-main-nav-offset-custom-search);
        transition: 0s top ease-in-out;

        &.stuck-to-top-custom {
          top: rem-calc($facet-menu-sticky-mobile-main-nav-offset-custom + $facet-menu-sticky-mobile-main-nav-offset-custom-search);
        }
      }

      @include breakpoint(medium) {
        top: $facet-menu-sticky-mobile-main-nav-offset-custom-medium;
      }
    }

    .label-number-active-facets {
      display: $facet-menu-number-active-facets-display;
      order: $facet-menu-number-active-facets-order;
      padding-left: rem-calc(5);
    }

    .page-contents {
      padding: $off-canvas-content-article-padding;

      @include breakpoint(medium) {
        padding: $off-canvas-content-article-padding-medium;
      }
    }

    #product-list-header-content {
      margin: $off-canvas-content-header-margin;

      @include breakpoint(medium) {
        margin: $off-canvas-content-header-margin-medium;
        width: auto !important;

        .breadcrumbs {
          margin-left: 0;
        }

        h1 {
          text-align: center !important;
        }
      }
    }
  }//off-canvas-filter-sort-active

  //begin off-canvas styles only
  .js-off-canvas-overlay {
    z-index: z-index(offcanvas)-1;

    &.is-overlay-fixed {
      @include breakpoint(medium) {
        position: $off-canvas-overlay-fixed-position-medium;
      }
    }
  }

  .off-canvas.is-transition-overlap,
  .off-canvas.is-transition-push {
    z-index: z-index(offcanvas);
  }

  .off-canvas-content .off-canvas.position-left {
    transform: translateX(-100%);
    width: $off-canvas-content-width;

    @include breakpoint(large) {
      transform: none;
      width: $off-canvas-content-width-large;
    }
  }

  .off-canvas.is-open {
    width: $off-canvas-open-content-width;
    z-index: z-index(offcanvas);

    //fix for ios mobile scroll
    height: fill-available;
    overflow-y: scroll;
    scroll-behavior: smooth;

    .close-button {
      outline: none;
      padding-top: $off-canvas-close-button-padding-top;
      right: $off-canvas-close-button-right;
    }

    .icon-plus,
    .icon-minus {
      display: block;
    }

    .active-filters-expand {
      display: none;
    }

    .top-bar-facets {
      .facets-container {
        min-height: $off-canvas-top-bar-facets-container-min-height;

        .filter-menu__sub-menu--filter-container.active {
          max-height: 100%;
          transition: none;
          display: $filter-menu__sub-menu--filter-container-active-display;
          overflow: hidden;
        }

        .filter-menu__sub-menu--filter-container {
          max-height: 100%;
          transition: none;
          display: none;
          &.item-column-1.active {
            display: $filter-menu__sub-menu--filter-container-item-column-active-display;
          }
        }

        .filter-menu-container {
          display: flex;
          height: $off-canvas-filter-menu-container-height;
          flex-direction: column;
        }

        .filter-menu-category-container {
          @include z-index(offcanvas);

          background-color: $white;
          display: flex;
          position: sticky;
          top: 0;

          .filter-menu-category {
            background-color: $filter-menu-category-background-color;
            font-size: $filter-menu-category-font-size;
            line-height: $filter-menu-category-line-height;
            text-transform: $filter-menu-category-text-transform;
            width: 100%;
          }

          .icon-close {
            color: $off-canvas-filter-close-button-color;
            font-size: $off-canvas-filter-close-button-size;
            position: $off-canvas-filter-close-button-position;
            right: $off-canvas-filter-close-button-right;
            top: $off-canvas-filter-close-button-top;
            &:before {
              content: $off-canvas-filter-close-button-content;
            }
          }
        }

        .filter-mobile-container {
          @include font-styling($size: 15, $weight: 400, $kerning: 0.5, $leading: 15);

          font-family: $off-canvas-filter-mobile-container-font-family;
          flex: 1 0 auto;
          padding: $off-canvas-filter-mobile-container-padding;
          position: relative;
          z-index: 0;

          label {
            @include font-styling($off-canvas-filter-mobile-container-label-font-style-params...);
            font-family: $off-canvas-filter-mobile-container-label-font-family;
            font-size: $off-canvas-filter-mobile-container-label-font-size;
          }

          @if $off-canvas-filter-menu-pin-sort == true {
            .sort {
              order: -1;
            }
          }

          @if $off-canvas-filter-menu-sub-menu-order == true {
            &.sort {
              order: 1;
            }
          }

          &.filter {
            display: flex;
            flex-direction: column;
            @if $off-canvas-filter-menu-sub-menu-order == true {
              order: 2;
            }

            @if $off-canvas-filter-menu-sub-menu-order == true {
              div[data-filter-name] {
                order: 50;
              }
            }

            div[data-filter-name="Size"] {
              @if $off-canvas-filter-menu-sub-menu-order == true {
                order: 1;
              }

              .filter-menu__sub-menu-options {
                column-count: $off-canvas-filter-menu-size-menu-column-count;
                column-gap: rem-calc(30);

                &.active ul {
                  max-height: $off-canvas-filter-menu-size-menu-max-height;
                }

                &.active li {
                  width: 100%;

                  .sub-menu-options__checkbox-container {
                    display: inline-block;
                    float: none;
                    padding-left: $off-canvas-filter-menu-checkbox-padding-left;
                  }
                }
              }

              .sub-menu-options__label-text {
                white-space: $off-canvas-filter-mobile-size-white-space;
                padding: $off-canvas-filter-mobile-size-padding;
              }
            }

            .filter-type-color.active {
              @if $off-canvas-filter-menu-sub-menu-order == true {
                order: $off-canvas-filter-sub-menu-color-flex-order;
                .filter-menu__sub-menu-options {
                  display: $filter-type-color-filter-menu__sub-menu-options-display;
                }
              }

              .flex-centered {
                display: $off-canvas-filter-sub-menu-color-flex-centered-display;
                margin: $off-canvas-filter-sub-menu-color-flex-centered-margin;
              }

              .sub-menu-options__checkbox {
                display: $off-canvas-filter-sub-menu-color-checkbox;
                flex-shrink: 0;
              }

              .filter-menu__sub-menu-options {
                column-count: $off-canvas-filter-sub-menu-color-column-count;
                column-gap: $off-canvas-filter-sub-menu-color-column-gap;
                display: $off-canvas-filter-sub-menu-color-display;
                margin: $off-canvas-filter-sub-menu-color-margin;

                .sub-menu-options  {
                  display: $off-canvas-filter-sub-menu-options-display;
                  flex-wrap: $off-canvas-filter-sub-menu-options-flex-wrap;
                  gap: $off-canvas-filter-sub-menu-options-gap;
                  padding: $off-canvas-filter-sub-menu-options-padding-mobile;
                  width: $off-canvas-filter-sub-menu-options-width;

                  &__selection {
                    width: $off-canvas-filter-sub-menu-options-selection-width;
                    margin-bottom: $off-canvas-filter-sub-menu-options-selection-margin-bottom;
                    margin-top: $off-canvas-filter-sub-menu-options-selection-margin-top;
                  }
                  &__checkbox-container {
                    display: $off-canvas-filter-sub-menu-options-checkbox-container-display;
                    padding: $off-canvas-filter-sub-menu-options-checkbox-container-padding;
                  }
                }

                @include breakpoint(medium) {
                  column-count: $off-canvas-filter-sub-menu-color-column-count-medium;
                  column-gap: $off-canvas-filter-sub-menu-color-column-gap-medium;
                  margin: $off-canvas-filter-sub-menu-color-margin-medium;
                }

                &.active {
                  padding-top: rem-calc(10);
                  padding-left: $off-canvas-filter-sub-menu-color-padding-left;

                  .sub-menu-options__selection {
                    &.active {
                      .sub-menu-options__color-swatch  {
                        outline-color: $off-canvas-filter-sub-menu-options-active-color-swatch-outline-color;
                        outline-width:  $off-canvas-filter-sub-menu-options-active-color-swatch-outline-width;
                      }
                    }
                  }
                }
              }

              .sub-menu-options__label {
                display: flex;
                flex-direction: column;
                padding-left: 0 !important;

                span {
                  color: $off-canvas-filter-sub-menu-color-label-color;
                  float: none;
                  font-size: $off-canvas-filter-sub-menu-color-label-font-size;
                  margin: $off-canvas-filter-sub-menu-color-label-margin;
                }

                &-text--num-products {
                  opacity: 0;
                  position: absolute;
                }
              }

              .sub-menu-options {
                &.selectable-color-swatches,
                &.active-filters {
                  .sub-menu-options__selection,
                  .sub-menu-options__selection-minimized {
                    margin-bottom: $off-canvas-filter-sub-menu-options-selection-color-margin-bottom;
                    margin-right: $off-canvas-filter-sub-menu-options-selection-color-margin-right;
                    padding: $off-canvas-filter-sub-menu-options-selection-color-padding;
                    width: $off-canvas-filter-sub-menu-options-selection-color-width;

                    .swatch {
                      display: $off-canvas-filter-sub-menu-options-selection-swatch-display-active;
                    }

                    .sub-menu-options__label {
                      width: $off-canvas-filter-sub-menu-options-selection-color-label-width;
                    }

                    .sub-menu-options__label-text {
                      display: $off-canvas-filter-sub-menu-options-selection-label-display-active;
                      margin: $off-canvas-filter-sub-menu-options-selection-label-margin-active;
                      text-transform: $off-canvas-filter-sub-menu-options-selection-label-text-transform-active;

                      &:first-letter {
                        text-transform: $off-canvas-filter-sub-menu-options-selection-label-first-letter-text-transform-active;
                      }
                    }
                  }
                }
              }
            }

            @if $off-canvas-filter-menu-sub-menu-order == true {
              div[data-filter-name="Style"] {
                order: 10;
              }

              div[data-filter-name="Shoe Size"] {
                order: 11;
              }

              div[data-filter-name="Features"] {
                order: 12;
              }

              div[data-filter-name="Category"] {
                order: 14;
              }

              div[data-filter-name="Benefits"] {
                order: 15;
              }

              div[data-filter-name="Activity"] {
                order: 20;
              }

              div[data-filter-name="Fit"] {
                order: 25;
              }
            }
          }

          .filter-menu__mobile-active-filters--active-filter {
            display: none;
          }
        }

        .sub-menu-actions--mobile {
          flex-shrink: 0;
          float: none;
          order: 3;
          padding: $off-canvas-filter-sub-menu-actions-mobile-padding;
          position: sticky;
          z-index: 1;

          .sub-menu-actions__action--apply {
            padding: $off-canvas-filter-sub-menu-actions-padding;
          }

          button {
            @include font-styling($size: $off-canvas-filter-sub-menu-actions-size, $weight: $off-canvas-filter-sub-menu-actions-weight, $kerning: $facet-menu-actions-apply-letter-spacing, $leading: $facet-menu-actions-apply-line-height);

            background-color: $off-canvas-filter-sub-menu-actions-background-color;
            color: $off-canvas-filter-sub-menu-actions-color;
            margin-bottom: 0;
            padding: rem-calc(12.5);

            &.active {
              background-color: $off-canvas-filter-sub-menu-actions-background-color-active;
            }

            &.secondary {
              @include font-styling($size: $off-canvas-filter-sub-menu-actions-button-secondary-size, $weight: $off-canvas-filter-sub-menu-actions-button-secondary-weight, $kerning: $off-canvas-filter-sub-menu-actions-button-secondary-letter-spacing, $leading: 13);
              background-color: transparent;
              border: 0;
              color: $off-canvas-filter-sub-menu-actions-secondary-color;
              padding-right: $off-canvas-filter-sub-menu-actions-secondary-padding-right;
              text-align: $off-canvas-filter-sub-menu-actions-secondary-text-align;
              text-decoration: underline;
              text-transform: none;

              &:hover {
                color: get-color(secondary);
              }
            }
          }

          .product-info {
            @include font-styling($size: 13, $weight: 700, $kerning: 0.5, $leading: 14);
            color: $off-canvas-filter-sub-menu-product-info-color;
            display: $off-canvas-filter-sub-menu-product-info-display;
            font-family: $off-canvas-filter-sub-menu-product-info-font-family;
            margin-left: rem-calc(10);
            padding-top: rem-calc(10);
            text-transform: uppercase;
            width: 50%;

            div {
              display: inline-block;
            }

            .active-filters__item-count {
              @include font-styling($size: 13, $weight: 700, $kerning: 0.5, $leading: 14);
              margin-right: $off-canvas-active-filters-item-count-margin-right;
            }

            .active-filters__counter-label {
              text-transform: uppercase;
            }

            .circular-loader {
              display: $facet-menu-circular-loader-display;
            }

            &.fetching-products {
              .active-filters {
                &__item-count,
                &__counter-label {
                  display: $facet-menu-active-filters-fetching-display;
                }
              }

              .circular-loader {
                display: $facet-menu-circular-loader-active-display;
              }
            }
          }

          .sub-menu-actions__action--clear {
            width: $off-canvas-filter-sub-menu-actions-clear-width;
          }
        }

        .filter-menu-category {
          border-bottom: $off-canvas-facets-category-border-bottom;
          font-family: $off-canvas-filter-menu-category-font-family;
          font-size: $off-canvas-filter-menu-category-font-size;
          margin-top: $off-canvas-filter-menu-category-margin-top;
          margin-bottom: $off-canvas-filter-menu-category-margin-bottom;
          padding: rem-calc(20);
        }

        .filter-menu__sub-menu {
          margin-bottom: $off-canvas-filter-menu-sub-menu-margin-bottom;
          margin-left: 0;
          width: $off-canvas-filter-menu-sub-menu-width;
        }

        .sub-menu-options {
          background-color: transparent;
          padding: $off-canvas-filter-sub-menu-options-padding;

          &.selectable-color-swatches {
            padding: $selectable-color-swatches-padding;
          }
          &__selection,
          &__selection-minimized {
            border: 0;

            .icon-close {
              color: $off-canvas-filter-sub-menu-options-selection-icon-color;
              display: $off-canvas-filter-sub-menu-options-selection-icon-display;
              font-size: $off-canvas-filter-sub-menu-options-selection-icon-font-size;
              margin: 0;
              position: $off-canvas-filter-sub-menu-options-selection-icon-position;
              right: $off-canvas-filter-sub-menu-options-selection-icon-right;
            }

            &.active {
              background-color: $off-canvas-filter-sub-menu-options-selection-active-bkgd-color;

              .icon-close {
                display: $off-canvas-filter-sub-menu-options-selection-icon-display-active;
              }
            }

            input {
              display: $off-canvas-filter-sub-menu-options-selection-input-display;
            }
          }

          @if $no-color-swatches-enabled-mobile == true {
            &.selectable-color-swatches {

              .flex-centered {
                display: flex;
              }

              .sub-menu-options {

                &__label-text {
                  line-height: normal;
                  padding: 0 0 0 rem-calc(10);
                  text-transform: none;
                }

                &__label {
                  align-items: flex-start;

                  .swatch {
                    display: none;
                  }
                }
              }
            }
          }
        }

        .filter-menu-header__type {
          float: none;
          font-size: $off-canvas-filter-menu-sub-menu-header-type-font-size;
          font-weight: $off-canvas-filter-menu-sub-menu-header-type-font-weight;
          letter-spacing: $off-canvas-filter-menu-sub-menu-header-type-letter-spacing;
          line-height: $off-canvas-filter-menu-sub-menu-header-type-line-height;
          padding: $off-canvas-filter-menu-sub-menu-header-type-padding;

          @if $off-canvas-filter-menu-pin-sort == true {
            order: -1;
          }

          &::before {
            @if ($off-canvas-filter-menu-sort-icon == true) {
              @include icomoonFont($icon-sort);
            }
          }
        }

        .filter-menu__sub-menu-header {
          @include font-styling($off-canvas-filter-menu-sub-menu-header-font-style-params...);

          background-color: $off-canvas-filter-menu-sub-menu-header-background-color;
          display: flex;
          font-family: $off-canvas-filter-menu-sub-menu-header-font-family;
          height: $off-canvas-filter-menu-sub-menu-header-height;
          margin: $off-canvas-filter-menu-sub-menu-header-margin;
          padding: $off-canvas-filter-menu-sub-menu-header-padding;
          text-align: $off-canvas-filter-menu-sub-menu-header-text-align;
          text-transform: $off-canvas-filter-menu-sub-menu-header-text-transform;

          &.active {
            border-bottom: $off-canvas-filter-menu-sub-menu-header-active-border-bottom;

            .filter-menu__title-text {
              color: $filter-menu-title-text-color;
            }

          }

          .filter-menu__title-text {
            color: $off-canvas-filter-menu-title-text-color;
            font-size: $off-canvas-filter-menu-title-text-font-size;
            font-weight: $off-canvas-filter-menu-title-text-font-weight;
            letter-spacing: $off-canvas-filter-menu-title-text-letter-spacing;
            line-height: $off-canvas-filter-menu-title-text-line-height;
            text-align: $off-canvas-filter-menu-title-text-align;
            width: 100%;
          }
        }

        .filter-menu__title-text {
          color: $off-canvas-filter-menu-title-text-color;
          float: none;
          padding-left: 0;
        }

        .filter-menu__title-icon {
          &:before {
            color: $off-canvas-filter-menu-title-icon-color;
            position: relative;
          }
        }

        .flex-centered {
          display: flex;
          align-items: center;
          margin-left: $off-canvas-sub-menu-flex-centered-margin-left;

          .sub-menu-options__label-text {
            color: $off-canvas-sub-menu-options-label-text-color;
            line-height: inherit;
            margin-right: $off-canvas-sub-menu-options-label-text-margin-right;

            &.sub-menu-options__label-text--num-products {
              color: $off-canvas-sub-menu-options-label-num-products-color;
              font-size: $off-canvas-sub-menu-options-label-num-products-font-size;
            }
          }

          .sub-menu-options__label {
            float: none;
            margin-right: none;
            padding-left: $off-canvas-filter-menu-sub-menu-options-label-padding-left;

            .sub-menu-options__color-swatch {
              border: $off-canvas-sub-menu-options-color-swatch-border;
              border-radius: $off-canvas-sub-menu-options-color-swatch-border-radius;
              box-shadow: $off-canvas-sub-menu-options-color-swatch-box-shadow;
              height: $off-canvas-sub-menu-options-color-swatch-height;
              margin: $off-canvas-sub-menu-options-color-swatch-margin;
              width: $off-canvas-sub-menu-options-color-swatch-width;
            }
            .sub-menu-options__label-text {
              color: $off-canvas-sub-menu-options-color-swatch-text-color;
              font-size: $off-canvas-sub-menu-options-color-swatch-text-font-size;
              font-weight: $off-canvas-sub-menu-options-color-swatch-text-font-weight;
              letter-spacing: $off-canvas-sub-menu-options-color-swatch-text-letter-spacing;
              padding-left: $off-canvas-sub-menu-options-color-swatch-text-padding-left;
            }
          }

          .product_sort_radio_select {
            margin-right: rem-calc(15);
            margin-top: $off-canvas-filter-menu-sub-menu-sort-radio-margin-top;
            display: $off-canvas-filter-menu-sub-menu-sort-radio-display;
          }
        }

        .sub-menu-options__checkbox {
          border-color: $facet-menu-sub-menu-checkbox-border-color;
          border-radius: $facet-menu-sub-menu-checkbox-border-radius;
          height: $facet-menu-sub-menu-checkbox-height;
          margin-left: $facet-menu-sub-menu-checkbox-margin-left;
          width: $facet-menu-sub-menu-checkbox-width;

          &:focus {
            + span {
              outline: $defaultFocusColor auto 1px;
            }
          }

          &:checked {
            border-color: $facet-menu-checked-border-color;
            &.disabled {
              background: $facet-menu-checked-disabled-background;
            }
            &:after {
              @include icomoonFont($facet-menu-checked-icon);
              font-size: $facet-menu-checked-font-size;
              line-height: $facet-menu-checked-line-height;
            }
          }

          &:hover {
            &::before {
              background-color: $facet-menu-hover-background-color;
            }
            &.disabled {
              &::before {
                background-color: $filter-checkbox-disabled-background;
              }
            }
          }

          &:checked::before {
            background: $facet-menu-checked-before-background;
            outline: $facet-menu-checked-before-outline;
          }

          &:checked::after {
            color: $facet-menu-checked-after-background;
          }
        }

        input {
          margin-right: 0;
          &.disabled {
            background: $filter-checkbox-disabled-background;
          }
        }

        .filter-type-size {
          .sub-menu-options__checkbox {
            display: $facet-menu-size-filter-input-display;
          }
        }

        .selectable-color-swatches {
          .flex-centered {
            display: inline-block;
          }

          .sub-menu-options__selection {
            display: inline-block;
            float: none;
            margin-bottom: rem-calc(30);
            margin-right: $off-canvas-sub-menu-options-selection-margin-right;
            min-width: $off-canvas-sub-menu-options-selection-min-width;
            &.disabled {
              opacity: $facet-value-color-opacity-disabled;
            }
          }

          .product-block-color-swatch.selected {
            box-shadow: $off-canvas-product-block-selected-color-swatch-box-shadow;
            position: relative;

            &:after {
              @include icomoonFont($icon-check);
              color: $white;
              content: $off-canvas-color-swatch-selected-check-content;
              font-size: $off-canvas-color-swatch-selected-check-font-size;
              left: $off-canvas-color-swatch-selected-check-left;
              position: absolute;
              top: $off-canvas-color-swatch-selected-check-top;
            }

            &.inverse-selection-check-color:after {
              color: $facet-menu-color-swatch-inverse-check-color;
            }

            &.icon-check:before {
              display: none;
            }
          }
        }

        .active-filters {
          &-container {
            background: $off-canvas-facets-active-filters-background;
            border-bottom: $off-canvas-facets-active-filters-border-bottom;
            justify-content: center;
            padding: $off-canvas-facets-active-filters-padding;

            .product-info,
            .active-filters__visibility-toggle--more,
            .active-filters__visibility-toggle {
              display: none;
            }

            .active-filters-list-container {
              .active-filters-list__active-filter {
                border-radius: $off-canvas-facets-active-filters-list-border-radius;
                color: $off-canvas-facets-active-filters-list-color;
                font-family: $off-canvas-facets-active-filters-list-font-family;
                height: $off-canvas-facets-active-filters-list-height;
                margin: $off-canvas-facets-active-filters-list-margin;
                padding: $off-canvas-facets-active-filters-list-padding;
                text-transform: $off-canvas-facets-active-filters-list-text-transform;

                .active-filter-remove {
                  &:before {
                    color: $off-canvas-active-filter-remove-before-color;
                    font-size: $off-canvas-active-filter-remove-before-font-size;
                  }
                }
              }
            }
          }

          &-display {
            column-count: 3;
            display: inline-table;
            overflow: visible;
            padding-right: 0;

            .active-filters-list {
              display: $off-canvas-facets-active-filters-list-display;
              flex-wrap: $off-canvas-facets-active-filters-list-flex-wrap;
              height: $off-canvas-facets-active-filters-list-height;
              justify-content: $off-canvas-facets-active-filters-list-justify-content;
              margin-bottom: $off-canvas-facets-active-filters-list-margin-bottom;
              margin-top: $off-canvas-facets-active-filters-list-margin-top;
              max-height: 100%;
              overflow: visible;
              text-align: $off-canvas-facets-active-filters-list-text-align;

              &:after {
                display: none;
              }

              li {
                margin-bottom: rem-calc(10);

                &:last-child {
                  margin-bottom: 0;
                }
              }
            }

          }

          &-meta {
            display: none;
          }

          &-list {
            @include off-canvas-active-filters;
          }
        }
      }

      &.stuck-to-top {
        top: 0;
      }
    }//end top-bar-facets
  }//off-canvas.is-open
}

@mixin total-results-font-style {
  @include font-styling($size: $facet-menu-sort-font-size, $weight: 500, $kerning: 0.5, $leading: 13);
  font-family: $facet-menu-sort-font-family;
}

//DESKTOP STYLES
@mixin top-facets {

  $singleDropdownColumnWidth: rem-calc($facet-menu-dropdown-width-per-column + $facet-menu-dropdown-padding-left + $facet-menu-dropdown-padding-right);

  @include breakpoint(large) {
    .top-bar-facets {
      background-color: $top-bar-facets-background;
      margin-left: $top-bar-facets-margin-left;
      padding: $top-bar-facets-padding-large;
      top: 0;
      transition: 0s top ease-in-out;
      z-index: z-index(topnav)-1;

      @include breakpoint (xlarge) {
        margin-left: $top-bar-facets-margin-left-xlarge;
        max-width: $top-bar-facets-max-width-xlarge;
        padding: $top-bar-facets-padding-xlarge;
      }

      .facets-container .active-filters-container {
        border-bottom: $top-facets-container-active-filters-container-border-bottom;
        margin-top: $top-facets-container-active-filters-container-margin-top;
      }
    }

    .off-canvas.is-closed {
      .icon-plus,
      .icon-minus {
        display: block;
      }
    }

    .decoupled-facet-container {
      background: $facet-container-background;
      display: flex;
      flex-wrap: wrap;
      padding-right: $facet-container-padding-right;
      position: relative;
      padding-bottom: $facet-container-decoupled-facet-container-padding-bottom;
      border-bottom: $facet-container-decoupled-facet-container-border-bottom;

      .sort {
        margin-left: $filter-menu-sub-menu-options-margin-left;

        .filter-menu__sub-menu-options {
          &.active {
            border: $filter-menu-sub-menu-options-active-border;
            padding: $filter-menu-sub-menu-options-active-padding;
          }
        }
      }

      .sort,
      .decoupled-facet-menu {
        height: $facet-menu-filter-height;
        margin-bottom: $facet-menu-filter-margin-bottom;
        margin-right: $facet-menu-filter-margin-right;
        width: auto;

        .filter-menu__sub-menu-header {
          align-items: baseline;
          background: $facet-menu-background;
          border: $facet-menu-dropdown-border-color rem-calc(1) solid;
          border-radius: $facet-menu-border-radius;
          color: $facet-menu-color;
          display: flex;
          font-family: $facet-menu-sub-menu-header-font-family;
          font-size: $facet-menu-font-size;
          font-weight: $facet-menu-sub-menu-header-font-weight;
          height: $facet-menu-filter-height;
          line-height: $face-menu-line-height;
          padding-left: rem-calc(12);
          padding-right: rem-calc(12);
          text-transform: none;

          &.filter-sort-menu {
            background: $facet-menu-color;
            border: $filter-sort-menu-border-color rem-calc(1) solid;
            color: $facet-menu-background;
            display: flex;
            font-size: rem-calc(15);
            justify-content: $facet-menu-filter-sort-menu-justify-content;
            min-width: $facet-menu-filter-sort-menu-min-width;
            padding-left: $facet-menu-filter-sort-menu-padding-left;
            width: $facet-menu-filter-sort-menu-width;

            .filter-menu__title-text {
              font-size: $facet-menu-filter-title-text-font-size;
              font-weight: $facet-menu-filter-title-text-font-weight;
              width: $facet-menu-filter-title-text-width;

              &:before {
                @include breakpoint(medium) {
                  @include icomoonFont($facet-menu-sort-icon);
                  font-size: $facet-menu-filter-title-text-before-font-size;
                  line-height: $facet-menu-filter-title-text-before-line-height;
                  padding-right: $facet-menu-filter-title-text-padding-right;
                  position: $facet-menu-filter-title-text-before-position;
                  top: $facet-menu-filter-title-text-before-top;
                }
              }
            }

            .filter-menu__title-text {
              display: flex;
              font-family: $facet-menu-sort-font-family;
              line-height: $face-menu-line-height;
              padding: 0;

              @include breakpoint(medium) {
                line-height: $face-menu-line-height-medium;
              }

              .sort-by-value {
                margin-left: rem-calc(5);
                text-align: left;
                width: $facet-menu-sort-by-value-width;
              }
            }

            .filter-menu__title-icon {
              color: $facet-menu-sort-icon-color;
              float: none;
              margin-left: $facet-menu-sort-icon-margin-left;
              padding: 0;
              width: auto;
            }
          }

          .filter-menu__title-text {
            color: $facet-filter-menu-title-text-color;
            font-size: rem-calc(15);
            padding-left: 0;
            padding-right: $facet-filter-menu-title-text-padding;
            text-transform: $facet-menu-title-text-transform;
            width: 100%;

            @include breakpoint(medium) {
              font-size: $facet-menu-title-text-font-size-medium;
            }
          }

          &.active {
            .filter-menu__title-icon,
            .filter-menu__title-text {
              color: $facet-menu-title-text-color-medium;
            }
          }

          .filter-menu__title-icon {
            color: $facet-menu-color;
            font-size: rem-calc(12);

            @include breakpoint(medium) {
              color: $facet-menu-title-icon-color-medium;
              font-size: $facet-menu-title-icon-font-size-medium;
              position: $facet-menu-title-icon-position-medium;
              top: $facet-menu-title-icon-top-medium;
            }

            &::before {
              display: inline-block;
              font-size: $facet-menu-title-icon-top-before-font-size;
              position: static;
              right: 0;
            }
          }

          &.active {
            background: $facet-menu-color-active;
            border-bottom: 0;
            border-radius: 0;
            border-width: rem-calc(1);
            color: $facet-menu-background-active;
            padding-bottom: rem-calc(32);

            &::before {
              color: $facet-menu-background-active;
            }

            .filter-menu__title-text,
            .filter-menu__title-icon {
              color: $facet-menu-background-active;
            }

          }
        }

        .filter-menu__sub-menu-options.active {
          border: $facet-menu-dropdown-border-color rem-calc(1) solid;
          border-radius: $facet-sub-menu-options-active-border-radius;
          left: 0;
          margin-top: rem-calc(2);
          max-height: $facet-menu-sub-menu-options-active-max-height;

          // IE10+ hack
          // sass-lint:disable-block no-vendor-prefixes
          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            margin-top: rem-calc(1.5);
          }

          padding-left: $facet-menu-dropdown-padding-left;
          padding-right: $facet-menu-dropdown-padding-right;
          width: $singleDropdownColumnWidth;

          @include breakpoint(medium) {
            padding-left: $facet-menu-dropdown-padding-left-medium;
            padding-right: $facet-menu-dropdown-padding-right-medium;
            white-space: $facet-menu-dropdown-white-space-medium;
            width: $singleDropdownColumnWidth-medium;
          }

          @for $i from 1 through $facet-menu-dropdown-max-number-columns {

            @if $no-color-swatches-enabled-desktop == false {
              &.item-column-color-#{($i)} {
                width: rem-calc($facet-menu-dropdown-width-per-column-color * $i);
              }
            }

            &.item-column-#{($i)} {
              width: rem-calc(($facet-menu-dropdown-width-per-column * $i) + $facet-menu-dropdown-padding-left + $facet-menu-dropdown-padding-right);
            }
          }

          .selectable-color-swatches {
            height: $facet-menu-dropdown-width-per-column;

            .sub-menu-options__selection {
              max-width: $facet-menu-dropdown-width-per-column-color;
              width: $facet-menu-options-selectable-color-swatches-width;
            }

            .flex-centered {

              height: rem-calc(50);

              .sub-menu-options__label {
                flex-direction: column;
                padding-right: $facet-sub-menu-options-label-padding-right;

                .selected {

                  &.product-block-color-swatch {
                    box-shadow: 0 0 0 rem-calc(2) $facet-menu-active-facets-border-color !important;
                  }

                  &:before {
                    color: $facet-menu-color;
                    content: $facet-menu-active-facets-check-content;
                    left: $facet-menu-active-facets-check-left;
                    position: relative;
                    top: $facet-menu-active-facets-check-top;
                    z-index: z-index(badges);
                  }

                  &.inverse-selection-check-color:before {
                    color: $facet-menu-color-swatch-inverse-check-color;
                  }

                  .sub-menu-options__color-swatch {
                    bottom: rem-calc(16);
                    position: relative;
                  }
                }

                .sub-menu-options__label-text {
                  font-size: $facet-menu-color-swatch-label-font-size;
                  line-height: rem-calc(12);
                  text-align: $facet-menu-color-swatch-label-text-align;
                  text-transform: lowercase;

                  &:first-letter {
                    text-transform: uppercase;
                  }
                }
              }

               .sub-menu-options__checkbox,
               .sub-menu-options__label-text--num-products {
                opacity: 0;
                position: absolute;
              }
            }
          }

          &.item-color {
            min-width: $facet-menu-dropdown-color-min-width;
            padding-left: $facet-menu-dropdown-color-padding-left;
            width: $facet-menu-dropdown-color-width;
            .selectable-color-swatches {
              row-gap: $facet-selectable-color-swatches-row-gap;
              .sub-menu-options__label-text {
                margin-top: $facet-selectable-color-swatches-margin-top;
              }
            }
          }

          ul {
            border-bottom: $facet-menu-dropdown-border-bottom;
            display: flex;
            flex-flow: $facet-menu-dropdown-flex-flow;
            max-height: $facet-menu-dropdown-height;
            width: 100%;

            // IE10+ hack
            // sass-lint:disable-block no-vendor-prefixes
            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
              height: $facet-menu-dropdown-height;
            }
          }

          .sort-by-options {
            border-bottom: 0;

            .flex-centered {
              font-family: $facet-menu-sort-font-family;
              font-size: $facet-menu-font-size;

              .product_sort_radio_select {
                margin-top: $facet-menu-product-sort-radio-select-margin-top;
              }
            }
            height: auto;
          }

          .sub-menu-options__checkbox-container > .flex-centered {
            margin-left: $facet-menu-filter-flex-centered-margin-left;
            margin-top: $facet-menu-filter-flex-centered-margin-top;

            .sub-menu-options__checkbox {
              border-color: $facet-menu-filter-checkbox-color;

              &:focus {
                outline: $defaultFocusColor auto 1px;
              }

              &:checked {
                border-color: $facet-menu-checkbox-checked-border-color;

                &::before {
                  background: $facet-menu-checked-before-background;
                  outline: $facet-menu-checked-before-outline;
                }

                &::before {
                  font-size: $facet-menu-checked-after-font-size;
                }

                &::after {
                  color: $facet-menu-checkbox-after-color;
                }
              }

              &:disabled {
                border-color: $facet-menu-dropdown-disabled-color;
              }
            }

            .sub-menu-options__label {
              &-text {
                color: $facet-sub-menu-options-label-color;
                margin-right: $facet-sub-menu-options-label-margin-right;
              }
            }
          }

          .sub-menu-actions {
            align-items: center;
            display: flex;
            padding-bottom: $facet-menu-dropdown-padding-bottom;
            padding-top: $facet-menu-dropdown-padding-top;
            width: $facet-menu-dropdown-width-per-column;

            .clear-filters {
              background: $facet-menu-clear-filter-bg-color;
              color: $facet-menu-clear-filter-text-color;
              font-family: $facet-menu-sort-font-family;
              font-size: $facet-menu-clear-filter-font-size;
              font-weight: 500;
              letter-spacing: rem-calc(0.3);
              line-height: $facet-menu-clear-filter-font-size;
              margin: $facet-menu-clear-filter-margin;
              padding: $facet-menu-clear-filter-padding;
              text-decoration: underline;
              text-transform: $facet-menu-clear-filter-text-transform;
              width: auto;

              @include breakpoint(medium) {
                color: $facet-menu-clear-filter-text-color-medium;
                font-size: $facet-menu-clear-filter-font-size-medium;
                letter-spacing: $facet-menu-clear-filter-letter-spacing-medium;
                line-height: $facet-menu-clear-filter-font-size-medium;
                text-transform: $facet-menu-clear-filter-text-transform-medium;
              }

            }

            .sub-menu-actions__action {
              padding: 0;
              width: $facet-menu-actions-clear-all-width;

              &.sub-menu-actions__action--apply {
                max-width: $facet-menu-actions-apply-max-width;
                min-width: $facet-menu-actions-apply-min-width;
                width: $facet-menu-actions-apply-width;

                .button {
                  background: $facet-menu-actions-apply-background;
                  font-size: $facet-menu-actions-apply-font-size;
                  padding-left: rem-calc(5);
                  padding-right: rem-calc(5);

                  @include breakpoint(medium) {
                    font-size: $facet-menu-actions-apply-font-size-medium;
                    letter-spacing: $facet-menu-actions-apply-letter-spacing-medium;
                    line-height: $facet-menu-actions-apply-line-height-medium;
                    padding: $facet-menu-actions-apply-padding-medium;
                    width: $facet-menu-actions-apply-width-medium;
                  }

                }
              }
            }
          }

          &.selection .sub-menu-actions .sub-menu-actions__action.sub-menu-actions__action--apply .button {
            background: $facet-menu-actions-apply-button-background;
          }
        }
      }

      .view-layout-switch {
        position: absolute;
        right: 0;

        button {
          direction: flex;
          font-size: rem-calc(16);
          height: $facet-menu-filter-height;
          width: $facet-menu-filter-height;

          span {
            bottom: rem-calc(3);
            position: relative;
            right: rem-calc(3);
          }
        }
        margin-bottom: 0;
        margin-left: auto;
      }
    }

    .decoupled-selected-facets-container {
      align-items: $facet-menu-decoupled-selected-facets-container-align-items;
      display: flex;
      max-width: $facet-menu-decoupled-selected-facets-container-max-width;
      padding: rem-calc(10) 0;

      .active-filters-display {
        padding: 0;
      }

      .active-filters-display.active-filters-list-container {
        max-width: 65%;
        padding-top: 0;
        width: auto;

        .active-filters-list {
          align-content: space-between;
          display: flex;
          flex-wrap: wrap;
          min-height: auto;

          &.collapsed {
            height: rem-calc(30);
            min-height: rem-calc(30);
          }

          &.expanded {
            height: $facet-menu-dropdown-active-container-height;
            min-height: $facet-menu-dropdown-active-container-min-height;
          }

          button {
            border: $facet-menu-dropdown-active-border;
            border-radius: $facet-menu-dropdown-active-border-radius;
            color: $facet-menu-sort-font-color;
            display: flex;
            font-family: $facet-menu-sort-font-family;
            font-size: $facet-menu-sort-button-font-size;
            font-weight: $facet-menu-sort-button-font-weight;
            height: $active-facets-height;
            letter-spacing: $active-facets-letter-spacing;
            margin-bottom: $active-facets-margin-bottom;
            padding: $facet-menu-sort-button-padding;

            .active-filter-label {
              order: 2;
              text-transform: $facet-menu-active-filter-label-text-transform;

              &:first-letter {
                text-transform: uppercase;
              }
            }

            .active-filter-color {
              border-radius: rem-calc(20);
              display: $facet-active-filter-color-display;
              height: rem-calc(11);
              order: 1;
              outline: none;
              width: rem-calc(11);
            }

            .active-filter-remove {
              color: $facet-menu-dropdown-line-separator-color;
              font-size: $facet-menu-face-close-icon-font-size;
              order: 3;

              &:before {
                @include icomoonFont($icon-close);
              }
            }
          }
        }
      }

      .active-filters-display.clear-all-container {
        border-left: $facet-menu-clear-all-container-border-left;
        margin: $facet-menu-clear-all-container-margin;
        padding: $facet-menu-clear-all-container-padding;
        width: $facet-menu-clear-all-container-width;
        flex-grow: $facet-menu-clear-all-container-flex-grow;

        div {
          color: $facet-menu-clear-all-container-div-color;
          display:  $facet-menu-clear-all-container-div-dfisplay;
          font-family:  $facet-menu-clear-all-container-div-font-family;
          font-size:  $facet-menu-clear-all-container-div-font-size;
          justify-content:  $facet-menu-clear-all-container-div-jusitfy-content;
          line-height:  $facet-menu-clear-all-container-div-line-height;
          width: 100%;

          .active-filters__item-count {
            margin-right:  $facet-menu-active-filters-item-count-margin-right;
          }

          &.row {
            .columns {
              &:last-child {
                width: $facet-menu-active-filters-columns-width;
              }
            }
          }
      }

        .button-container {
          display: flex;
        }

        @include see-more-button;

        button {
          border-left: $facet-menu-clear-all-button-border-left;
          border-radius: $facet-menu-clear-all-button-border-radius;
          font-size: $facet-menu-clear-all-button-font-size;
          font-weight: $facet-menu-clear-all-button-font-weight;
          letter-spacing: $facet-menu-clear-all-button-letter-spacing;
          margin-left: $facet-menu-clear-all-button-margin-left;
          margin-right: $facet-menu-clear-all-button-margin-right;
          text-transform: $facet-menu-clear-all-button-text-transform;
        }
      }

      .product-info {
        margin-left: auto;
        padding: 0;

        div {
          display: inline-block;
          @include total-results-font-style;
        }

        .circular-loader {
          display: $facet-menu-circular-loader-display;
        }

        &.fetching-products {
          .active-filters {
            &__item-count,
            &__counter-label {
              display: $facet-menu-active-filters-fetching-display;
            }
          }

          .circular-loader {
            display: $facet-menu-circular-loader-active-display;
          }
        }
      }
    }

    .top-bar-facets.stuck-to-top.top-facet-sticky-compressed-menu {
      align-items: center;
      background-color: $off-canvas-filter-sort-bar-color;
      background-image: $off-canvas-facets-active-filters-sticky-bkgd;
      box-shadow: $facet-menu-sticky-box-shadow;
      border-top: $facet-menu-sticky-borders;
      border-bottom: $facet-menu-sticky-borders;
      display: flex;
      height: $off-canvas-facets-active-filters-sticky-height;
      margin: 0;
      max-width: 100%;
      padding: 0;
      transition: 0.3s top ease-in-out;
      z-index: z-index(topnav)-1;

      .facets-bar-container {
        @if($off-canvas-filter-use-grid) {
          @include grid-row;
        }
        padding: 0 $column-gutter/2;
        position: relative;
        width: 100%;

        .decoupled-facet-container {
          background: transparent;

          .filter-menu__title-text {
            display: flex;

            .filter-menu__title-number-selected {
              padding-left: rem-calc(5);
            }
          }
        }

        .facet-product-counter {
          display: $off-canvas-facets-sticky-product-count-display;
        }

        .decoupled-selected-facets-container {
          display: none;
        }
      }
    }

    .facets-container {
      .facet-product-counter {
        color: $facet-container-product-counter-color;
        display: flex;
        justify-content: flex-end;
        margin-top: $facet-container-product-counter-margin-top;
        padding-bottom: $facet-container-product-counter-padding-bottom;

        .active-filters {
          &__item-count,
          &__counter-label {
            @include total-results-font-style;
          }

          &__counter-label {
            padding-left: rem-calc(5);
          }
        }

        .circular-loader {
          display: $facet-menu-circular-loader-display;
        }

        &.fetching-products {
          .active-filters {
            &__item-count,
            &__counter-label {
              display: $facet-menu-active-filters-fetching-display;
            }
          }

          .circular-loader {
            display: $facet-menu-circular-loader-active-display;
          }
        }
      }
    }

    .filter-type-color {
      .sub-menu-options {
        flex-direction: $facet-menu-filter-type-color-flex-direction;

        &__selection {
          margin: $facet-menu-filter-type-color-selection-margin;
          width: $facet-menu-filter-type-color-selection-width;

          .flex-centered {
            height: $facet-menu-filter-type-color-flex-centered-height;
            margin-top: $facet-menu-filter-type-color-flex-centered-margin-top;

            .sub-menu-options__label {
              flex-direction: $facet-menu-filter-type-color-label-flex-direction;
              width: $facet-menu-filter-type-color-label-width;

              .sub-menu-options__color-swatch {
                border: $facet-menu-filter-type-color-swatch-border;
                border-radius: $facet-menu-filter-type-color-swatch-border-radius;
                margin-right: $facet-menu-filter-type-color-swatch-margin-right;
              }

              &-text {
                margin-right: $facet-menu-filter-type-color-text-margin-right;
                padding-top: $facet-menu-filter-type-color-text-padding-top;
              }

              &-text--num-products {
                display: $facet-menu-filter-type-color-text-num-products-display;
              }
            }

            .sub-menu-options__checkbox {
              display: $facet-menu-filter-type-color-checkbox-display;
            }
          }

         &.active {
          .sub-menu-options__color-swatch {
            outline-color: $facet-menu-filter-type-color-active-swatch-outline-color;
            outline-width: $facet-menu-filter-type-color-active-swatch-outline-width;
          }
        }
      }
    }
  }

    @if $no-color-swatches-enabled-desktop == true {
      .decoupled-facet-container {

        .sort,
        .decoupled-facet-menu {

          .filter-menu__sub-menu-options.active {
            .selectable-color-swatches {
              .sub-menu-options {
                &__selection {
                  margin: $no-color-swatches-selection-margin;
                  padding: $no-color-swatches-selection-padding;
                }

                &__label-text {
                  white-space: nowrap;
                }

                &__label {
                  margin: $no-color-swatches-label-margin;
                  padding: $no-color-swatches-label-padding;
                  width: auto;

                  .swatch {
                    display: none;
                  }
                }

                &__checkbox-container > .flex-centered {
                  height: auto;

                  .sub-menu-options__checkbox {
                    opacity: 1;
                  }

                }
              }
            }
          }
        }
      }
    }

  }
}

@mixin top-facets-star-rating-filter {
  .top-bar-facets {
    .sub-menu-options-stars-rating .rating-selection,
    .active-filters-list {
      .star-rating-filter-containers {
        padding: 0 rem-calc(5) 0 0;
      }

      .star-rating-filter-icon-container {
        display: inline-flex;
      }

      .star-rating-icon {
        color: $facet-menu-star-rating-star-color;
        font-size: rem-calc(16);
        margin: 0;

        &.active {
          color: $facet-menu-star-rating-active-star-color;
        }
      }
    }
    @if($facet-menu-star-rating-circular-checkbox) {
      .sub-menu-options-stars-rating .rating-selection {
        .sub-menu-options__checkbox {
          border-radius: 50%;

          &::before {
            background: transparent;
          }

          &:checked::after {
            background: $black;
            border-radius: 50%;
            content: '';
            height: rem-calc(7);
            left: 50%;
            text-align: center;
            top: 50%;
            transform: translate(-50%, -50%);
            width: rem-calc(7);
          }
        }
      }
    }
  }
}

@mixin off-canvas-active-filters {
  $off-canvas-facet-active-filter-padding: 0 rem-calc(5) 0 0 !default;
  $off-canvas-facets-active-filters-close-icon-font-size: rem-calc(6) !default;
  li {
    align-items: center;
    border: 1px solid $light-gray;
    border-radius: rem-calc(10);
    display: inline-flex;
    height: rem-calc(25);
    margin: 0 rem-calc(4);
    padding: 0 rem-calc(10);
  }

  .active-filter {
    &-label {
      @include font-styling($size: $active-filter-size, $weight: 400, $kerning: $off-canvas-facet-active-filter-letter-spacing, $leading: 12);

      font-family: $font-family-secondary;
      line-height: $off-canvas-facet-active-filter-letter-spacing-line-height;
      order: 1;
      padding: $off-canvas-facet-active-filter-padding;
      text-transform: none;
    }

    &-color {
      border-radius: 50%;
      display: $off-canvas-facet-active-filter-color-display;
      height: rem-calc(11);
      margin-right: rem-calc(5);
      order: 0;
      outline: none;
      width: rem-calc(11);
    }

    &-remove {
      color: $off-canvas-facets-active-filters-close-icon-color;
      font-size: $off-canvas-facets-active-filters-close-icon-font-size;
      order: 3;

      &:before {
        @include icomoonFont($active-filter-icon-close);
        color: $off-canvas-facets-active-filters-close-icon-color;
      }
    }
  }
}

@mixin see-more-button {
  button {
    align-items: center;
    background-color: $facet-menu-dropdown-active-bg-color;
    border-color: $facet-menu-dropdown-active-border-color;
    border-radius: $facet-menu-dropdown-active-border-radius;
    color: $facet-menu-dropdown-active-color;
    display: $facet-menu-dropdown-active-display;
    float: $facet-menu-dropdown-active-float;
    font-family: $facet-menu-sort-font-family;
    font-size: $facet-menu-active-facets-font-size;
    height: $active-facets-height;
    letter-spacing: rem-calc(1);
    line-height: rem-calc(13);
    margin: $facet-menu-dropdown-active-margin;
    padding: $facet-menu-dropdown-active-padding;
    text-transform: none;
    
    &.hide-more {
      display: $facet-menu-dropdown-active-display-hide;
    }

    .active-filter-remove {
      color: $facet-menu-dropdown-line-separator-color;
      font-size: $facet-menu-face-close-icon-font-size;
      padding: $facet-menu-clear-all-button-icon-padding;

      &:before {
        @include icomoonFont($icon-close);
        color: $facet-menu-clear-all-button-icon-color;
      }
    }
  }

  .active-filters-expand {
    background: $facet-menu-active-filters-expand-background;
    border: $facet-menu-active-filters-expand-border;
    border-radius: $facet-menu-active-filters-expand-border-radius;
    color: $facet-menu-active-filters-expand-color;
    font-family: $facet-menu-sort-font-family;
    font-weight: $facet-menu-active-filters-expand-font-weight;
    letter-spacing: $facet-menu-active-filters-expand-letter-spacing;
    line-height: $facet-menu-active-filters-expand-line-height;
    margin: $facet-menu-active-filters-expand-margin;
    outline: none;
    padding-left: $facet-menu-active-filters-expand-padding-left;
    padding-right: $facet-menu-active-filters-expand-padding-right;
    text-transform: $facet-menu-active-filters-expand-text-transform;

    .icon {
      color: $facet-menu-active-filters-expand-icon-color;
      float: left;
      font-size: rem-calc(9);
      font-weight: $facet-menu-active-filters-expand-icon-font-weight;
      line-height: $facet-menu-active-facets-font-size;
      padding-right: rem-calc(3);
    }

    .title {
      font-size: $facet-menu-active-filters-expand-title-font-size;
      font-weight: $facet-menu-active-filters-expand-title-font-weight;
      line-height: $facet-menu-active-filters-expand-title-line-height;
      white-space: $facet-menu-active-filters-expand-title-white-space;
    }
  }
}

