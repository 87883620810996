@import 'catalog/sort-by';

.sort-by-section {
	float: $global-right;
	width: grid-column(8);

    @include breakpoint(medium) {
		clear: left;
		float: $global-left;
		margin-top: 0;
		margin-bottom: 50px;
		width: grid-column(6);
    }

    @include breakpoint(large) {
		clear: none;
		float: $global-right;
		margin-bottom: 0;
		width: grid-column(4);
	}

	.prefix {
		@include grid-column(4, 0);
	}

	.sort-by {
		@include grid-column(8, 0);
	}

	.sort-by-container {
		select {
			&:focus {
				border: $input-border;
				border-left: 0;
			}
		}
	}
}
