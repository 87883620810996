@import 'global/z-indexes';

$productDrawerEnabled: false !default;
$productDetailsDrawerImageBorderColor: null !default;
$productDetailsDrawerImagePadding: 21px 25px 25px !default;
$productDetailsDrawerImageContentMarginTop: 15px !default;
$product-details-drawer-container-p-font-family: $secondaryFont !default;

@if ($productDrawerEnabled) {

.product-details-drawer {
	display: none;
}

.product-details-drawer-container {
	width: 100%;
	background-color: #e9e7dd;
	margin-top: 30px;

	p {
		font-family: $product-details-drawer-container-p-font-family;
		font-size: $smallParagraphFontSize;
		color: $primaryParagraphFontColor;
		line-height: 21px;
	}

	h4 {
		@include setFontStyle($drawerContainerFeatureTitlesFont);
		padding-top: 5px;
	}

	.product-details-drawer {
	//mobile first
		@include grid-row();

		.feature-section {
			@include grid-column(12);
			padding-left: convertPxToVw(40, 640);
			padding-right: convertPxToVw(40, 640);

			@include breakpoint(medium) {
				float: $global-left;
				margin-left: 0;
				padding-left: convertPxToVw(24, 768);
				padding-right: convertPxToVw(24, 768);
				width: grid-column(6);
			}

			.title {
						font-size: convert-to-em(14);
					}

					ul.feature li {
						margin-bottom: 15px;
						overflow: hidden;

						a {
							line-height: 2em;
						}
					}
		}

		.description-section {
		//mobile first
			float: $global-left;
			margin-left: 0;
			padding-left: convertPxToVw(40, 640);
			padding-right: convertPxToVw(40, 640);
			width: grid-column(12);

			@include breakpoint(medium) {
				padding-left: convertPxToVw(24, 768);
				padding-right: convertPxToVw(24, 768);
				width: grid-column(6);
			}
		}

		.title {
						font-size: convert-to-em(14);
					}

					ul.feature li {
						margin-bottom: 15px;
						overflow: hidden;

						a {
							line-height: 2em;
						}
					}

		article {
			@include grid-column(12, 0);
		}
	}

	.btn-section {
	//mobile first
		background-color: #e9e7dd;

		.product-details-drawer-btn-container {
			@include grid-row();
			padding: 10px convertPxToVw(15, 640);
			text-align: center;
			font-size: convert-to-em(12);
			letter-spacing: 0.15em;
			font-weight: 700;

			a {
				@include grid-column-collapse;
				border: 3px solid #fff;
				color: #2f2c2a;
				display: inline-block;
				float: none;
				font-size: convertPxToVw(19, 640);
				margin: auto;
				padding-bottom: 14px;
				padding-top: 14px;
				text-transform: uppercase;
				width: grid-column(12);
				white-space: nowrap;

				@include breakpoint(medium) {
					font-size: inherit;
				}

				@include breakpoint(large) {
					width: grid-column(5);
				}
			}

			@include breakpoint(medium) {
				padding-left: convertPxToVw(24, 768);
				padding-right: convertPxToVw(24, 768);
			}
		}

		&.expanded {
		//mobile first
			padding: 0px 0px 50px 0px;
			background-color: #e9e7dd;

			@include breakpoint(medium) {
				@include grid-column-gutter();
				float: none;
				width: grid-column(12);

				& a {
					@include grid-column-gutter();
					float: none;
					width: grid-column(6);
				}
			}

			@include breakpoint(large) {
				padding: 40px 0 50px 0;
			}
		}
	} //End of btn-section

	// variation: product description with image - START
	.product-details-drawer {
		&.product-details-drawer-image {
			border: 1px solid $productDetailsDrawerImageBorderColor;
			padding: $productDetailsDrawerImagePadding;

			.drawer-close-btn-container {
				float: right;
				width: auto;
			}

			.accordion-drawer-content {
				clear: both;
			}

			.description-section,
			.drawer-description-product-feature {
				margin-top: $productDetailsDrawerImageContentMarginTop;
			}

			.description-section {
				@include grid-column(12);
			}

			.drawer-description-product-feature {
				@include grid-column(12);

				float: right;
				text-align: center;

				.feature-section {
					width: 100%;
				}

				.drawer-description-content-image-container {
					.drawer-description-image {
						border: 1px solid $productDetailsDrawerImageBorderColor;
					}
				}
			}

			@include breakpoint(medium) {
				.description-section {
					@include grid-column(8);
				}

				.drawer-description-product-feature {
					text-align: right;
					@include grid-column(4);
				}
			}

			@include breakpoint(large) {
				.description-section {
					@include grid-column(9);
				}

				.drawer-description-product-feature {
					text-align: right;
					@include grid-column(3);
				}
			}
		}
	}
	// variation: product description with image - END
} //End of product-details-drawer-container

.drawer-close-btn-container {
	@include grid-column(12);
	margin-top: 30px;
	text-align: center;

	.description-pointer {
		width: 0;
		height: 0;
		border-left: 70px solid transparent;
		border-right: 70px solid transparent;
		border-top: 55px solid $backgroundColor;
		margin: auto;
	}

	.icon-close {
		cursor: pointer;
		position: absolute;
		top: 0;
		right: $pdpSpacingLarge;
	}
}

.drawer-description {
	padding-bottom: 40px;
	border-bottom: 2px solid $borderColor;
	margin-bottom: 40px;

	.title {
		@include setFontStyle($medium-headline);
		margin-bottom: 20px;
	}

	.drawer-description-content {
		p {
			@include setFontStyle($productDescriptionFontStyle);
		}

		ul {
				padding-left: 15px;
				list-style-type: disc;
				font-size: convert-to-em(14px);
		}
	}

}

.drawer-features {
	.title {
		@include setFontStyle($medium-headline);
		margin-bottom: 20px;
	}
}

ul.index {
//mobile first
	@include grid-column(12, 0);
	padding-bottom: 40px;
	border-bottom: $BGSectionSeperatorB;
	margin-bottom: 40px;

	li {
		margin-bottom: 30px;
	}

	li:not(.thumb) {
		list-style-image: url('data:image/gif;base64,R0lGODlhBAAKAIABAMrJwP///yH5BAEAAAEALAAAAAAEAAoAAAIIhI8Jwe0PWwEAOw==');
		margin-left: 20px;
	}

	li.thumb {
		position: relative;
		padding-left: 124px;

		h4 {
			color: $greenIndexFontColor;
		}

		img {
			left: 0;
			position: absolute;
			top: 0;
		}

		& img+span {
			width: 100px;
			position: absolute;
			left: 0;
			font-size: em-calc(34);
			line-height: 74px;
			color: #fff;
			text-align: center;

			&.secondary {
				font-size: em-calc(24);
			}
		}
	}

	@include breakpoint(large) {
		padding-bottom: inherit;
		border-bottom: inherit;
		margin-bottom: inherit;
	}
} //ul-index

ul.feature {
	@include grid-column(12, 0);

	li {
		margin-bottom: 30px;

		&:not(.thumb) {
			list-style-image: url('data:image/gif;base64,R0lGODlhBAAKAIABAMrJwP///yH5BAEAAAEALAAAAAAEAAoAAAIIhI8Jwe0PWwEAOw==');
			margin-left: 20px;
		}
	}

	li.thumb {
		position: relative;
		padding-left: 94px;
		min-height: 94px;

		img {
			left: 0;
			position: absolute;
			top: 0;
		}
	}

	div.more-features {
		display: none;
	}

	.title {
		@extend h3;
	}

	.show-more,
	.flex-video,
	.read-less {
		display: none;
	}
}

.show-all-features-hopup {
//mobile first
	padding: 30px $column-gutter / 2;

	@include breakpoint(medium) {
		padding: 30px;
	}

	@include grid-row(12);

    h2 {
      @include setFontStyle($showAllFeaturesHopupFontStyle);
      line-height: normal;
      font-size: em-calc(header-font-size(h1, medium));
	}

	.title {
		margin: 10px;
	}

	.title::after {
		content: ' :';
	}
}

.read-more-hopup {
	padding: 30px $column-gutter / 2;

	@include breakpoint(medium) {
		padding: 40px;
	}

	h4 {
		text-align: center;
		padding-bottom: 20px;
		border-bottom: 1px solid $fontInvertLight;
		margin-bottom: 20px;
	}

	li {
		border-bottom: 1px solid $fontInvertLight;
		padding-bottom: 20px;
	}

	.flex-video {
		clear: left;
	}
}

.green-index-hopup-content {
//mobile first
	padding: 30px $column-gutter / 2;
	@include grid-row();
	background-color: $backgroundColor;

	@include breakpoint(medium) {
		padding: 40px;
	}

	.index {
		margin-top: 20px;
	}

	h1 {
		@extend h4;
		text-align: center;
		padding-bottom: 20px;
		border-bottom: 1px solid $fontInvertLight;
		margin-bottom: 20px;
	}

	.green-index-short-description {
		text-align: center;
		font-size: convert-to-em(15);
	}

	h4 {
		margin-bottom: 20px;
	}

	li {
		&:first-child {
			img {
				margin-top: 10px !important;
			}

			span {
				line-height: 89px !important;
			}
		}

		padding-bottom: 20px;
		text-transform: uppercase;

		h2 {
			font-size: convert-to-em(20);
			padding: 11px 0;
			line-height: 1.5em;

			@include breakpoint(medium) {
				font-size: convert-to-em(34);
				padding: 11px 0;
				line-height: 1.5em;
			}

			@include breakpoint(large) {
				font-size: convert-to-em(50);
				line-height: 54px;
			}
		}

		h3 {
			font-size: convert-to-em(16);
			padding: 11px 0;

			@include breakpoint(medium) {
				font-size: convert-to-em(28);
				padding: 11px 0;
			}

			@include breakpoint(large) {
				font-size: convert-to-em(34);
				padding: 11px 0;
			}
		}

		h5 {
			font-size: convert-to-em(14);
			padding: 11px 0;
			color: $fontGreyDark;
			text-transform: none;

			@include breakpoint(medium) {
				font-size: convert-to-em(24);
				padding: 11px 0;
			}

			@include breakpoint(large) {
				font-size: convert-to-em(28);
			}
		}
	}

	&+.btn-container {
	//mobile first
		text-align: center;
		padding-left: $column-gutter / 2;
		padding-right: $column-gutter / 2;
		background: $moduleBackground;

		@include breakpoint(medium)	 {
			padding-top: 40px;
			padding-bottom: 30px;
		}
	}
} //End of green-index-hopup

} //End of if ($productDrawerEnabled)
