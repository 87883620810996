@import 'catalog/facet-breadcrumb';

.breadcrumb-facet-list {
  margin-left: -$column-gutter/4;
}

.breadcrumb-facet {
  line-height: 1;
  position: relative;

  .filter-remove {

    @include breakpoint(medium) {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }

	&:last-child {
		display: none;
	}
}

.facet-breadcrumb,
.facet-breadcrumb.not-reset-filters {
  margin: 0 $column-gutter/4 $column-gutter/2;
  padding: 10px 18px 10px 12px;

  @include breakpoint(medium) {
    margin: 0 $column-gutter/4 $column-gutter/4;
  }
}

.facet-breadcrumb {
  .reset-all-filters {
    padding: 16px 12px;
    text-decoration: none;
  }
}
