// Product List Page

#product-list-header-content {
  @include grid-row;
  padding-left: $column-gutter/2;
  padding-right: $column-gutter/2;
}

.product-block {
  &.circular-swatches {
    .color-swatches {
      margin: 0;
    }

    .product-block-info {
      .swatch .inner-border {
        width: 100%;
      }

      .swatch {
        @include napa-circular-color-swatch($colorSwatchSize, $colorSwatchSizeMedium);
      }
    }
  }
}

.no-header-hero {
  float: left;
  width: 75%;
}

.product-list {
  .espot-container {
    display: none;

    @include breakpoint(medium) {
      display: block;
      margin-bottom: $column-gutter;
    }
  }
}

.product-list-search-top {
  @include breakpoint(medium) {
    width: 75%;
    float: right;
  }
}

.search {
  .product-list-search-top {
    @include breakpoint(medium) {
      padding-top: $column-gutter/2;
      margin-bottom: $column-gutter;
      border-bottom: 1px solid $light-gray;
    }
  }

  .product-list-page-meta {
    .product-list-section-title {
      display: none;
    }
  }
}

#catalog.search #search-results-header-container .search-results-summary h1 {
  @include napa-search-results-summary-heading;
}

.search-results-summary {
  @include napa-search-results-summary-heading;
  // Hardcode this to a row width - cannot use grid row mixin since container MUST have different font-size due to WCS markup
  margin: 0 auto;
  max-width: ($global-width * $global-font-size/$headerHeroDescFontSize);

  .search-results-summary-header {
    @include napa-search-results-query;
    display: inline-block;
    font-size: inherit;
  }
}

.product-list-no-results-recommentation {
  .no-search-content-content {
    margin: $column-gutter 0 $column-gutter*2;

    p {
      margin-bottom: 0;
    }
  }

  .catalog-results {
    margin: $column-gutter 0;
  }

  .no-search-content-title {
    font-size: em-calc(header-font-size(h3, medium));
  }
}
