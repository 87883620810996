$page-margin-left-right: null !default;

%grid-block { //this is most likely being in an e-spot. too dangerous to be removed or made mobile-first
	height: $productBlockDesktopHeight;

	@media screen and (min-width: 1023px) and (max-width: 1080px) {
		height: 350px;
	}

	@media screen and (min-width: 1000px) and (max-width: 1024px) {
		height: 460px;
	}

	@media screen and (min-width: 900px) and (max-width: 999px) {
		height: 450px;
	}

	@media screen and (min-width: 880px) and (max-width: 899px) {
		height: 420px;
	}

	@media screen and (min-width: 840px) and (max-width: 879px) {
		height: 410px;
	}

	@media screen and (min-width: 750px) and (max-width: 839px) {
		height: 400px;
	}

	@media screen and (min-width: 641px) and (max-width: 749px) {
		height: 360px;
	}

	@media screen and (min-width: 620px) and (max-width: 640px) {
		height: 410px;
	}

	@media screen and (min-width: 520px) and (max-width: 619px) {
		height: 380px;
	}

	@media screen and (min-width: 460px) and (max-width: 519px) {
		height: 330px;
	}

	@media screen and (min-width: 360px) and (max-width: 459px) {
		height: 300px;
	}

	@media screen and (min-width: 320px) and (max-width: 359px) {
		height: 270px;
	}

	@media screen and (max-width: 319px) {
		height: 260px;
	}
}

%grid-block-height-reset {
	height: auto;
}

%exact-match-content {
	@include grid-column(6, 0);
    height: 450px;
    margin: 0;
    text-align: center;

    &::before {
    	background: transparent;
       	content: '';
        display: inline-block;
        height: 100%;
        margin-right: -0.5em;
        vertical-align: middle;
        width: 1px;
    }
}

%page-container-layout {
	@include grid-row;

	@include breakpoint(large) {
		@media only screen and (max-width: 1440px) {
			margin-left: $page-margin-left-right;
			margin-right: $page-margin-left-right;
		}
	}
  	h1 {
		margin-bottom: 0;
		margin-top: 0;
  		text-align: center;

  		@include breakpoint(medium) {
  			text-align: left;
  		}
  	}
}
