@import 'product/product-info';

$napa-pdp-element-spacing: 10px !default;

.pdp-swatches,
.pdp-drawer {
  #product-info {
    border-bottom: 1px solid $light-gray;
    margin: 0 0 rem-calc(10) 0;
    padding: 0 0 rem-calc(24) 0;

    @include breakpoint(medium) {
      margin: 0 0 rem-calc(16) 0;
      padding: 0 0 rem-calc(25) 0;
    }

    //product name
    h1 {
      font-size: rem-calc(18);
      line-height: rem-calc(23);
      margin-bottom: 0;
      padding-top: rem-calc(20);
      text-transform: none;

      @include breakpoint(medium) {
        font-size: rem-calc(24);
        line-height: rem-calc(31);
        margin-bottom: 0;
        padding-top: 0;
      }
    }

    .short-description {
      font-size: rem-calc(12);
      line-height: rem-calc(20);
      margin-bottom: 0;

      @include breakpoint(medium) {
        font-size: rem-calc(13);
      }
    }

    //product identifier
    .promo-eyebrow {
      font-size: em-calc(16px);
      margin-bottom: em-calc(5px);
      text-transform: none;
    }

    .product-price {
      float: none;
      font-size: rem-calc(14);
      line-height: rem-calc(17);
      padding: rem-calc(6) 0 0 0;
      width: auto;

      span.original-price {
        color: $black;
        display: inline-block;
        font-weight: bold;
        text-decoration: line-through;
      }

      span.offer-price {
        color: $black;
        display: inline-block;
        font-weight: bold;
      }

      span.current-price,
      span.discount-price {
        color: get-color(burnt-orange);
        display: inline-block;
      }

      @include breakpoint(medium) {
        font-size: rem-calc(18);
        line-height: rem-calc(22);
      }

      @include breakpoint(medium) {
        min-height: auto;
      }
    }

    //style number
    .product-style {
      @include breakpoint(medium) {
        bottom: -17px;
        clear: both;
        font-family: get-font('AkkuratPro');
        float: left;
        font-size: rem-calc(13);
        font-weight: 400;
        line-height: rem-calc(16);
        padding-bottom: 5px;
        position: absolute;
        right: 10px;
        text-align: right;
        width: auto;

        .product-style-js {
          font-weight: 700;
        }
      }
    }

    //product reviews
    .product-reviews-container {
      margin-top: $column-gutter;
    }
  } // end #product-info
} // end pdp
