@import 'catalog/filters';

@if ($filterBarAccordionEnabled) {
  .nav-dept {
    .filter-group {

      @include breakpoint(medium) {
        &:first-child {
          border-top: $filterGroupBorderBottom;
        }
      }

      &.selected {
        border-bottom: $filterGroupBorderBottom;
        border-top: 0;
        padding-bottom: 20px;

        .filter-options {
          margin: 0 0 20px;
        }
      }

      .filter-title {
        &::after {
          color: $black;
        }
      }

      .filter-options {

        .reset-all-filters {
          float: right;
          font-size: 12px;
          line-height: 24px;
          text-transform: lowercase;
        }

        .facet-option {

          &.selected {
            font-weight: bold;
            height: 30px;
            line-height: 26px;
          }
        }

        .facet-value {
          display: block;
          margin-bottom: 8px;
        }

        .plp-checkbox {
          border: 1px solid $light-gray;
          display: inline-block;
          height: 16px;
          line-height: 16px;
          margin-right: 10px;
          position: relative;
          text-align: center;
          text-indent: 1px;
          top: -1px;
          vertical-align: text-top;
          width: 16px;

          &.checked {

            &::before {
              @include icomoonFont($icon-check);
              color: $medium-gray;
            }
          }
        }

        .facet-color {
          //Dynamic background color is set in JSP
          background-size: cover;
          float: right;
          height: 8px;
          position: relative;
          right: 8px;
          top: 6px;
          width: 14px;
        }
      }

      .filter-actions {
        text-transform: lowercase;
      }

      &.reset-all:first-child {
        .facet-value {
          background: none;
          border: 0;
          cursor: default;
          height: auto;
          line-height: 1;
          margin: 0;
          padding: 0;

          // "filtered by" heading
          & > span {
            color: $light-gray;
            font-size: rem-calc(16);
            font-weight: bold;
            line-height: 24px;
            text-transform: lowercase;

            @include breakpoint(medium) {
              color: $black;
              font-size: 1rem;
              font-weight: normal;
              line-height: 1;
            }
          }
        }

        .reset-all-filters {
          .filter-remove {
            display: none;
          }
        }
      }
    }
  }
}

.product-list-filters-action {
  background-color: $white;
  margin-top: -1px;

  a {

    &.button.secondary {
      color: $white;
      font-weight: normal;
      padding: 12px 0;
      text-decoration: none;
      text-transform: lowercase;
      width: 50%;
    }

    &:first-of-type,
    &:first-of-type:hover {
      background-color: $black;
      color: get-color(burnt-orange);
    }

    &:last-of-type,
    &:last-of-type:hover {
      background-color: get-color(eclipse);
      color: get-color(burnt-orange);
    }
  }
}

.product-list-custom-form-input-graphic {
  @include grid-row();
  @include grid-column-gutter();

  font-size: $filterTitleFontSize;
  margin-top: 20px;
  margin-bottom: 20px;
}
