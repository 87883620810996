$sortBySectionTopPosition: 21px !default;
$sortBySectionDesktopTopMargin: 0 !default;
$sortBySectionWidth: 3 !default;
$sortBySectionWidthMedium: 3 !default;

.sort-by-section {
	float: $global-right;
	width: grid-column(8.5);

	@include breakpoint(medium) {
		@include grid-column-gutter();
		margin-top: $sortBySectionDesktopTopMargin;
		width: grid-column($sortBySectionWidth);
	}

	@include breakpoint(large) {
		width: 25%;
	}

	.prefix {
		@include grid-column(3, 0);
		@include setFontStyle($filterRefineSearchFontStyle);
		background: $select-background;
		border: $input-border;
		border-right: 0;
		font-weight: normal;
		height: get-form-input-height();
		line-height: $input-line-height;
		padding: $input-padding;
		text-align: right;
		white-space: nowrap;
	}

	.sort-by-container {
		select {
			@include setFontStyle($filterRefineSearchFontStyle);
			border-left: 0;
			margin-bottom: 0;

			option {
				color: $defaultText;
			}
		}
	}

	&.with-header {
		margin-top: 10px;
	}
}

form.sort-by {
	@include grid-column(9, 0);
	margin-bottom: 0;

	@include breakpoint(medium) {
		@include grid-column(9, 0);
	}
}
