$pdpInfoWidthTablet: 5 !default;
$productRatingSizeOnPdp: 1.6em !default; // The size of ratings stars on PDP
$productRatingSizeOnPdpLarge: null !default;
$productRatingLineHeightOnPdp: 1.6em !default; // The line-height of ratings stars on PDP
$pdpInfoSeeProductDetailsContainerMargin: null !default;
$socialMediaWebShareMobileBottom: null !default;
$socialMediaWebShareMobileFontSize: null !default;
$socialMediaWebShareMobilePosition: null !default;
$socialMediaWebShareMobileRight: null !default;
$socialMediaWebShareMobileRightMedium: null !default;
$socialMediaWebShareMobileTextDecoration: null !default;
$originalPriceFloat: null !default;
$originalPriceMarginRight: null !default;
$originalPriceTextDecoration: null !default;
$socialMediaWebShareDisplay: null !default;
$socialMediaWebShareDisplayMedium: $socialMediaWebShareDisplay !default;
$socialMediaWebShareBottom: null !default;
$socialMediaWebShareTop: null !default;
$productInfoPaddingMedium: null !default;
$socialMediaWebShareMaxHeight: null !default;
$socialMediaWebShareHeight: null !default;
$socialMediaWebShareWidth: null !default;
$socialMediaWebShareIconRight: null !default;
$socialMediaWebShareIconTop: null !default;
$socialMediaWebShareIconPosition: null !default;
$productInfoNameLineHeight: null !default;
$productInfoNameLineHeightMedium: $productInfoNameLineHeight !default;
$productInfoNameTextTransform: null !default;
$productSecondaryTitleFontFamily: null !default;
$pdpPricePaddingBottom: null !default;
$product-info-price-letter-spacing: null !default;
$product-price-30days-lowest-price-show-display: flex !default;
$product-price-30days-lowest-price-show-flex-direction: row !default;
$product-price-30days-lowest-price-show-flex-wrap: wrap !default;
$history-lowest-price-show-percentage-price-color: get-color(primary) !important !default;
$lowest-history-price-tooltip-color: null !default;
$history-lowest-price-show-font-family: null !default;
$history-lowest-price-show-price-width: 100% !default;
$history-lowest-price-show-price-margin-right: null !default;
$price-total-font-family: null !default;
$price-total-min-width-small: rem-calc(50) !default;
$price-total-min-width-medium: rem-calc(75) !default;
$price-total-margin-right-small: null !default;
$price-total-margin-right-medium: null !default;
$price-total-font-weight-small: null !default;
$price-total-font-weight-medium: null !default;
$history-lowest-price-show-font-size-small: rem-calc(13) !important !default;
$history-current-price-font-size-small: rem-calc(15) !important !default;
$history-current-price-font-size-medium: rem-calc(18) !important !default;
$history-current-price-font-weight: 700 !default;
$history-price-color: null !default;
$price-total-percentage-font-weight-small: null !default;
$price-total-percentage-font-weight-medium: null !default;
$lowest-discount-price-margin-small: null !default;
$lowest-discount-price-margin-medium: null !default;
$history-lowest-price-show-font-weight: 400 !important !default;
$price-total-percentage-line-height: null !default;
$history-current-price-line-height-small: rem-calc(24) !default;
$history-current-price-line-height-medium: $history-current-price-line-height-small !default;
$history-lowest-price-show-font-size-medium: rem-calc(16) !important !default;
$history-lowest-price-show-title-min-width-small: rem-calc(62) !default;
$history-lowest-price-show-title-min-width-medium: rem-calc(75) !default;
$history-lowest-price-show-title-margin-right-small: null !default;
$history-lowest-price-show-title-margin-right-medium: null !default;
$lowest-price-social-media-web-share-mobile-display-small: null !default;
$lowest-price-social-media-web-share-mobile-display-medium: null !default;
$lowest-price-product-info-price-and-review-container-flex-direction: null !default;
$history-lowest-price-show-display: null !default;
$price-total-color: null !default;
$history-lowest-price-product-content-info-name-margin-bottom: null !default;
$history-lowest-price-section-reviews-margin-bottom-small: null !default;
$history-lowest-price-pdp-klarna-padding-small: null !default;
$history-lowest-price-pdp-klarna-padding-medium: null !default;
$history-lowest-price-pdp-klarna-margin-small: 0 !important !default;
$history-lowest-price-pdp-klarna-margin-medium: 0 !important !default;
$product-price-30days-lowest-price-show-margin: null !default;
$history-lowest-price-klarna-section-container-padding-small: null !default;
$history-lowest-price-klarna-section-container-padding-medium: null !default;
$history-lowest-price-product-form-margin: null !default;
$history-lowest-price-product-info-padding: null !default;
$history-lowest-price-klarna-upstream-interstitial-margin-top-small: null !default;
$history-lowest-price-klarna-upstream-interstitial-margin-bottom-small: null !default;
$history-lowest-price-klarna-upstream-interstitial-margin-bottom-medium: null !default;
$history-lowest-price-klarna-upstream-interstitial-margin-top-medium: null !default;
$history-lowest-price-discount-container-display: null !default;
$history-lowest-price-price-block-margin-small: null !default;
$history-lowest-price-price-block-margin-medium: null !default;
$history-lowest-price-product-content-info-price-padding: null !default;
$history-lowest-price-product-info-padding-bottom: null !default;
$history-lowest-price-reviews-font-size-small: null !default;
$history-lowest-price-reviews-font-size-medium: null !default;
$product-price-span-display: inline-block !default;
$lowest-price-product-vieweer-height: null !default;
$lowest-price-product-viewer-margin: null !default;
$history-current-price-color: null !default;
$price-total-line-height-small: null !default;
$price-total-line-height-medium: $price-total-line-height-small !default;
$history-lowest-price-product-content-info-rating-margin: null !default;
$history-lowest-price-product-content-info-rating-padding: null !default;
$history-lowest-price-star-font-size: null !default;
$history-lowest-price-reviews-line-height: null !default;
$history-lowest-price-show-title-font-weight: null !default;
$history-icon-star-font-size: null !default;
$history-icon-star-width-small: null !default;
$history-icon-star-width-medium: null !default;
$history-lowest-price-section-reviews-padding: null !default;
$history-lowest-star-rating-component-margin-bottom-small: null !default;
$history-lowest-star-rating-component-margin-bottom-medium: null !default;
$history-lowest-price-show-price-margin-top: null !default;
$history-price-enabled-discount-price-line-height: null !default;
$history-price-enabled-discount-price-display: null !default;
$history-price-enabled-product-block-range-price-width: null !default;
$original-price-sticky-display: none !default;
$original-price-sticky-margin-right: null !default;
$product-price-range-price-display: null !default;
$product-price-range-price-flex-wrap: null !default;
$original-price-order: null !default;
$current-price-order: null !default;
$discount-price-order: null !default;
$discount-price-width: null !default;
$current-price-margin-right: null !default;
$original-price-content-sticky-display: null !default;
$lowest-discount-price-margin-right: rem-calc(10) !default;
$current-price-lowest-price-enabled-margin-left: null !default;

.pdp {
    //product information section
    #product-info-container {
        .social-media-web-share-mobile {
            bottom: $socialMediaWebShareMobileBottom;
            display: $socialMediaWebShareDisplay;
            font-size: $socialMediaWebShareMobileFontSize;
            height: $socialMediaWebShareHeight;
            max-height: $socialMediaWebShareMaxHeight;
            position: $socialMediaWebShareMobilePosition;
            right: $socialMediaWebShareMobileRight;
            text-decoration: $socialMediaWebShareMobileTextDecoration;
            width: $socialMediaWebShareWidth;

            span {
                position: $socialMediaWebShareIconPosition;
                right: $socialMediaWebShareIconRight;
                top: $socialMediaWebShareIconTop;
            }

            @include breakpoint(medium) {
                bottom: $socialMediaWebShareBottom;
                display: $socialMediaWebShareDisplayMedium;
                right: $socialMediaWebShareMobileRightMedium;
                top: $socialMediaWebShareTop;
            }
        }
    }

    #product-info {
        margin-bottom: $pdpSpacingLarge;

        .product-price {
            color: $priceFontColor;
            &.range-price {
                display: $product-price-range-price-display;
                flex-wrap: $product-price-range-price-flex-wrap;
                .original-price {
                    order: $original-price-order;
                }
                .current-price {
                    margin-right: $current-price-margin-right;
                    order: $current-price-order;
                }
                .discount-price {
                    order: $discount-price-order;
                    width: $discount-price-width;
                }
            }
            .current-price,
            .offer-price,
            .original-price,
            .discount-price,
            .old-price {
                white-space: nowrap;
            }

            .current-price {
                color: $currentPriceFontColor;
            }

            .offer-price {
                color: $offerPriceColor;
                display: inline-block;
            }

            .original-price {
                color: $originalPriceColor;
                display: inline-block;
                float: $originalPriceFloat;
			    margin-right: $originalPriceMarginRight;
		    	text-decoration: $originalPriceTextDecoration;
            }

            .no-discount {
                color: $originalPriceColor;
            }

            .old-price {
                text-decoration: line-through;
            }

            padding-bottom: rem-calc($pdpPricePaddingBottom);
        }

        h1 {
            @include setFontStyle($productTitleH1Style);
            line-height: $productInfoNameLineHeight;
            text-transform: $productInfoNameTextTransform;
            @include breakpoint(medium) {
                @include setFontStyle($productTitleH1StyleMedium);
                line-height: $productInfoNameLineHeightMedium;
                padding: $productInfoPaddingMedium;
            }
            letter-spacing: $product-info-price-letter-spacing;
        }

        .product-content-info-secondary-title-wrapper {
            font-family: $productSecondaryTitleFontFamily;
            font-size: rem-calc(18);
            font-weight: 400;
            line-height: rem-calc(24);
            margin-top: rem-calc(-15);
            padding-bottom: rem-calc(18);
            width: 100%;

            @include breakpoint(medium) {
                font-size: rem-calc(26);
                line-height: rem-calc(30);
                margin-top: rem-calc(-18);
                padding-bottom: rem-calc(32);
            }
        }

        .product-style-container {
            padding-bottom: 0;
            float: left;
            @include breakpoint(medium) {
                @include grid-column(4, 0);
                padding-bottom: $pdpSpacingLarge;
            }
        }

        .product-reviews-container {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            padding-bottom: $pdpSpacingLarge;
        }

        .product-content-info-rating {
            font-size: $productRatingSizeOnPdp;
            margin-right: 0.5rem;
            margin: $history-lowest-price-product-content-info-rating-margin;
            padding: $history-lowest-price-product-content-info-rating-padding;
            .icon-star {
                font-size: $history-lowest-price-star-font-size;
            }
            @include breakpoint(large) {
                font-size: $productRatingSizeOnPdpLarge;
            }
        }

        .product-content-info-review-count-link,
        .product-content-info-write-review-link {
            line-height: $productRatingLineHeightOnPdp;
            white-space: nowrap;
        }

        .product-content-info-see-product-details-container {
            margin: $pdpInfoSeeProductDetailsContainerMargin;
        }
    }
    // end #product-info
}
.lowest-price-enabled {
    .sticky-add-to-cart {
        .product-price {
            display: flex;
            .lowest-discount-price {
                margin-right: $lowest-discount-price-margin-right;
                order: 1;
                text-decoration: line-through;
                .percentage, .title {
                    display: none;
                }  
            }
            .discount-price {
                display: none;
            }   
            .current-price {
                margin-left: $current-price-lowest-price-enabled-margin-left;
                order: 2;
            }
            .original-price {
                display: $original-price-sticky-display;
                margin-right: $original-price-sticky-margin-right;
                > * {
                    display: $original-price-content-sticky-display;
                }
            }
        }
    }
    .product-block-range-price {
        &.range {
            &:not(.current-price) {
                width: $history-price-enabled-product-block-range-price-width;
            }
        }
    }
    #product-detail {
        .vfdp-s7-viewer .s7container[mode='normal'] .viewer-wrapper {
            height: $lowest-price-product-vieweer-height;
            margin-top: $lowest-price-product-viewer-margin;
        }
        .social-media-web-share-mobile {
            display: $lowest-price-social-media-web-share-mobile-display-small;
            @include breakpoint(medium) {
                display: $lowest-price-social-media-web-share-mobile-display-medium;
            }
        }
        .product-info-price-and-review-container {
            flex-direction: $lowest-price-product-info-price-and-review-container-flex-direction;
        }
        .section-reviews {
            margin-bottom: $history-lowest-price-section-reviews-margin-bottom-small;
            padding: $history-lowest-price-section-reviews-padding;
            .star-rating-component {
                margin-bottom: $history-lowest-star-rating-component-margin-bottom-small;
                @include breakpoint(medium) {
                    margin-bottom: $history-lowest-star-rating-component-margin-bottom-medium;
                }
            }
            .product-content-info-write-review-link, .product-content-info-review-count-link {
                font-size: $history-lowest-price-reviews-font-size-small;
                line-height: $history-lowest-price-reviews-line-height;
                @include breakpoint(medium) {
                    font-size: $history-lowest-price-reviews-font-size-medium;
                }
            }
        }
        .discount-container {
            display: $history-lowest-price-discount-container-display;
        }
        .product-content-info-name {
            font-size: rem-calc(21);
            line-height: rem-calc(24);
            margin-bottom: $history-lowest-price-product-content-info-name-margin-bottom;
        }
        .product-info {
            padding: $history-lowest-price-product-info-padding-bottom;
            h1 {
                padding: $history-lowest-price-product-info-padding;
            }
        }
        .price-block {
            margin: $history-lowest-price-price-block-margin-small;
            @include breakpoint(medium) {
                margin: $history-lowest-price-price-block-margin-medium;
            }
        }
        .discount-price {
            display: $history-price-enabled-discount-price-display;
            line-height: $history-price-enabled-discount-price-line-height;
        }
        .current-price {
            color: $history-current-price-color;
            font-size: $history-current-price-font-size-small;
            font-weight: $history-current-price-font-weight;
            line-height: $history-current-price-line-height-small;
            order: 1;
            width: fit-content;
            @include breakpoint(medium) {
                font-size: $history-current-price-font-size-medium;
                line-height: $history-current-price-line-height-medium;
            }
            &.only-offer-visible {
                color: $offerPriceColor !important;
            }
        }
        .original-price {
            line-height: $history-current-price-line-height-small;
            @include breakpoint(medium) {
                line-height: $history-current-price-line-height-medium;
            }
        }
        .star-rating-icon-container {
            width: $history-icon-star-width-small;
            @include breakpoint(medium) {
                width: $history-icon-star-width-medium;
            }
            .icon-star {
                @include breakpoint(medium) {
                    font-size: $history-icon-star-font-size;
                }
            }
        }
        .product-price {
            width: 100% !important;
        }
        .history-lowest-price-show {
            .product-content-info-price {
                padding: $history-lowest-price-product-content-info-price-padding;
            }
            .product-price {
                display: $product-price-30days-lowest-price-show-display;
                flex-direction: $product-price-30days-lowest-price-show-flex-direction;
                flex-wrap: $product-price-30days-lowest-price-show-flex-wrap;
                margin: $product-price-30days-lowest-price-show-margin;
                .lowest-discount-price, .original-price {
                    color: $history-price-color;
                    display: $history-lowest-price-show-display;
                    font-size: $history-lowest-price-show-font-size-small;
                    letter-spacing: unset;
                    line-height: $history-current-price-line-height-small;
                    margin-top: $history-lowest-price-show-price-margin-top;
                    margin-right: $history-lowest-price-show-price-margin-right;
                    text-decoration: unset;
                    width: $history-lowest-price-show-price-width;
                    @include breakpoint(medium) {
                        font-size: rem-calc(16) !important;
                        line-height: $history-current-price-line-height-medium;
                    }
                    span {
                        display: $product-price-span-display;
                        color: $history-price-color;
                        font-family: $history-lowest-price-show-font-family;
                        font-size: $history-lowest-price-show-font-size-small;
                        font-weight: $history-lowest-price-show-font-weight;
                        line-height: $history-current-price-line-height-small;
                        &.title {
                            font-weight: $history-lowest-price-show-title-font-weight;
                            margin-right: $history-lowest-price-show-title-margin-right-small;
                            min-width: $history-lowest-price-show-title-min-width-small;
                            @include breakpoint(medium) {
                                min-width: $history-lowest-price-show-title-min-width-medium;
                                margin-right: $history-lowest-price-show-title-margin-right-medium;                     
                            }
                        }
                        @include breakpoint(medium) {
                            font-size: $history-lowest-price-show-font-size-medium;
                            line-height: $history-current-price-line-height-medium;
                        }
                        &.price-total {
                            color: $price-total-color;
                            font-family: $price-total-font-family;
                            font-weight: $price-total-font-weight-small;
                            line-height: $price-total-line-height-small;
                            margin-right: $price-total-margin-right-small;
                            min-width: $price-total-min-width-small;
                            @include breakpoint(medium) {
                                font-weight: $price-total-font-weight-medium;
                                line-height: $price-total-line-height-medium;
                                margin-right: $price-total-margin-right-medium;
                                min-width: $price-total-min-width-medium;
                            }
                        }
                    }
                    .price-total {
                        text-decoration: line-through;
                    }
                    .percentage {
                        color: $history-lowest-price-show-percentage-price-color;
                        font-family: $price-total-font-family;
                        font-weight: $price-total-percentage-font-weight-small;
                        line-height: $price-total-percentage-line-height;
                        @include breakpoint(medium) {
                            font-weight: $price-total-percentage-font-weight-medium;       
                        }
                    }
                }
                .lowest-history-price-tooltip {
                    color: $lowest-history-price-tooltip-color;
                    cursor: pointer;
                    font-size: rem-calc(14);
                    order: 1;
                    place-self: center;
                    text-align-last: center;
                    width: 7%;
                }
                .lowest-discount-price {
                    margin: $lowest-discount-price-margin-small;
                    order: 2;
                    @include breakpoint(medium) {
                        margin: $lowest-discount-price-margin-medium;
                    }
                    .title {
                        min-width: rem-calc(74);
                    }
                }
                .original-price {
                    order: 3;
                    text-decoration: none !important;
                    .title {
                        min-width: rem-calc(67);
                    }
                }
                .klarna-upstream-interstitial {
                    margin-top: 0;
                    order: 4;
                }
                .discount-price {
                    display: none !important;
                }
            }
            .pdp-klarna {
                float: left;
            }
        }
        .klarna-section-container {
            margin: $history-lowest-price-pdp-klarna-margin-small;
            padding: $history-lowest-price-klarna-section-container-padding-small;
            @include breakpoint(medium) {
                margin: $history-lowest-price-pdp-klarna-margin-small;
                padding: $history-lowest-price-klarna-section-container-padding-medium;
            }
        }
        .pdp-klarna {
            margin: $history-lowest-price-pdp-klarna-margin-small;
            padding: $history-lowest-price-pdp-klarna-padding-small;
            text-align: left;
            @include breakpoint(medium) {
                margin: $history-lowest-price-pdp-klarna-margin-medium;
                padding: $history-lowest-price-pdp-klarna-padding-medium;
            }
            .pdp-klarna-message {
                margin: 0 !important;
            }
        }
        .klarna-upstream-interstitial {
            p {
                margin-bottom: $history-lowest-price-klarna-upstream-interstitial-margin-bottom-small;
                margin-top: $history-lowest-price-klarna-upstream-interstitial-margin-top-small;
                @include breakpoint(medium) {
                    margin-bottom: $history-lowest-price-klarna-upstream-interstitial-margin-bottom-medium;
                    margin-top: $history-lowest-price-klarna-upstream-interstitial-margin-top-medium;
                }
            }
        }
        #product-form {
            margin: $history-lowest-price-product-form-margin;
        }
    }
}
