@import 'catalog/quickview';

//mobile-first
#quickview-box {
  &.pdp-drawer {
    .more-details {
      margin-top: em-calc(20);
      text-align: center;
    }

    #product-content-container {
      margin: 0;
    }

    #product-info {
      h1 {
        font-size: em-calc(18);
        margin-bottom: em-calc(20px);

        @include breakpoint(medium) {
          font-size: em-calc(22);
        }
      }

      .product-price {
        font-size: em-calc(16);
      }

      .offer-price {
        font-size: em-calc(16);
        font-weight: bold;
        width: 100%;
      }

      .price-block {
        float: left;
        width: 50%;
      }

      .product-style-container {
        float: right;
        text-align: right;
        width: 50%;
      }

      .product-style {
        float: none;
        padding-top: 4px;

        span {
          display: inline-block;
        }
      }
    }

    #product-form {
      .step-content {
        + span {
          font-size: em-calc(12);
        }
      }

      .size-chart-popup {
        float: none;
        margin-top: em-calc(5);
      }

      .product-actions {
        .button {
          margin: {
            bottom: 0;
            top: 0;
          }
          width: 100%;
        }
      }

      .attr-size {
        .attr-box {
          float: none;
        }
      }
    }
  }
}
