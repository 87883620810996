// ****************************************************************
// TNF FONT WEIGHT DESIGN                            FONT WEIGHT CSS
// ****************************************************************
// Helvetica Neue Helvetica 47 Light Condensed .......... 200
// Helvetica Neue Helvetica 57 Regular Condensed ........ 400
// Helvetica Neue Helvetica 67 Medium Condensed ......... 500
// Helvetica Neue Helvetica 77 Bold Condensed ........... 700
//
// Helvetica Neue Light ................................. 200
// Helvetica Neue Regular ............................... 400
// Helvetica Neue Medium ................................ 500
// Helvetica Neue Bold .................................. 700
//
// *****************************************************************


// ****************************************************************
// TBL FONT WEIGHT DESIGN                            FONT WEIGHT CSS
// ****************************************************************
// Semplicitia W-01 Bold ................................ 700
// ARIAL BOLD ........................................... 700
// ARIAL Regular ........................................ 400
//
// *****************************************************************

$font-family-primary: null !default;  //these are defined in brand/sass/global/themes/cabana/variables
$font-family-secondary: null !default; //these are defined in brand/sass/global/themes/cabana/variables

// ***************************************************************************************
// USAGE: @include font-styling($size: 48, $weight: inherit, $kerning: 3.9, $leading: 25);
// ***************************************************************************************

@mixin font-styling ($size: inherit, $weight: inherit, $kerning: inherit, $leading: inherit) {
  $font-size: null;
  $letter-spacing: null;
  $line-height: null;
  @if ($size) {
    $font-size: rem-calc($size);
  } @else {
    $font-size: inherit;
  }
  @if ($kerning) {
    $letter-spacing: rem-calc($kerning);
  } @else {
    $letter-spacing: inherit;
  }
  @if ($leading) {
    $line-height: rem-calc($leading);
  } @else {
    $line-height: inherit;
  }

  font-size: $font-size;
  font-weight: $weight;
  letter-spacing: $letter-spacing;
  line-height: $line-height;
}
