$productImgH: 74px !default;
$pdpViewerWidth: 7 !default;
$pdpAltViewSpacing: 37px !default;
$pdpAltViewSpacingOnTablet: $pdpAltViewSpacing !default;
$borderHoverColor: $borderFocusColor !default;
$altImgSizeReducedBy: 18 !default;
$altImgSizeForDesktop: $productImgH !default;
$altImgSizeForTablet:  $altImgSizeForDesktop - $altImgSizeReducedBy !default;
$altImgLineHeightForDesktop: $productImgH !default;
$altImgLineHeightForTablet: $altImgSizeForDesktop - $altImgSizeReducedBy !default;
$altImgOverflow: hidden !default;


//mobile first
#product-views-container {
	.reset-label {
		display: none;
	}

	.imageInfo {
		background: rgba(0, 0, 0, 0.7);
		bottom: 0;
		color: #FFFFFF;
		cursor: default;
		font-weight: normal;
		left: 0;
		line-height: convert-to-em(21);
		overflow: hidden;
		padding: 20px;
		position: absolute;
		text-align: center;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 100%;

		.uploader {
			font-size: convert-to-em(16);
			height: convert-to-em(20);
			letter-spacing: convert-to-em(4);
		}

		.caption {
			font-size: convert-to-em(13);
			max-height: 45px;
			overflow: hidden;
			white-space: pre-line;
		}

		.see-all a {
			font-size: convert-to-em(12);
		}
	}
}

// *******Image Loader********
.img-loader {
	background: $ajax-loader no-repeat scroll 50% 50% transparent;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: -1;
}
