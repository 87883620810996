@import 'catalog/top-bar-filters';

#catalog #product-list {
	margin-top: rem-calc(25) !important;
	@include breakpoint(large) {
		margin-top: rem-calc(5) !important;
	}
}

.product-list-filters-page-numbering,
.cat-nav-bar-divider,
.nav-sub-category {
	display: none;
}

.top-bar-facets {
  margin: 0 auto;
  @include breakpoint(large) {
	margin-bottom: rem-calc(20);
	padding: 0 rem-calc(20) 0 rem-calc(2);
	right: 0;
}

  &.stuck-to-top {
	border: rem-calc(1) solid $light-gray;
	border-left: 0;
	border-right: 0;
	box-shadow: 0 rem-calc(2) rem-calc(4) rgba($black, 0.15) ;
	max-width: 100%;
	top: rem-calc(50);

	.facets-bar-container {
		@include breakpoint(xlarge) {
			margin: auto;
			max-width: rem-calc(1373);
			padding: 0 rem-calc(4) 0 rem-calc(10);
		}
	}
}

  .filter-menu-container {
	.view-layout-switch {
		margin: $top-bar-sub-menu-margin 0;

		li:last-child {
			display: none;
		}

		button {
			background: transparent;
			border-color: get-color(napa-grey);

			.icon-toggle-1box {
				&:before {
					@include icomoonFont($icon-block-plp-filter);
					color: get-color(napa-grey);
					font-size: rem-calc(22);
					vertical-align: middle;
				}
			}

			.icon-toggle-4box {
				&:before {
					@include icomoonFont($icon-grid-plp-filter);
					color: get-color(napa-grey);
					font-size: rem-calc(22);
					vertical-align: middle;
				}
			}

			&.clicked,
			&:hover {
				border-color: $black;

				.icon-toggle-4box,
				.icon-toggle-1box {
					&:before {
						color: $black;
					}
				}
			}
		}
	}
}
}

.product-list-header-container {
.product-list-header-title {
	@include breakpoint(large) {
    	align-items: center;
		display: flex;
		margin-left: rem-calc(-8);
	}

	.product-info {
		display: inline-flex;
		float: right;
		font-size: rem-calc(12);
    	line-height: rem-calc(14);
		margin: rem-calc(-15) 0 0 0;

		@include breakpoint(large) {
			font-size: rem-calc(14);
			margin: rem-calc(4) 0 0 0;
		}

		.active-filters__item-count {
			padding-right: rem-calc(5);
			@include breakpoint(large) {
				&::before {
					content: "( ";
				}
			}
		}

		.active-filters__counter-label {
			@include breakpoint(large) {
				&::after {
					content: " )";
				}
			}
		}
    }
}

.search-results-summary {
	border-bottom: 0;
}

	.garnish-wrapper {
		@include breakpoint (large) {
			.plp-banner-column {
				padding: 0 rem-calc(3);
				text-align: left;

				h2 {
					display: none;
					font-size: rem-calc(26);
					line-height: rem-calc(30);
					margin-bottom: rem-calc(10);
				}

				p {
					padding: 0;
				}

				.link-container {
					margin-bottom: rem-calc(30);
				}

				.copy-container {
					font-weight: lighter;
				}
			}
		}
	}
}

.off-canvas-filter-content {
	padding: 0;

	.off-canvas-filter-controls {

		.off-canvas-filter-controls-combo {
			margin: 0 0 0 rem-calc(11);
			width: 62%;

		.label {
			float: left;
			font-size: rem-calc(14);
			font-weight: normal;
			margin: 0 0 0 rem-calc(-20);
		}
		}
	}

	.active-filters-meta
		button {
		  background: transparent;
		  border: rem-calc(1) solid $medium-gray ;
		  color: $black;
		  height: rem-calc(43);
		  margin-right: rem-calc(10);
		  margin-top: rem-calc(-43);
		  width: 30%;

		  &::after {
			@include icomoonFont($icon-close);
		   font-size: rem-calc(11);
		   font-weight: normal;
           margin-left: rem-calc(6);
		   padding: 0 0 0 rem-calc(8);
		  }
		}

	.active-filters-container {
		.product-info {
			display: flex;
			margin-left: rem-calc(12) !important;
			width: 100%;

			.active-filters__item-count {
				margin-right: rem-calc(3);
			}
		}



		.active-filters-list-container
		.active-filters-list__active-filter {
			background-color: get-color(white-smoke);
			border: 0;
			border-radius: 0;
			display: inline-flex;
			margin: 0 rem-calc(-5) rem-calc(10) rem-calc(12);
			letter-spacing: 0;

			.active-filter-label {
				font-size: rem-calc(10);
				padding-right: rem-calc(15);
			}
		}

    .active-filters-meta
    .active-filters__active-text {
              display: none;
            }
		}

	.icon-filter {
		&:before {
			content: $icon-menu;
			float: right;
			font-size: rem-calc(14);
			margin: 0 rem-calc(-18) 0 0;
		}
	}
}

.facets-container {

	@include breakpoint(large) {
		padding: 0;

		.stuck-to-top & {
			padding: rem-calc(15) 0 0 0;
		}
	}

	label {
		font-weight: lighter;
	}

    .filter-menu__sub-menu {
        margin-right: inherit;

		&--sort-container {
			@include breakpoint (large) {
			border: rem-calc(1.5) solid $black;

			.sub-menu-options {
				border-bottom: 0;
			}

			&.active {
				left:0;
				padding-right: rem-calc(12);
				padding-top: rem-calc(10);
				top: rem-calc(48);
				width: max-content;

				.sort-by-option {
					.flex-centered {
						font-size: rem-calc(14);
					}
				}
			}
        }
	}

	&[data-attribute-id="Gender"] {
		.sub-menu-options__label-text {
			text-transform: lowercase;
			&:first-letter {
				text-transform: uppercase;
			  }
		}
	}

        &.active {
            border-top: rem-calc(1) solid $light-gray;
			@include breakpoint (large) {
				border-top: 0;
        	}
		}

        .filter-menu__sub-menu-header {

			@include breakpoint(large) {
				padding: rem-calc(13) 0;
			}

            &.filter-sort-menu {
				background-color: $white;
                border: rem-calc(1) solid $black;
				color: $black;
				margin-left: 0;
				position: relative;

				.filter-menu__title-text {
					padding-right: rem-calc(20);

					.sort-by-value-seperator {
						display: none;
					}

					.sort-by-value {
						display: none;
					}
				}

				.filter-menu__title-icon {
					&::before {
						color: $black;
					}
				}
            }

            &.active {
                border: 0;
				@include breakpoint(large) {
					border-color: $black;
					border-style: solid;
					border-width: rem-calc(1.5) rem-calc(1.5) 0 rem-calc(1.5);
					margin: rem-calc(-1) auto 0 0;
					padding-top: rem-calc(11);
				}

				.filter-menu__title-text {
					color: $black !important;
					font-size: rem-calc(14);
					padding-bottom: rem-calc(10);
					padding-top: rem-calc(2);
					@include breakpoint(large) {
						font-size: rem-calc(12);
					}

					@include breakpoint(xlarge) {
						font-size: rem-calc(14);
					}
                }
            }

            .icon-plus {
				&::before {
					@include icomoonFont($icon-plus);

					@include breakpoint(large) {
						@include icomoonFont($icon-arrow-down-indicator-open);
						color: $white;
						font-size: rem-calc(12);
					}
				}
			}

            .icon-minus {
				&::before {
					@include icomoonFont($icon-minus);

					@include breakpoint(large) {
						@include icomoonFont($icon-arrow-up-indicator-open);
						color: $black;
						font-size: rem-calc(12);
						margin-top: rem-calc(3);

					}
				}
			}

			.filter-menu__title-text {
				@include breakpoint(medium) {
					display: flex;

					.filter-menu__title-number-selected {
						padding-left: rem-calc(5);
					}
				}

				@include breakpoint(large) {
					font-weight: 300;
				}
			}

        }

		&.filter-type-size {
			@include breakpoint(large) {
				.filter-menu__sub-menu--filter-container {
					width: auto !important;
				}
			}

			.sub-menu-options {
				@include breakpoint(large) {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					height: fit-content;
					max-height: rem-calc(220);
					width: rem-calc(430);

					&__selection {
						width: rem-calc(100);
					}
				}
			}
		}

        &.filter-type-color {
            .filter-menu__sub-menu-options {
              column-count: 3 !important;

			  @include breakpoint(large) {
				  column-count: unset !important;
				  height: auto;
				  max-height: 100%;
				  width: auto;
            }
        }

		.sub-menu-options {
			@include breakpoint(large) {
				display: flex;
				flex-wrap: wrap;
				margin-left: 0;
				margin-right: 0;
				margin-top: rem-calc(10);
				min-width: rem-calc(325);
				width: rem-calc(420);
			}

			&__checkbox {
				@include breakpoint(large) {
					display: none;
				}
			}

			&__selection {
				@include breakpoint(large) {
					margin-bottom: rem-calc(20);
					width: 20%;
				}

				label {
					&.flex-centered {
						@include breakpoint(large) {
							align-items: center;
							flex-direction: column;
							height: auto;
							margin-left: 0;
						}
					}
				}
			}

			&__label {
				@include breakpoint(large) {
					align-items: center;
					flex-direction: column;
				}

				.selected {
					&::before {
						@include breakpoint(large) {
							color: $white;
							padding: rem-calc(5);
							position: absolute;
						}
					}

					&.inverse-selection-check-color:before {
								color: $black;
							}
				}
			}

			&__label-text {
				&--num-products {
					display: none;
				}
			}
		}
	}

	&--filter-container.active {
		left: 0;
		padding-left: rem-calc(13);
		padding-right: rem-calc(13);
		top: rem-calc(44);

		@include breakpoint(large) {
			top: rem-calc(42);
		}

		@include breakpoint(xlarge) {
			top: rem-calc(44);
		}
	}
}

	.clear-filters {
		font-family: get-font('AkkuratPro');
		white-space: nowrap;
	}

	.sub-menu-actions {
		.sub-menu-actions {

			&__action--apply {
				width: 100%;

				.button {
					margin: 0;
					white-space: nowrap;
				}
			}

			&__action--clear {
				margin-right: rem-calc(10);
				width: 100%;
			}
		}
	}


	.sub-menu-options {
		border-bottom: 0;

		  @include breakpoint(large) {
			 border-bottom: rem-calc(1) solid $light-gray;
			 height: auto;
			 max-height: rem-calc(300);
			 overflow-x: hidden;
    		 overflow-y: auto;

		 &__checkbox-container {
			label.flex-centered {
				align-items: flex-start;
				height: auto;
				min-height: rem-calc(30);
			}
		}
	}

		&__checkbox {
			 border-radius: 0;
			 margin-top: rem-calc(1);

			&:before {
				 border-radius: 0;
			}

			&:checked {

				&:after {
					color: $white;
					@include breakpoint(large) {
						color: $black;
					}
				}
			}
		}

		&__selection {

			.swatch {
				border: rem-calc(0.5) solid get-color(napa-grey);
				border-radius: 50%;
				box-shadow: 0 0 0 0.5px get-color(napa-grey);
				height: rem-calc(25);
				margin-bottom: rem-calc(7);
				width: rem-calc(25);
			}
		}

		&__label-text {
			color: get-color(primary);

			&--num-products {
				color: $black;
			}
		}
	}

	.filter-menu {
		&__ title-text {
			text-transform: uppercase;
		}
	}

	.active-filter-label {
		font-size: rem-calc(10) !important;
		padding-right: rem-calc(15) !important;
	}

	.active-filter-remove::before {
		font-size: rem-calc(10);
	}

	.active-filters-list {
	&__active-filter {
		@include breakpoint(large) {
		background-color: $white;
		margin: 0 rem-calc(20) rem-calc(10) 0;

		.active-filter-color {
			border-radius: 50%;
			height: rem-calc(15);
			order: 1;
			width: rem-calc(15);
		}

		.active-filter-label {
			font-weight: 400;
			order: 2;
		}

		.active-filter-remove {
			order: 3;

			&::after {
			@include icomoonFont($icon-close);
			font-size: rem-calc(10);
			line-height: 2;
			}
		}
	}
}
}

	.active-filters__clear-all {
		&--link {
			@include breakpoint(large) {
			border: 0;
			border-left: get-color(napa-grey-2) rem-calc(1) solid;
			margin: 0 0 auto rem-calc(-10);
			padding: 0 0 0 rem-calc(25);
		}
	}
}

	.active-filters-container {
		@include breakpoint(large) {
		border-bottom: 0;
		border-top: rem-calc(1) solid $light-gray;
		margin-left: rem-calc(12);
		margin-top: rem-calc(-8);
		padding-top: rem-calc(20);

		.stuck-to-top & {
			display: none;
		}

		.active-filters-display {
			float: left;
			padding-top: 0;
		}

		.active-filters-list-container {
			max-width: 65%;
			padding-left: 0;
			padding-top: 0;
			width: auto;
		}
	}

	.product-info {
		align-items: center;
		display: none;
		justify-content: end;

		.active-filters__item-count {
			margin: 0 rem-calc(5) 0 0;
		}
	}
}
}
