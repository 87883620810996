@import 'global/z-indexes';

//Product Block Promo Badge
$productBlockPromoBadgeWidth: 60px !default;
$productBlockPromoBadgeWidthTablet: 50px !default;
$productBlockPromoBadgeWidthMobile: 30px !default;
$productBlockPromoBadgeHeightMobile: $productBlockPromoBadgeWidthMobile !default;
$productBlockPromoDisplayMobile: none !default;
$productBlockPromoBadgeAlign: right !default;
$productBlockPromoBadgeTop: 15px !default;
$productBlockPromoBadgeBottom: auto !default;
$productBlockPromoBadgeLeft: auto !default;
$productBlockPromoBadgeRight: 15px !default;
$productBlockInfoHeight: auto !default;
$productBlockStyleNumberFontWeight: null !default;
$productBlockPromoBadgeZindex: null !default;
$productBlockPromoBadgeImageHeight: 100% !default;
$productBlockPromoBadgeImageWidth: 100% !default;


//product block promo eyebrow
$productBlockPromoEyebrowTop: -30px !default;
$productBlockPromoEyebrowAlign: center !default;

//Product Block Name
$productBlockNameHeight: auto !default;

//Product Block Rating
$ratingMargins: 0 !default;
$ratingContainerHeight: auto !default;

//Product Block Dismensions
$productBlockDesktopHeight: 360px !default;
$productColumnsInBlock: 4 !default;
$productColumnsInBlockTablet: 6 !default;
$productColumnsInBlockMobile: 6 !default;
//Product Block Styling
$productBlockTextAlign: left !default;
$productBlockBorderHover: 4px solid transparent !default;
$productBlockIconDisplay: null !default;
$productBlockFigureMinHeight: null !default;
$productBlockFigureMinHeightMedium: null !default;
$productLanesLayoutEnabled: false !default;
$productBlockPaddingSmall: 0 !default;
$productBlockPaddingLarge: null !default;
$promoBadgeEnabled: false !default;
$infoBadgeEnabled: false !default;
$promoEyebrowEnabled: false !default;
$promoPriceEnabled: false !default;
$pricingEnabled: false !default;
$productBlockViewPagerContainerOpacity: null !default;
$plpProductBlockCarouselDotsOpacity: null !default;
$plpProductBlockCarouselDotsDisplay: none !default;
$plpProductBlockCarouselDotsOpacityLarge: null !default;
$plpProductBlockCarouselDotsPagerBorder: rem-calc(1) solid $medium-gray !default;
$plpProductBlockCarouselDotsActivePagerBgColor: $black !default;
$arrows-container-active-opacity: 1 !default;
$arrows-container-active-opacity-medium: null !default;
$plpProductBlockCarouselDotsBottom: null !default;
$plpProductBlockCarouselDotsPosition: null !default;
$plpProductBlockCarouselDotsWidth: null !default;
$plpProductBlockCarouselDotsZindex: null !default;

$productColumnsInLane: 3 !default;
$productColumnsInLaneTablet: 4 !default;
$productColumnsInLaneMobile: 6 !default;

//quick shop button
$quickshopBtnFontSize: 15px !default;
$quickshopBtnWidth: 60% !default;
$quickshopBtnTop: 70% !default;
$quickshopBtnBottom: auto !default;
$quickshopBtnLeft: 20% !default;

$heroQuickshopBtnWidth: 20% !default;
$heroQuickshopBtnTop: 70% !default;
$heroQuickshopBtnBottom: auto !default;
$heroQuickshopBtnLeft: 40% !default;

// Carousel
$productCarouselButtonBg: transparent !default;
$productCarouselButtonColor: $body-font-color !default;
$productCarouselButtonFontSize: null !default;
$productCarouselButtonPadding: rem-calc(16) !default;
$productCarouselButtonFontSizeMedium: null !default;
$productCarouselButtonPaddingMedium: null !default;
$productRatingButtonFontSize: null !default;
$productRatingButtonFontSizeMedium: null !default;
$productCarouselArrowContainerOpacity: 0 !default;
$productBlockMarginLarge: 0 rem-calc(-4) rem-calc(32) 0 !default;

$product-block-info-text-align: center !default;
$product-block-view-button-focus-display: block !default;
$productBlockStarRatingPositionLeft: null !default;
$product-block-image-helper-text-display: null !default;
$product-block-info-margin: $productBlockInfoMargin 0 !default;
$product-block-info-line-height: rem-calc(18) !default;

// Icon favorite
$productIconFavoriteMarginTop: null !default;
$productIconFavoriteFontSize: rem-calc(20) !default;

//reserved size for plp images
$product-block-views-container-min-height: null !default;
$product-block-views-container-min-width: null !default;

$reviewCounterFontSizeMedium: null !default;
$reviewCounterFontStyle: (
  'font': (
    'color': $body-font-color,
    'line-height': 1.4em,
    'font-size': 0.75em,
    'margin': 0 0 0 2px,
    'vertical-align': top
  )
)!default;
$productInfoFontStyle: (
  'font': (
    'font-family': $body-font-family,
    'color': $body-font-color,
    'font-size': convert-to-em(13px)
  )
) !default;

@mixin product-block-short-description-more-layout {
	cursor: pointer;
	display: inline-block;

	&:hover {
		color: get-color(primary);
	}

	&:hover + &-content {
		display: block;
	}

	&-content {
		display: none;
		left: 0;
		margin: 0 $column-gutter/2;
		position: absolute;
		right: 0;
		z-index: 12;

		&:hover {
			display: block;
		}
	}
}
@mixin show-quick-view-buttons-on-hover {
	.product-block,
	.full-width-product-block {
		&:hover {
			.view {
				.button {
					display: block; // Expose buttons on hover
				}
			}
		}

		.view {
			.focused.button {
				display: $product-block-view-button-focus-display;
			}
		}
	}

	.product-block {
		&:hover {
			border: $productBlockBorderHover;
		}
	}
}

.product-block {
	@extend %grid-block-height-reset; //placeholders.scss
	@include grid-column($productColumnsInBlockMobile);
	border: $productBlockBorder;
	box-sizing: border-box;
	display: inline-block;
	float: none;
	height: auto;
	margin: 0 -4px $productBlockMargin 0;
	padding: $productBlockPaddingSmall;
	text-align: $productBlockTextAlign;
	vertical-align: top;

	@include breakpoint(large) {
		padding: $productBlockPaddingLarge;
	}

	.icon-favorite-filled,
	.icon-favorite {
		background: transparent;
		color: inherit;
		font-size: $productIconFavoriteFontSize;
		line-height: 1.7em;
		margin: 0;
		margin-top: $productIconFavoriteMarginTop;
		padding: 0;

		&:focus,
		&:hover {
			background: transparent;
		}
	}

	.product-block-pdp-url {
		cursor: pointer;
		display: block;
		min-height: $product-block-views-container-min-height;
		min-width: $product-block-views-container-min-width;
	}

	.product-block-views-container {
		width: 100%;
	}

	.product-block-view {
		&:hover {
			.arrows-container {
				@include breakpoint(large) {
					opacity: 1;
				}
			}

			.pager-container {
				@include breakpoint(large) {
					opacity: $productBlockViewPagerContainerOpacity;
				}
			}
		}

		position: relative;

		@include breakpoint(large) {
			.arrows-container {
				visibility: hidden;
			}

			&.active {
				.arrows-container {
					visibility: visible;
				}
			}
		}

		.icon-error,
		.icon-success {
			display: $productBlockIconDisplay;
		}

		.product-block-figure {
			min-height: $productBlockFigureMinHeight;

			@include breakpoint(medium) {
				min-height: $productBlockFigureMinHeightMedium;
			}

			.mol-video-player {
				width: 100%;
			}
		}
	}

	.arrows-container {
		align-items: center;
		cursor: pointer;
		display: flex;
		height: 100%;
		justify-content: space-between;
		opacity: $productCarouselArrowContainerOpacity;
		pointer-events: none;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: z-index(carousels);

		.column-6-layout & {
			display: none !important;
		}

		.column-1-layout & {
			opacity: 1;
		}

		@include breakpoint(large) {
			display: none;
			opacity: 0;
		}

		&.active {
			display: flex;
			opacity: $arrows-container-active-opacity;
			@include breakpoint(medium) {
				opacity: $arrows-container-active-opacity-medium;
			}

			.column-2-layout & {
				display: none;

				@include breakpoint(large) {
					display: flex;
				}
			}

			@include breakpoint(large) {
				opacity: 0;
			}
		}

	}

	.arrow {
		background: $productCarouselButtonBg;
		border: 0;
		color: $productCarouselButtonColor;
		font-size: $productCarouselButtonFontSize;
		margin: 0;
		padding: $productCarouselButtonPadding;
		pointer-events: all;

		@include breakpoint(medium) {
			font-size: $productCarouselButtonFontSizeMedium;
			padding: $productCarouselButtonPaddingMedium;
		}
	}

	&,
	&:last-child:not(:first-child) {
	float: none;
	}

	&-figure {
		margin: 0;
	}

	@include breakpoint(medium) {
		@include grid-column($productColumnsInBlockTablet);
		float: none;

		&,
          &:last-child:not(:first-child) {
            float: none;
          }

	}

	@include breakpoint(large) {
		@include grid-column($columns: $productColumnsInBlock);
		float: none;
		margin: $productBlockMarginLarge;

		&,
          &:last-child:not(:first-child) {
            float: none;
          }
	}

	.nav-dept & {
		width: 100%;
	}

	&-views-container {
		position: relative;

		.carousel-initializing & {
			&.cycle-sentinel {
				position: absolute !important;
			}
			&.cycle-slide-active {
				position: relative !important;
			}
		}

		&.alt-views-container {
			color: transparent;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}
	}

	.view {
		overflow: hidden;
		position: relative;
		text-align: center;

		img {
			width: 100%;
		}

		.button {
			bottom: $quickshopBtnBottom;
			display: none;
			font-size: em-calc($quickshopBtnFontSize);
			font-weight: $quickshopBtnFontWeight;
			left: $quickshopBtnLeft;
			position: absolute;
			top: $quickshopBtnTop;
			width: $quickshopBtnWidth;
		}

		.button,
		.favorite-pos {
			z-index: z-index(badges);
		}

		@if($promoBadgeEnabled) {
			.promo-badge {
				bottom: $productBlockPromoBadgeBottom;
				height: $productBlockPromoBadgeHeightMobile;
				left: $productBlockPromoBadgeLeft;
				position: absolute;
				right: $productBlockPromoBadgeRight;
				text-align: $productBlockPromoBadgeAlign;
				top: $productBlockPromoBadgeTop;
				width: $productBlockPromoBadgeWidthMobile;
				z-index: $productBlockPromoBadgeZindex;

				img {
					height: $productBlockPromoBadgeImageHeight;
					width: $productBlockPromoBadgeImageWidth;
				}

				@include breakpoint(medium) {
					height: $productBlockPromoBadgeWidthTablet;
					width: $productBlockPromoBadgeWidthTablet;
				}

				@include breakpoint(large) {
					height: $productBlockPromoBadgeWidth;
					width: $productBlockPromoBadgeWidth;
				}
			}
		}
	}

	.info {
		height: $productBlockInfoHeight;
		left: $productBlockStarRatingPositionLeft;
		line-height: $product-block-info-line-height;
		margin: $product-block-info-margin;
		position: relative;
		text-align: $product-block-info-text-align;

		@if($infoBadgeEnabled) {
			.badge {
				@include setFontStyle($productInfoPromoEyebrowFontStyle);
			}
		}

		//Product Block Rating
		.rating-container {
			display: inline-block;
			height: $ratingContainerHeight;
			margin: $ratingMargins;

			a {
				text-decoration: none;
			}

			.reviewCounter {
				@include setFontStyle($reviewCounterFontStyle);
				@include breakpoint(medium) {
					font-size: $reviewCounterFontSizeMedium;
				}
			}

			.rating {
				line-height: inherit;
				font-size: $productRatingButtonFontSize;

				@include breakpoint(medium) {
					font-size: $productRatingButtonFontSizeMedium;
				}

			}
	  	}

	  	.avgRating {
	  		height: 19px;
	  	}


		@if($promoEyebrowEnabled) {
			.promo-eyebrow {
				@include setFontStyle($productInfoPromoEyebrowFontStyle);
				display: block;
				position: absolute;
				text-align: $productBlockPromoEyebrowAlign;
				top: $productBlockPromoEyebrowTop;
				width: 100%;
			}
		}

		.divider {
			color: $productNamePriceDividerFontColor;
		}

		@if($promoPriceEnabled) {
			margin-bottom: 5px;
		}

		.name {
			height: $productBlockNameHeight;

			a span {
				@include setFontStyle($productInfoFontStyle);
			}

			.product-block-style-number-wrapper {
				font-weight: $productBlockStyleNumberFontWeight;
			}
		}

		.more-color {
			font-family: $secondaryFont;
			font-size: em-calc(13);
			font-weight: normal;
			text-transform: normal;
		}
	}
}

.long-product-block {
	float: right;
}

.full-width-product-block {
	@include grid-column($columns: 12);
	overflow: hidden;

	.info {
		font-size: em-calc($heroProductNameFontSize);
	}

	.badge {
		font-size: em-calc($heroBadgeFontSize);
	}

	.view .button {
		bottom: $heroQuickshopBtnBottom;
		left: $heroQuickshopBtnLeft;
		top: $heroQuickshopBtnTop;
		width: $heroQuickshopBtnWidth;
	}
}

@include breakpoint(medium) {
	html.user-can-hover {
		@include show-quick-view-buttons-on-hover
	}
}

// Setting for product block on lanes layout //

@if($productLanesLayoutEnabled) {
	.product-block.lanes {
		@extend .product-block;
		@extend %grid-block-height-reset;
		@include grid-column-gutter();
		float: none;
		width: grid-column($productColumnsInLaneMobile);

		@include breakpoint(medium) {
			width: grid-column($productColumnsInLaneTablet);
		}

		@include breakpoint(large) {
			width: grid-column($productColumnsInLane);
		}

		&.product-4 {
			display: none;

			@include breakpoint(large) {
				display: inline-block;
			}
		}

		&.product-3 {
			display: none;

			@include breakpoint(medium) {
				display: inline-block;
			}
		}

		.promo-badge {
			display: $productBlockPromoDisplayMobile;

			@include breakpoint(medium) {
				display: block;
			}
		}
	}
}

.grid-mlt-link-wrapper {
	display: $product-block-image-helper-text-display;
	float: right;
	font-size: 10px;
	margin: $column-gutter/2 0 -4em;
	position: relative;
	right: 0;
    text-align: left;
    top: -4em;
    width: 20%;

    a {
    	color: black;
    	font-weight: 700;
    }

	@include breakpoint(medium) {
		max-width: 40px;
		right: -5px;
		width: 15%;
	}

}

#mlt-focus {
	h3 {
		text-align: center;
	}
}

// **********************************
//  Catalog Results - Favorite items
// **********************************
.favorites-item {
	.product-block .info {
		font-size: em-calc($catologProductNameFontSize);
	}
}

.grid-espot-container {
	display: inline-block;
	float: none;
	margin-right: -4px;
}

.espot-container {
	width: 100% !important; //this controls width in e-spot that has inline styling
}

.genericESpot {
	text-align: $productBlockPromoEyebrowAlign;
}

@mixin plp-product-block-carousel-dots {
  .pager-container {
    bottom: $plpProductBlockCarouselDotsBottom;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: rem-calc(5) 0 0 0;
	opacity: $plpProductBlockCarouselDotsOpacity;
	position: $plpProductBlockCarouselDotsPosition;
	width: $plpProductBlockCarouselDotsWidth;
	z-index: $plpProductBlockCarouselDotsZindex;

		@include breakpoint(medium) {
			display: $plpProductBlockCarouselDotsDisplay;
		}

		@include breakpoint(large) {
			opacity: $plpProductBlockCarouselDotsOpacityLarge;
		}

    .pager {
      background-color: $white;
      border: $plpProductBlockCarouselDotsPagerBorder;
      border-radius: 50%;
      display: inline-block;
      height: rem-calc(7);
      margin: 0 rem-calc(2);
      width: rem-calc(7);

      &.active {
        background-color: $plpProductBlockCarouselDotsActivePagerBgColor;
        border: 0;
      }
    }
  }
}

@mixin plp-quickshop-container-add-to-cart-btn {
	.product-block .quickshop-button-container {
		text-align: center;

		.product-block-quickshop-button {
			background: $white;
			border: rem-calc(1) solid $black;
			color: $black;
			font-size: rem-calc(12);
			min-width: fit-content !important; // sass-lint:disable-line no-important
			outline: none;
			padding: rem-calc(8);
			text-transform: capitalize;
			@include breakpoint(medium) {
				font-size: rem-calc(14);
				padding: rem-calc(10);
			}

			&::before {
				@include icomoonFont($icon-shopcart);
				padding-right: rem-calc(8);
			}

			&:hover {
				background: $black;
				color: $white;
			}
		}
	}
}
