
$inactiveColor: null !default;
$viewLayoutSwitchBottom: null !default;
$viewTextFontSize: null !default;
$viewTextFontWeight: null !default;
$viewTextPositionTop: null !default;
$viewLayoutSwitchDisplay: inline-block !default;
$viewLayoutSwitchDisplayMedium: null !default;
$view-layout-switch-button-border: null !default;
$view-layout-switch-button-border-color: null !default;
$view-layout-switch-button-border-radius: null !default;
$view-layout-switch-button-border-style: null !default;
$view-layout-switch-button-border-width: null !default;
$view-layout-switch-view-toggle-text-font-family: $font-family-secondary !default;
$view-layout-switch-button-background: $white !default;

@mixin view-layout-switch {

  .view-layout-switch {
    bottom: $viewLayoutSwitchBottom;
    display: $viewLayoutSwitchDisplay;
    float: right;
    list-style-type: none;
    padding: 0;

    @include breakpoint(medium) {
      display: $viewLayoutSwitchDisplayMedium;
    }

    li {
      display: inline-block;

      .view-toggle-text {
        font-size: $viewTextFontSize;
        font-weight: $viewTextFontWeight;
        font-family: $view-layout-switch-view-toggle-text-font-family;
        text-align: center;
        color: $black;
        display: none;

        &.clicked {
          display: block;
          position: relative;
          top: $viewTextPositionTop;
        }
      }
    }

    button {
      background: $view-layout-switch-button-background;
      border: $view-layout-switch-button-border;
      color: $inactiveColor;
      margin: 0;
      padding: rem-calc(10);

      &:hover,
      &:focus {
        background: $white;
        border-style: $view-layout-switch-button-border-style;
        border-width: $view-layout-switch-button-border-width;
        border-color: $view-layout-switch-button-border-color;
        border-radius: $view-layout-switch-button-border-radius;
        color: $inactiveColor;
      }

      &:hover {
        border-color: $inactiveColor;
      }

      &.clicked {
        border-style: $view-layout-switch-button-border-style;
        border-width: $view-layout-switch-button-border-width;
        border-color: $view-layout-switch-button-border-color;
        border-radius: $view-layout-switch-button-border-radius;
        color: get-color(secondary);
      }

    }
  }
  
  .catalog-product-listing,
  .view-layout-switch-grid {
    display: flex;
    flex-wrap: wrap;

    #catalog-form {
      width: 100%;
    }

    .grid-espot-content {
      border: 0 !important;
      flex: 100%;
      margin-top: 32px !important;
      max-width: calc(100% - 10px);
      min-height: 250px;
      overflow: hidden;
      padding: 0;

      @include breakpoint(medium) {
        flex: 33.3%;
        max-height: 540px;
        max-width: 33.3%;
      }

      @include breakpoint(large) {
        flex: 50%;
        max-height: 540px;
        max-width: 50%;
      }

      &:empty {
        display: none;
      }
    }

    &.column-1-layout {
      .product-block {
        width: 100%;
      }
    }

    &.column-2-layout {
      .product-block {
        width: 50%;
      }

      .grid-espot-content {
        flex: 100%;
        max-width: 100%;
        min-height: 247px;

        @include breakpoint(medium) {
          min-height: 920px;
        }
      }
    }

    &.column-4-layout {
      .product-block {
        width: 25%;
      }

      .grid-espot-content {
        flex: 50%;
        min-width: 50%;
      }
    }

    &.column-6-layout {
      .product-block {
        width: 16%;
      }

      .grid-espot-content {
        flex: 32%;
        margin-left: 0;
        margin-right: 0;
        max-height: 394px;
        max-width: 32%;
      }
    }
  }
}
