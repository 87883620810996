$facetValueCounterFontSize: 13px !default;
$facetValuePriceFontColor: null !default;
$filterRefineSearchPaddingTop: 27 !default;
$filterRefineSearchPaddingLeft: 27 !default;
$filterRefineSearchPaddingBottom: 27 !default;



// grid filter UI
$filterMinWidthSmall: 170px !default;
$filterMinWidthMedium: null !default;
$filterOverlayBg: null !default;
$filterGroupBorderBottom: null !default;
$filterGroupMarginBottom: null !default;
$filterGroupPadding: 10px 0 10px 0 !default;
$filterTitleColor: null !default;
$filterTitleBg: null !default;
$filterTitleHeight: 30px !default;
$filterTitleFontSize: convert-to-em(20px) !default;
$filterTitleFontSizeMedium: $filterTitleFontSize !default;
$filterTitleWidth: null !default;
$filterTitleFontWeight: null !default;
$filterTitleTextTransform: inherit !default;
$filterTitleTextAlign: null !default;
$filterTitlePadding: 0 0 0 10px !default;
$filterTitletoggleColor: $fontLink !default;
$filterOptionsMargin: 0 !default;
$filterOptionsPadding: null !default;
$filterOptionsItemHeight: 40px !default;
$filterOptionsItemLineHeight: 40px !default;
$filterOptionsItemHeightMobile: 40px !default;
$filterOptionsItemLineHeightMobile: 40px !default;
$filterOptionsItemPadding: 0 0 0 10px !default;
$filterOptionsItemMargin: 0 0 5px 0 !default;
$filterOptionsItemBorder: 2px solid #fff !default;
$filterOptionsItemFontSize: convert-to-em(16px) !default;
$filterOptionsItemBackground: transparent !default;
$filterOptionsItemColorReset: inherit !default;
$filterOptionsItemFontSizeReset: inherit !default;
$filterOptionsItemLineHeightReset: null !default;
$filterOptionsItemBorderReset: 0 !default;
$filterOptionsItemHeightReset: null !default;
$filterOptionsItemFontWeight: inherit !default;
$filterOptionsItemButtonPadding: 0 !default;
$filterOptionsItemButtonMargin: 0 !default;
$filterOptionsItemLetterSpacing: inherit !default;
$filterOptionsItemTextAlign: left !default;
$filterOptionsItemTextTransform: none !default;
$filterOptionsItemWidth: 100% !default;
$filterOptionsMoreItemFontSize: convert-to-em(13px) !default;
$filterOptionsItemSizeFontSize: convert-to-em(14px) !default;
$filterOptionsMoreItemDisplay: null !default;
$filterOptionsMoreItemAlignItems: center !default;
$filterOptionsSelectedItemBgColor: #fff !default;
$filterOptionsSelectedItemBgColorHover: #fff !default;
$filterOptionsTitleIconTopPosition: null !default;
$filterOptionsTitleIconTransform: null !default;
$filterOptionsTitleMinHeight: null !default;

$filterOptionTitleBackground: transparent !default;
$filterOptionTitleBorder: 0 !default;
$filterOptionTitleColor: inherit !default;
$filterOptionTitleFontWeight: inherit !default;
$filterOptionTitleLetterSpacing: 0 !default;
$filterOptionTitleMargin: 0 !default;
$filterOptionTitleMarginMedium: $filterOptionTitleMargin !default;
$filterOptionTitlePadding: 0 !default;
$filterOptionTitleTextAlign: left !default;
$filterOptionTitleWidth: 100% !default;

$filterOptionsSelectedItemFontColor: $body-font-color !default;
$filterOptionsSelectedItemFontColorHover: $body-font-color !default;
$filterAttrboxSize: 41px !default;
$filterAttrboxBorder: 1px solid $fontGrey !default;
$filterAttrboxBorderHover: 1px solid $fontGrey !default;
$filterAttrboxInsetBorderHover: 0 0 0 1px get-color(secondary) inset !default;
$filterAttrboxBgHover: $backgroundColor !default;
$filterAttrboxFontColor: $fontLink !default;
$filterAttrboxFontColorHover: #fff !default;
$filterAttrboxSelectedBg: get-color(secondary) !default;
$filterAttrboxSpacing: 1px !default;

$filterAttrBoxIconColor: null !default;
$filterAttrBoxIconDisplay: none !default;
$filterAttrBoxIconFontSize: null !default;
$filterAttrBoxIconHeight: null !default;
$filterAttrBoxIconTextAlign: null !default;

$filterLinkColor: $fontLink !default;
$filterLinkColorHover: $fontLink !default;
$filterLinkSizeAttrColorHover: $fontLink !default;
$filterCounterColor: $fontLinkHover !default;
$filterLinkHeight: convert-to-em(25) !default;
$filterLinkActionsMargin: 5px 0 0 10px !default;
$filterLinkFontWeight: bold !default;
$filterLinkTextDecoration: null !default;
$filterLinkTextTransform: null !default;
$filterLinkHeight: null !default;

$filterRefineSearchButtonIcon: $icon-arrow-down-indicator !default;
$filterRefineSearchButtonIconSelected: $icon-arrow-up-indicator !default;
$filterRefineSearchButtonIconColor: null !default;
$filterBoxHeightMobile: 38px !default;
$filterBoxLineHeightMobile: 38px !default;
$filterBoxPaddingTopMobile: 0 !default;
$filterAttrBoxCenterLineHeight: null !default;

//variables for filter accordion.
$filterBarAccordionEnabled: false !default;
$filterAccordianExpandedStateIconfont: null !default;
$filterAccordianCollapsedStateIconfont: null !default;
$filterAccordianExpandedStateIconBorder: null !default;
$filterAccordianExpandedStateIconPadding: null !default;
$filterAccordianIconHeight: 1 !default;
$filterAccordianIconfontsize: 16px  !default;
$filterAccordianIconRightPosition: 10px !default;
$filterOptionOpacity: null !default;
$filterOptionMaxHeight: null !default;
$filterOptionCollapsedMaxHeight: null !default;
$filterOptionCollapsedOverflow: null !default;
$filterOptionCollapsedOpacity: null !default;
$filterTitleAccordianCursor: null !default;
$fitlerBreadcrumbFacetListResetDisplay: block !default;
$filtersResetAllFontFamily: null !default;
$filtersResetAllDisplay: block !default;
$filtersResetAllFloat: null !default;
$filtersResetAllTextDecoration: null !default;
$filter-title-background-color: null !default;
$filter-group-first-child-border-top: 0 !default;
$filter-options-margin-top: null !default;

.nav-dept {
	@include grid-column($columns: 12);
		background-color: $filterOverlayBg;
		display: none;
		margin-bottom: 20px;
		z-index: 1;

	@include breakpoint(medium) {
		@include grid-column($columns: 3, $gutters: 0);

		background-color: transparent;
		display: block;
		margin-bottom: 0;
		padding: 0;
	}

	.filters-nav {
		@include grid-column($columns: 12);
		margin: 0;

		@include breakpoint(medium) {
			min-width: $filterMinWidthSmall;
		}
		@include breakpoint(large) {
			min-width: $filterMinWidthMedium;
		}
	}

	&.active {
		max-width: 282px;
		position: fixed;
		top: 0;
	}

	.filter-group {
		@include setFontStyle($filterGroupFontStyle);
		border-bottom: $filterGroupBorderBottom;
		margin-bottom: $filterGroupMarginBottom;
		padding: $filterGroupPadding;

		&:first-child {
			border-top: $filter-group-first-child-border-top;
			padding: $filterGroupPadding;
		}

		&.reset-all {
			border: 0;
			margin: 0;
			padding: $filterGroupPadding;

			@include breakpoint(medium) {
				padding: 0;
			}
		}

		.reset-all-filters {
			color: $filterOptionsSelectedItemFontColor;
			display: $filtersResetAllDisplay;
			float: $filtersResetAllFloat;
			font-family: $filtersResetAllFontFamily;
			outline: none;
			text-decoration: $filtersResetAllTextDecoration;

			&:hover {
				color: $filterOptionsSelectedItemFontColor;
			}
		}

		.breadcrumb-facet-list {

		.reset-all-filters  {
				display: $fitlerBreadcrumbFacetListResetDisplay;
			}
		}
	}

	.filter-title {
		background: $filterTitleBg;
		color: $filterTitleColor;
		cursor: pointer;
		font-size: $filterTitleFontSize;
		font-weight: $filterTitleFontWeight;
		line-height: $filterTitleHeight;
		padding: $filterTitlePadding;
		text-align: $filterTitleTextAlign;
		text-transform: $filterTitleTextTransform;
		width: $filterTitleWidth;
		@include breakpoint(medium) {
			font-size: $filterTitleFontSizeMedium;
		}
		.attr-box-clear {
			color: $filterTitletoggleColor;
			cursor: pointer;
			margin-left: 20px;

			&:hover {
				color: $filterLinkColorHover;
			}
		}
	}

	.filter-options {
		li {
			list-style: none;
		}

		ul {
			@include clearfix();

			margin: $filterOptionsMargin;
			padding: $filterOptionsPadding;

			&.show-hidden-filters {
				display: none;
			}
		}

		// Reset inherited button styles
		button {
			background: $filterOptionsItemBackground;
			border: $filterOptionsItemBorderReset;
			color: $filterOptionsItemColorReset;
			font-size: $filterOptionsItemFontSizeReset;
			font-weight: $filterOptionsItemFontWeight;
			height: $filterOptionsItemHeightReset;
			letter-spacing: $filterOptionsItemLetterSpacing;
			line-height: $filterOptionsItemLineHeightReset;
			margin: $filterOptionsItemButtonMargin;
			padding: $filterOptionsItemButtonPadding;
			text-align: $filterOptionsItemTextAlign;
			text-transform: $filterOptionsItemTextTransform;
			width: $filterOptionsItemWidth;
		}

		.facet-value {
			color: $filterLinkColor;
			cursor: pointer;
			display: flex;
			font-size: $filterOptionsItemFontSize;
			line-height: $filterLinkHeight;
			list-style: none;

			&:hover {
				color: $filterLinkColorHover;
			}

			> input {
				flex: none;
			}

			.counter {
				color: $filterCounterColor;
				font-size: $facetValueCounterFontSize;
				font-weight: normal;
			}

			&[data-facet-value^=price_] {
				color: $facetValuePriceFontColor;
			}

			&.selected {
				background-color: $filterOptionsSelectedItemBgColor;
				border: $filterOptionsItemBorder;
				color: $filterOptionsSelectedItemFontColor;
				height: $filterOptionsItemHeightMobile;
				line-height: $filterOptionsItemLineHeightMobile;
				margin: $filterOptionsItemMargin;
				padding: $filterOptionsItemPadding;

				@include breakpoint(medium) {
					height: $filterOptionsItemHeight;
					line-height: $filterOptionsItemLineHeight;
				}

				&:hover {
					background-color: $filterOptionsSelectedItemBgColorHover;
					color: $filterOptionsSelectedItemFontColorHover;
				}

				.filter-remove {
					cursor: pointer;
					float: right;
					margin-right: 10px;
				}

				.counter {
					display: none;
				}
			}
		}

		.attr-box {
			border: $filterAttrboxBorder;
			color: $filterAttrboxFontColor;
			cursor: pointer;
			display: inline-block;
			float: left;
			font-size: $filterOptionsItemSizeFontSize;
			height: $filterAttrboxSize;
			line-height: $filterAttrboxSize;
			list-style: none;
			margin: $filterAttrboxSpacing;
			text-align: center;
			width: $filterAttrboxSize;

			.icon-check {
				color: $filterAttrBoxIconColor;
				display: $filterAttrBoxIconDisplay;
				font-size: $filterAttrBoxIconFontSize;
				height: $filterAttrBoxIconHeight;
				text-align: $filterAttrBoxIconTextAlign;
			}

			&:hover {
				background-color: $filterAttrboxBgHover;
				border: $filterAttrboxBorderHover;
				color: $filterLinkSizeAttrColorHover;
			}

			&.selected {
				background-color: $filterAttrboxSelectedBg;
				border: $filterAttrboxBorderHover;
				box-shadow: $filterAttrboxInsetBorderHover;
				color: $filterAttrboxFontColorHover;
				position: relative;

				.icon-check {
					background-repeat: no-repeat;
					color: #fff;
					display: block;
					height: 100%;
					left: 0;
					padding-top: 18px;
					position: absolute;
					top: 0;
					width: 100%;
				}

				&.show-check-mark {
					font-size: 0;
				}
			}
		}

		.attr-box-center {
			line-height: $filterAttrBoxCenterLineHeight;
		}
	}

	.filter-actions {
		align-items: $filterOptionsMoreItemAlignItems;
		background: $filterOptionsItemBackground;
		color: $filterLinkColor;
		cursor: pointer;
		display: $filterOptionsMoreItemDisplay;
		font-size: $filterOptionsMoreItemFontSize;
		font-weight: $filterLinkFontWeight;
		height: $filterLinkHeight;
		letter-spacing: $filterOptionsItemLetterSpacing;
		line-height: $filterLinkHeight;
		margin: $filterLinkActionsMargin;
		padding: 0;
		text-decoration:  $filterLinkTextDecoration;
		text-transform: $filterLinkTextTransform;
		&:active {
			background: $filterOptionsItemBackground !important;
		}

		&:hover {
			color: $filterLinkColorHover;
		}

	}

	.filter-group-with-image {

		.facet-value-with-image {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;

			li {
				flex-basis: 28%;
				padding: 0 rem-calc(10) rem-calc(10) 0;
			}
		}

		.facet-value {
			border: 1px solid $dark-gray;
			display: block;
			min-height: rem-calc(82);
			min-width: rem-calc(82);
			padding: rem-calc(5);

			.facet-value-text {
				color: $black;
				font-size: rem-calc(13);
				font-weight: 300;
				line-height: rem-calc(16);
				padding: rem-calc(5) 0 0 0;
			}

			&.selected {
				background-color: transparent;
				border: 2px solid $black;
				border-radius: 1px;
				margin: 0;
				padding: rem-calc(5);

				&:hover {
					background-color: transparent;
					border: 2px solid get-color(primary);
					outline: 0;
				}
			}

			&:hover {
				background-color: transparent;
				border: 1px solid transparent;
				outline: 2px solid get-color(primary);
			}

			&.out-of-stock,
			&.disabled,
			&:disabled {
				border: 0;
				cursor: not-allowed;
				opacity: 0.7;
				outline: 0;
				position: relative;

				.facet-value-text {
					color: $light-gray;
				}

				&::before,
				&::after {
					background: linear-gradient(90deg, $light-gray 0%, $light-gray 20%, transparent 20%,
					transparent 80%, $light-gray 80%, $light-gray 100%);
					content: '';
					height: 2px;
					left: 50%;
					position: absolute;
					top: 50%;
					width: 100%;
				}

				&::before {
					transform: translate(-50%, 50%) rotate(45deg);
				}

				&::after {
					transform: translate(-50%, 50%) rotate(-45deg);
				}
			}
		}
	}

	.epot-filter-group {
		text-transform: uppercase;

		.filter-group {
			float: none;
		}

		.filter-title {
			float: none;
		}
	}
}

.product-list-filters-action {
	@include clearfix();
	float: none;
	width: grid-column(12);
	z-index: 100;

	@include breakpoint(medium) {
		display: none;
	}

	.button.secondary {
		@include grid-column($columns: 5);

		&:last-child {
			float: right;
		}
	}

	&.filters-action-fixed {
		@include grid-column-gutter();
		bottom: 10px;
		float: none;
		left: 0;
		position: fixed;
		width: grid-column(12);
	}
}

//this shows only on mobile
.refine-search-btn-container {
	cursor: pointer;
	float: $global-left;
	width: grid-column(3.5);

	@include breakpoint(medium) {
		display: none;
	}
}

.refine-search-btn {
	@include form-select;
	@include setFontStyle($filterRefineSearchFontStyle);
	height: $filterBoxHeightMobile;
	margin-bottom: 0;

	@include breakpoint(medium) {
		display: none;
	}

	&.filter-search-close-btn {
		background-image: none;
		border: 0;
		display: block;
		padding: 0;
	}
}

@if ($filterBarAccordionEnabled) {
	.nav-dept {
		.filter-group {
			.filter-title {
				background: $filterOptionTitleBackground;
				border: $filterOptionTitleBorder;
				color: $filterOptionTitleColor;
				font-weight: $filterOptionTitleFontWeight;
				letter-spacing: $filterOptionTitleLetterSpacing;
				margin: $filterOptionTitleMargin;
				min-height: $filterOptionsTitleMinHeight;
				padding: $filterOptionTitlePadding;
				position: relative;
				text-align: $filterOptionTitleTextAlign;
				width: $filterOptionTitleWidth;

				@include breakpoint(medium) {
					font-weight: $filterOptionTitleMarginMedium;
				}

				&:active {
					background-color: $filter-title-background-color;
				}
				&::after {
					@include icomoonFont($filterAccordianExpandedStateIconfont);
					border: $filterAccordianExpandedStateIconBorder;
					font-size: em-calc($filterAccordianIconfontsize);
					line-height: $filterAccordianIconHeight;
					padding: $filterAccordianExpandedStateIconPadding;
					position: absolute;
					right: $filterAccordianIconRightPosition;
					top: $filterOptionsTitleIconTopPosition;
					transform: $filterOptionsTitleIconTransform;
					}
				}

				&.collapsed {
					padding: $filterGroupPadding;

					.filter-title {

						&::after {
							@include icomoonFont($filterAccordianCollapsedStateIconfont, false);
						}
					}

					.filter-options {
						max-height: $filterOptionCollapsedMaxHeight;
						opacity: $filterOptionCollapsedOpacity;
						overflow: $filterOptionCollapsedOverflow;
					}

					.filter-actions {
						display: none;
					}
				}

				&:not(.collapsed) {
					.filter-options {
						margin-top: $filter-options-margin-top;
					}
				}

			//Adding a transition here causes the sticky filters to overlap the footer during the duration of the transition
			.filter-options {
				max-height: $filterOptionMaxHeight;
				opacity: $filterOptionOpacity;
			}
		}
	}
}

.plp-sticky-sidebar {
	will-change: min-height;
}

.plp-sticky-sidebar__inner {
	transform: translate3d(0, 0, 0);
    will-change: position, transform;
}

@include breakpoint(medium) {
	.product-list-filters-section {
		.filter-search-close-btn {
			display: none;
		}
	}
}
