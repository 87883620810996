//Product Result Section
$no-results-recommendation-bottom-margin: 40px !default;
$catalog-results-product-block-width-medium: null !default;
$catalog-results-margin: null !default;

body {
	  //necessary to disable scroll anchoring in supported browsers for infinite scroll and load more button
	  overflow-anchor: none;
}

#catalog-container {

	float: right;

	@include grid-column(12);
	margin-top: 30px;

	@include breakpoint(medium) {
		@include grid-column($columns: 9);
		float: right;
		margin-top: 0;
	}
}

.catalog-results-small-set {
	.product-block {
		@extend %grid-block-height-reset;
		@include grid-column-gutter();
		float: none;
		width: grid-column(6);
		@include breakpoint(medium) {
			width: $catalog-results-product-block-width-medium;
		}
	}
}

//Style for catalog lanes layout
.lanes #catalog-container {
	width: 100%;
	margin: 0;
	padding: 0;
}

//Style for product results
// It seems that #catalog-results-no-nav is not used
#catalog-results,
#catalog-results-no-nav {
	margin: $catalog-results-margin;
	position: relative;
	text-align: left;
}


//This might not be in use
#catalog-container-no-nav {
  @include grid-column($columns: 12);
}

// This might not be in use
#catalog-results-no-nav {
	.product-block {
		@include grid-column(6);
		text-align: center;

	  	@include breakpoint(medium) {
	    	@include grid-column(4);
	  	}

	  	@include breakpoint(large) {
	    	@include grid-column($columns: 3);
	  	}

	  	.current-price {
	    	color: $priceFontColor;
	  	}

	  	.info {
	    	font-size: 0.8125em;
	    	line-height: 18px;
	    	margin: $productBlockInfoMargin 0 $productBlockMargin 0;
	  	}

		.badge {
  			color: $badgeFontColor;
  			font-weight: bold;
  			text-transform: uppercase;
		}

		.divider {
	      	color: $productNamePriceDividerFontColor;
	    }

	  	.name,
	  	.price {
	    	display: inline;
	  	}

	  	.price:before {
	    	content: '/';
	  	}
	}
}

.product-list-no-results-recommentation .catalog-results {
	margin-top: $no-results-recommendation-bottom-margin;
}
