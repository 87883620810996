@import 'catalog/view-more-results';

$viewMoreFontColor: $black;

.view-more-results {
    border-top: 0;
    border-bottom: 0;

    .view-all {
        border: 1px solid $light-gray;
        color: $viewMoreFontColor;
        cursor: pointer;
        display: block;
        padding: 12px;
        text-decoration: none;
        float: none !important;

         @include breakpoint(medium) {
            font-size: 14px;
            margin-bottom: 60px;
        }
    }
}
