//Authorized retailer feature
.feature-authorized-retailer {
	border: 0 none;
	padding-top: 0;

	.retailer-notification {
		color: $fontGreyDark;
		font-size: 0.8em;
		padding: 15px 20px;
		background-color: $backgroundColorUtility;
		border-top: $BGSectionSeperatorB;
		border-bottom: $BGSectionSeperatorB;
		margin-bottom: $pdpSpacingLarge;
	}
}
