//---------------------------------------------------------------------------
// unmade editor variables goes here
//---------------------------------------------------------------------------
$unmadeEditorCloseBtnTop: 10px !default;
$unmadeEditorCloseBtnRight: 14px !default;
$unmadeEditorCloseBtnMediumTop: 23px !default;
$unmadeEditorHeaderBorderColor: $light-gray !default;
$custmiseBtnBackgroundColor: $white !default;
$customiseBtnBorderColor: $black !default;
$customiseBtnFontColor: $customiseBtnBorderColor !default;
$pdpSecondaryImgHeight: 68px !default;
$pdpSecondaryImgWidth: 58px !default;
$customiseBtnLinearGradientDirection: 101deg !default;
$customiseIcon: url('../images/svg/customise-icon.svg');

$customiseBtnColorCodesList: #ff0000, #ee00ff, #1000ff, #00ffe9, #04ff00, #fff600, #ffa100 !default;

// Customise button linear gradient mixin
@mixin customise-btn-linear-gradient($direction, $list) {
  background-image: linear-gradient($direction, $list);
}
// Customise button mixin
@mixin customise-btn {
    @include base-button-styles;
    align-items: center;
    background-color: $custmiseBtnBackgroundColor;
    border: 1px solid $customiseBtnBorderColor;
    color: $customiseBtnFontColor;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    padding: 10px 35px;
    width: 100%;

    &:focus,
    &:hover {
        @include customise-btn-linear-gradient($customiseBtnLinearGradientDirection, $customiseBtnColorCodesList);
        animation: customiseBtnGradientKeyFrames 3s ease alternate infinite;
        background-origin: border-box;
        background-size: 150% 200%;
        border: 1px solid transparent;
        box-shadow: 2px 50px 1px $custmiseBtnBackgroundColor inset;
    }

    &-icon {
        background: transparent $customiseIcon center center/100% no-repeat;
        height: 24px;
        margin: 0 rem-calc(3);
        width: 24px;
    }

    span {
      font-size: inherit;

      &:last-child {
        transform: translateY(10%);
      }
    }
}

@keyframes customiseBtnGradientKeyFrames {
    0% {
      background-position: 0% 50%;
    }

    50% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0% 50%;
    }
}

.unmade-popup {
  padding: 0;
  z-index: z-index(reveal) + 2;
  @include breakpoint(medium) {
    padding: 1rem;
  }

  &-header {
    margin: 0;
    @include breakpoint(medium) {
      margin: 0 0 10px;
    }

    &-logo {
      background: transparent $store-logo center center/100% no-repeat;
      height: 44px;
      margin: 0 15px;
      width: 130px;
      @include breakpoint(medium) {
        margin: 0;
      }
    }

  }

  &-iframe-container {
    border-top: 1px solid $unmadeEditorHeaderBorderColor;
    height: calc(100% - 58px);
    width: 100%;
  }

  .unmade-popup-header-close {
    right: $unmadeEditorCloseBtnRight;
    top: $unmadeEditorCloseBtnTop;
    @include breakpoint(medium) {
      top: $unmadeEditorCloseBtnMediumTop;
    }
  }

}

// Cutsomise secondary button mixin
@mixin customise-secondary-btn($btnHeight: 30px, $iconWidth: 16px, $iconHeight: 16px) {
    align-items: center;
		display: flex;
		font-size: 1em;
		font-weight: bold;
		height: $btnHeight;
		justify-content: center;
		margin: 10px 0 0 0;

		&-icon {
			background: transparent $customiseIcon center center/100% no-repeat;
			height: $iconHeight;
			margin: 0 3px 2px 0;
			width: $iconWidth;
		}
}

.product-template-container .pdp-unmade-custom-img {
  height: 100%;
  width: 100%;
}

.pdp-custom-template-container .bl-hover-element {
  display: none;
}

.pdp-custom-preview {
  &-container {
   display: flex;
   margin: 5px 0;
  }

  &-left-container {
    width: $pdpSecondaryImgWidth;

    img {
      cursor: pointer;
      height: $pdpSecondaryImgHeight;
      margin: 5px 0;
      min-width: $pdpSecondaryImgWidth;

      &:focus,
      &:hover,
      &.selected {
        border: 1px solid get-color(napa-orange);
      }
    }
}
}




