// Grid Header

.grid-header {
  padding-left: $column-gutter/2;
  padding-right: $column-gutter/2;

  @include clearfix;

  .no-header-hero {
    margin-bottom: 40px;
    padding-left: 50px;
    width: 100%;

    @include breakpoint(medium) {
      width: auto;
    }
  }

  .header-result-counter {
    font-size: $global-font-size;

    h1 {
      display: inline-block;
      font-size: em-calc(18px);
      margin-bottom: 8px;

      @include breakpoint(medium) {
        margin-bottom: 0;
      }
    }

    .items {
      display: block;
      font-size: em-calc(12px);

      @include breakpoint(medium) {
        display: inline;
        font-size: em-calc(18px);

        &::before {
          content: ' - ';
        }
      }
    }
  }

  .breadcrumb-facet-list {
    display: none;
  }
}

.product-list {
  .no-header-hero {
    margin-bottom: $column-gutter/2;
    padding-left: 0;

    @include breakpoint(medium) {
      margin-bottom: 10px;
    }

    @include breakpoint(large) {
      margin-bottom: 40px;
    }
  }
}
