$productLaneEnabled: false !default;
$productListHeaderImageFloat: none !important !default;
$viewAllContainerTopBorder: 2px solid $brandColorPalette7 !default;
$productLaneBorder: 2px solid $brandColorPalette7 !default;
$productLaneTitleBorder: 1px solid $brandColorPalette7 !default;
$productLaneTitlePadding: 8px !default;
$productLaneIndicatorRightColor: inherit !default;
$productLaneFooterMarginTop: 50px !default;
$productLaneFooterMarginBottom: 50px !default;
$viewAllContainerPaddingTop: null !default;
$productColumnsInStackedCategory: 3 !default;
$productColumnsInStackedCategoryTablet: 6 !default;
$productColumnsInStackedCategoryMobile: 6 !default;
$productCatalogContainerPadding: null !default;
$productCatalogContainerPaddingMedium: null !default;
$product-block-views-container-skeleton-width: 100% !default;
$product-block-views-container-skeleton-height: null !default;
$product-block-views-container-skeleton-display: null !default;
$product-block-views-container-skeleton-background: null !default;

@if ($productLaneEnabled) {

	.grid.lanes {
		#product-listing {
			@include breakpoint(medium) {
				&:not(.view-layout-switch-grid) {
					.product-block-views-container {
						background: $product-block-views-container-skeleton-background;
						display: $product-block-views-container-skeleton-display;
						min-height: $product-block-views-container-skeleton-height;
						min-width: $product-block-views-container-skeleton-width;
					}
				}
			}
		}

		.page-contents {
			@include grid-row();
			padding-top: 0;
		}

		#product-list,
		#product-listing,
		#catalog-results {
			width: 100%;
		}

		#product-list-header-content {
			@include grid-row();

			.header-section {
				display: none;

				@include breakpoint(medium) {
					@include grid-column(12, 0);
					display: block;

					&,
        			&:last-child:not(:first-child) {
						float: $productListHeaderImageFloat;
        			}
				}

				@include breakpoint(large) {
					@include grid-column(12);
				}
			}
		}

		#catalog-container {
			float: right;
			width: 100%;
			padding: $productCatalogContainerPadding;

			@include breakpoint(medium) {
				padding: $productCatalogContainerPaddingMedium;
			}

			#catalog-form {
				margin: 0 0 4px;
				width: 100%;
			}
		}

		.page-banner {
			@include grid-row();
		}
	}

	.dept-lanes {
		.product-block {
			.product-actions {
				display: none;
			}
		}
	}

	// applied to stacked-category template.//
	.stacked-category {
		#catalog-results {
			.product-block {
				@include grid-column($columns: $productColumnsInStackedCategoryMobile);

				@include breakpoint(medium) {
					@include grid-column($columns: $productColumnsInStackedCategoryTablet);
				}

				@include breakpoint(large) {
					@include grid-column($columns: $productColumnsInStackedCategory);
				}
			}
		}
	}

	.dept-lanes,
	.cat-lanes #catalog-form {
		.view-all-container {
			@include grid-row;
			border-top: $viewAllContainerTopBorder;
			clear: both;
			padding-top: $viewAllContainerPaddingTop;
			text-align: center;
		}

		.product-lane-footer {
			margin-bottom: $productLaneFooterMarginBottom;
			margin-top: $productLaneFooterMarginTop;
			text-align: center;
		}
	}

	.no-lanes {
		#catalog-container {
			#catalog-form {
				margin: 0;
			}
		}

		.product-block.lanes {
			margin-bottom: 6px;
			margin-right: -4px;
			margin-top: 28px;

			.product-actions {
				margin-bottom: 0;
			}
		}
	}


	.product-lane {
		@include grid-column(12);
		margin-bottom: 0;

		@include breakpoint(medium) {
			margin-bottom: 14px;
		}

		header {
			@include grid-column(12);

			@include breakpoint(large) {
				@include grid-column(12, 0);
			}
		}

		.lane-divider {
			border-top: $productLaneBorder;
			float: left;
			height: 2px;
			margin-bottom: 19px;
			width: 100%;
		}

		.lane-header {
			border-bottom: $productLaneTitleBorder;
			float: left;
			padding-bottom: $productLaneTitlePadding;

			@include breakpoint(medium) {
				border-bottom: 0 transparent;
				float: none;
			}
		}

		.title-divider {
			border-bottom: $productLaneTitleBorder;
		}

		.featured-title {
			@extend .lane-header;
			display: inline;
		}

		.lane-title {
			float: left;
			width: auto;

			@include breakpoint(medium) {
				display: inline-block;
				float: none;
			}
		}

		.lane-sub-title {
			@include setFontStyle($productLaneFontStyle);
			float: left;
			margin-top: 9px;

			@include breakpoint(medium) {
				float: none;
			}
		}

		.view-all {
			@include setFontStyle($productLaneViewAllFontStyle);
			background: none;
			color: $productLaneIndicatorRightColor;
			float: left;
			padding-left: 4px;
			padding-top: 10px;

			@include breakpoint(medium) {
				float: none;
				font-size: em-calc(17);
				padding-top: 0;
			}

			&:hover {
				color: #000;
			}
		}

		.product-lane-arrow {
			float: left;
			font-size: 11px;
			font-weight: bold;
			padding-top: 10px;

			@include breakpoint(medium) {
				float: none;
				padding-top: 0;
			}
		}

		&.first {
			.lane-divider {
				border-top: 0;
			}
		}

		.has-narrow-espot {

			@include breakpoint(medium) {
				.product-3 {
					display: none !important; //for overriding the inline stlyle
				}

			}

			@include breakpoint(large) {
				.product-2,
				.product-3 {
					display: block !important; //for overriding the inline stlyle
				}

			}

			.espot-block {
				@include grid-column(6);

				@include breakpoint(medium) {
					@include grid-column(4);
				}

				@include breakpoint(large) {
					@include grid-column(3);
				}
			}

			.product-2 {
				display: none !important; //for overriding the inline stlyle

				@include breakpoint(medium) {
						display: block !important; //for overriding the inline stlyle
				}
			}

			.product-3 {
				display: none !important; //for overriding the inline stlyle

				@include breakpoint(large) {
					display: block !important; //for overriding the inline stlyle
				}
			}
		}

		.has-wide-espot {

			.product-2 {
				display: block;

				@include breakpoint(medium) {
						display: none !important; //for overriding the inline stlyle
				}

				@include breakpoint(large) {
					display: block;
				}
			}

			.espot-block {
				display: none;

				@include breakpoint(medium) {
						@include grid-column(8);
				}

				@include breakpoint(large) {
					@include grid-column(6);
					display: block;
				}

			}
		}

		.espot-block.wide {
			@include grid-column(6);
		}

		.espot-block .content-wrapper {
			padding-top: 14px;
		}
	}
}
