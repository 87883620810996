//Product Block Color Swatches
$productBlockColorSwatchWidth: 18.5% !default;
$productBlockColorSwatchWidthMedium: null !default;
$productBlockColorSwatchContainerWidth: 90% !default;
$productBlockColorSwatchContainerWidthMobile: 95% !default;
$productBlockColorSwatchesContainerDisplay: null !default;
$productBlockColorSwatchesContainerFlexWrap: null !default;
$productBlockColorSwatchesContainerJustifyContent: null !default;
$productBlockColorSwatchesContainerJustifyContentDesktop: null !default;
$productBlockColorSwatchHeight: 25px !default;
$productBlockColorSwatchHeightMobile: 19px !default;
$productBlockColorSwatchHeightMobileSmall: 15px !default;
$productBlockColorSwatchMarginDesktop: null !default;
$productBlockColorSwatchDisplayDesktop: null !default;
$productBlockColorSwatchInnerBorderWidth: null !default;
$productBlockColorSwatchInnerBorderWidthMobile: null !default;
$productBlockColorSwatchGutter: 1.45% !default;
$productBlockColorSwatchSelectedBorder: null !default;
$productBlockColorSwatchHoverBorder: null !default;
$productBlockColorSwatchGutterMobile: 1.5% !default;
$productBlockColorSwatchesMarginBottom: 0 !default;
$productBlockColorSwatchMarginLeft: null !default;
$productBlockColorSwatchPositionMobile: absolute !default;
$productBlockHasMoreColorSwatchWidth: 23.5% !default;
$productBlockHasMoreColorSwatchWidthMobile: 23% !default;
$productBlockMoreSwatchWidth: 19% !default;
$productBlockMoreSwatchWidthMobile: 19% !default;
$productBlockMoreSwatchesPaddingTop: 2px !default;
$productBlockMoreSwatchBorder: 1px solid $brandColorPalette3 !default;
$productBlockMoreSwatchBackgroundColor: transparent !default;
$productBlockHasShowMoreContainerBGColor: #fff !default;
$productBlockHasShowMoreContainerJustifyContent: null !default;
$productBlockHasShowMoreContainerMargin: null !default;
$productBlockHasShowMoreContainerMarginMedium: null !default;
$productBlockHasShowMoreContainerMaxWidth: 81% !default;
$productBlockHasShowMoreContainerMaxWidthMedium: null !default;
$productBlockHasShowMoreContainerprecedingText: '+' !default;
$productBlockHasShowMoreContainerprecedingExpandedText: '-' !default;
$colorSwatchesEnabled: false !default;
$colorSwatchesHoverStateEnabled: false !default;
$productBlockColorSwatchBorder: null !default;
$centerAlignSwatches: false !default;

//Diagonal Color Swatches
$colorSwatchesDiagonalDisplay: false !default; // set true to enable diagonal swatches
$colorSwatchesDiagonalRotation: -45deg !default; // 45deg for square swatches, adjust as needed for rectangular
$colorSwatchesDiagonalOffset: 1/3*100% !default; // offset for 1st and 3rd colors, default to equal 3 parts
$colorSwatchesDiagonalWidth: 150% !default; // oversize the width to cover the area when rotated
$colorSwatchesDiagonalColor1Origin: bottom left !default; // origin of rotation for color 1
$colorSwatchesDiagonalColor3Origin: top right !default; // origin of rotation for color 3


// Enable diagonal display when 2 or 3 colors are shown. The 1st and 3rd colors are
// positioned above the 2nd color and then rotated.
// - For 2 colors, the 1st (rotated) color should be aligned to the middle of the swatch
//   and the angle adjusted to match the swatches proportions.
// - For 3 colors, the 1st and 3rd (rotated) colors are offset equally to reveal
//   the 2nd (non-rotated) color.
//
// ** For best result make color swatches square
@mixin setDiagonalColorSwatches() {
	.inner-border {
		overflow: hidden;
		position: relative;
	}

	.two-color,
	.three-color {
		.inner-border {
			// rotate 1st and 3rd colors and position above 2nd color
			:nth-child(1),
			:nth-child(3) {
				position: absolute;
				transform: rotate($colorSwatchesDiagonalRotation);
				width: $colorSwatchesDiagonalWidth;
			}

			// set transform on color1
			:nth-child(1) {
				transform-origin: $colorSwatchesDiagonalColor1Origin;
			}

			// set transform on color3
			:nth-child(3) {
				transform-origin: $colorSwatchesDiagonalColor3Origin;
			}

			// color 2 always full-width
			:nth-child(2) {
				width: 100%;
			}
		}
	}

	// when 3 colors offset 1st and 3rd equally
	.three-color {
		.inner-border {
			:nth-child(1) {
				left: -$colorSwatchesDiagonalOffset;
			}

			:nth-child(3) {
				right: -$colorSwatchesDiagonalOffset;
			}
		}
	}
}

@if ($colorSwatchesEnabled) {
	.color-swatches {
		cursor: pointer;
		height: $productBlockColorSwatchHeightMobile;
		margin: 0 auto $productBlockColorSwatchesMarginBottom;
		overflow: hidden;
		position: relative;
		width: $productBlockColorSwatchContainerWidthMobile;
		z-index: 10;

		&.show-more {
			overflow: visible;
		}

		.swatches-container {
			display: $productBlockColorSwatchesContainerDisplay;
			flex-wrap: $productBlockColorSwatchesContainerFlexWrap;
			justify-content: $productBlockColorSwatchesContainerJustifyContent;
			width: 100%;
			@include breakpoint(large) {
				justify-content: $productBlockColorSwatchesContainerJustifyContentDesktop;
			}
		}

		@include breakpoint(medium) {
			height: $productBlockColorSwatchHeight;
			width: $productBlockColorSwatchContainerWidth;
		}

	}

	@if ($centerAlignSwatches) {
		.doesnt-have-more,
		.single-row {
			.swatches-container {
				display: flex;
				justify-content: center;
			}
		}
	}

	.swatches-container {
		height: $productBlockColorSwatchHeightMobile;
		margin: 0 auto;
		position: absolute;

		@include breakpoint(medium) {
			height: auto;
		}

		@include breakpoint(large) {
			position: $productBlockColorSwatchDisplayDesktop;
			margin: $productBlockColorSwatchMarginDesktop;
		}

	}

	.product-block-color-swatches.show-more {
		.more-swatches {
		  span {
			&::before {
			  content: $productBlockHasShowMoreContainerprecedingExpandedText;
			}
		  }
		}
	  }
	  
	//over-writing above property when isProductGridSwatchesExpansionEnabled is enable in vf-strore config.
	.swatches-expansion-enabled {

		.swatches-container {
			position: relative;
		}

		.more-swatches {
			span {
				&::before {
					content: $productBlockHasShowMoreContainerprecedingExpandedText;
				}
			}
		}
	}

	.swatch {
		box-sizing: border-box;
		float: left;
		height: $productBlockColorSwatchHeightMobile;
		margin-bottom: $productBlockColorSwatchGutter;
		margin-right: $productBlockColorSwatchGutterMobile;
		overflow: hidden;
		width: $productBlockColorSwatchWidth;

    .doesnt-have-more & {
      margin-left: $productBlockColorSwatchMarginLeft;
    }

		img {
			vertical-align: top;
		}

		.inner-border {
			display: block;
			height: 100%;
			text-indent: -9999px;

			// since the swatch width above needs to be percentage based, this can be used to
			// set a strict width on the inner content an not break the View More functionality.
			// Ex: make swatches square
			width: $productBlockColorSwatchInnerBorderWidthMobile;

			span {
				display: block;
				float: left;
				height: $productBlockColorSwatchHeightMobile;
			}
		}

		@include breakpoint(medium) {
			height: $productBlockColorSwatchHeight;
			margin-right: $productBlockColorSwatchGutter;
			width: $productBlockColorSwatchWidthMedium;

			.inner-border {
				width: $productBlockColorSwatchInnerBorderWidth;

				span {
					height: $productBlockColorSwatchHeight;
				}
			}
		}

		@if ($colorSwatchesHoverStateEnabled) {
			&:hover {
				border: $productBlockColorSwatchHoverBorder;
			}

			&.selected {
				border: $productBlockColorSwatchSelectedBorder;
			}
		}
	}


	@if($colorSwatchesDiagonalDisplay) {
		@include setDiagonalColorSwatches;
	} @else {
		.two-color .inner-border span {
			width: 50%;
		}

		.three-color .inner-border span {
			width: 33.333%;
		}
	}

	.more-swatches {
		background: $productBlockMoreSwatchBackgroundColor;
		border: $productBlockMoreSwatchBorder;
		display: none;
		float: left;
		height: $productBlockColorSwatchHeightMobile;
		line-height: $productBlockColorSwatchHeightMobile;
		position: $productBlockColorSwatchPositionMobile;
		right: 0;
		text-align: center;
		width: $productBlockMoreSwatchWidthMobile;

		@include breakpoint(medium) {
			height: $productBlockColorSwatchHeight;
			line-height: $productBlockColorSwatchHeight;
			padding-top: 0;
			width: $productBlockMoreSwatchWidth;
		}

		span {
			@include setFontStyle($productInfoColorSwatchFontStyle);
			display: block;
			height: 100%;
			width: 100%;

			&::before {
				content: $productBlockHasShowMoreContainerprecedingText;
			}
		}
	}


	.has-show-more {
		.swatches-container {
			background-color: $productBlockHasShowMoreContainerBGColor;
			float: left;
			height: auto;
			margin: $productBlockHasShowMoreContainerMargin;
			max-width: $productBlockHasShowMoreContainerMaxWidth;
			justify-content: $productBlockHasShowMoreContainerJustifyContent;

			@include breakpoint(medium) {
				margin: $productBlockHasShowMoreContainerMarginMedium;
				max-width: $productBlockHasShowMoreContainerMaxWidthMedium;
			}
		}

		.swatch {
			width: $productBlockHasMoreColorSwatchWidthMobile;

			@include breakpoint(medium) {
				width: $productBlockHasMoreColorSwatchWidth;
			}
		}

		img {
			border: $productBlockColorSwatchBorder;
		}

		.more-swatches {
			display: block;
		}
	}

	.store-results-list { // find in a store color swatches
		.color-swatches {
			margin-left: 2px;
			margin-top: 2px;
			overflow: visible;
			width: 100%;
		}

		.swatch {
			margin-bottom: 10px;
			margin-right: 10px;
			max-width: 45px;
			overflow: hidden;

			&:not(.one-color):not(.two-color):not(.three-color) {
				width: inherit;
			}
		}

		.swatch.selected {
			outline: 2px solid $brandColorPalette1;
		}
	}
}
