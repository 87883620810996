@import 'catalog/product-lane';

@if ($productLaneEnabled) {

  .product-lane {
    border-top: 0;

    &-search-group-header {
      overflow: hidden;

      .lane-title {
        float: left;
        font-size: 24px;
      }

      .lane-header {
        float: right;

        .product-lane--view-all {
          font-size: 14px;
          font-weight: bold;
          text-decoration: none;
          text-transform: lowercase;
        }

        .product-lane-arrow {
          color: get-color(burnt-orange);
          font-size: 21px;
          position: relative;
          top: 4px;

          &::before {
            @include icomoonFont($icon-arrow-right);
          }
        }
      }
    }
  }

  .grid.lanes {
    header {
      padding: 0 !important; //overwrite gutter.scss padding
    }
    
    #product-list-header-content {
      padding: 0 $column-gutter rem-calc(18) $column-gutter;

      @include breakpoint(large) {
        padding: 0 $column-gutter 0 $column-gutter;
      }

      h1,
      span {
        font-size: rem-calc(16);
        font-weight: normal;
        line-height: rem-calc(20);
        margin-bottom: 0;
        margin-left: rem-calc(-8);
        width: 72%;

        @include breakpoint(large) {
          display: inline-block;
          font-size: rem-calc(26);
          font-weight: 600;
          line-height: rem-calc(32);
          margin-bottom: $header-margin-bottom;
          margin-left: 0;
          margin-right: rem-calc(10);
          margin-top: 0;
          padding: rem-calc(10) 0;
          width: fit-content;
        }
      }
    }

    header {
      @include grid-column(12);
      font-size: rem-calc(18);
      margin-bottom: $column-gutter;

      .product-lane-header {
        text-decoration: none;
      }

      .product-lane-title {
        font-size: rem-calc(18);
      }

      .items {
        display: none;
      }

      @include breakpoint(medium) {
        header {
          margin-bottom: 30px;
        }

        .items {
          display: inline-block;
        }
      }
    }

    @include breakpoint(medium) {
      .items::before {
        content: ' - ';
      }
    }
    @include breakpoint(large) {
        padding:0;
    }
  }

  .stacked-category {
    #catalog-results {
      .product-block,
      .product-block:last-child:not(:first-child) {
        float: none;
      }
    }
  }
}
