.search-exact-match {
	.view {
		@extend %exact-match-content;
	}

	.info {
		@extend %exact-match-content;
	}

	figure,
	.content {
        display: inline-block;
        vertical-align: middle;
        width: 100%;
	}

	.content {
    	padding: 0px 30px;
	}
}
