@import 'util/common-mixins';

$include-html-top-bar-filters-classes: $include-html-classes !default;

$active-facets-height: null !default;
$active-facets-letter-spacing: null !default;
$active-facets-margin-bottom: 0 !default;
$active-filters-background: $white !default;
$active-filters-border-radius: null !default;
$active-filters-border: 1px solid get-color(very-light-grey) !default;
$active-filters-list-max-height: 45px !default;
$active-filters-list-display: null !default;
$active-filters-container-background: get-color(white-smoke-2) !default;
$facet-menu-sort-font-color: null !default;
$facet-menu-sort-font-family: null !default;
$facets-animate-time: 0.50s !default;
$facets-main-font-family: null !default;
$facets-main-font-family_medium: null !default;
$filter-border: 1px solid get-color(very-light-grey) !default;
$filter-color: null !default;
$filter-display: null !default;
$filter-menu-title-font-size: null !default;
$filter-menu-title-font-size-medium: null !default;
$filter-menu-title-font-size-large: null !default;
$filter-menu-title-font-size-xlarge: null !default;
$filter-menu-active-title-text: get-color(primary) !default;
$filter-menu-checkbox-container-margin: null !default;
$filter-menu-checkbox-container-justify-content: null !default;
$filter-menu-title-outline: null !default;
$filter-menu-title-text-align: null !default;
$filter-menu-background: $white !default;
$filter-menu-header-background: $white !default;
$filter-option-display: null !default;
$filter-option-checkbox-height: null !default;
$filter-option-checkbox-width: null !default;
$filter-option-height-large-screen: 30px !default;
$filter-option-line-height-large-screen: $filter-option-height-large-screen !default;
$filter-option-margin-large-screen: null !default;
$filter-option-height: 40px !default;
$filter-option-opacity: null !default;
$sort-filter-font-family-medium: null !default;
$filter-option-position: null !default;
$filter-option-text-height-medium-screen: 18px !default;
$filter-small-screen-container-background: $white !default;
$filter-small-screen-header-height: 50px !default;
$filter-small-screen-options-max-height: null !default;
$left-facet-sort-bar-height: 40px !default;
$left-facet-width: 25% !default;
$top-bar-filter-facet-margin-top: 1em !default;
$top-bar-filter-facet-min-height: null !default;
$top-bar-filter-facet-Zindex: z-index(topbarfilter) !default;
$top-bar-sub-menu-option-background-color: get-color(white-smoke-3) !default;
$filter-option-height-disabled-color: get-color(dark-gray) !default;
$top-bar-sub-menu-margin: null !default;
$top-bar-sub-menu-width: null !default;
$top-bar-sub-menu-min-width: null !default;
$top-bar-sub-menu-min-width-xlarge: null !default;
$top-bar-sub-menu-max-height: null !default;
$top-bar-sub-menu-4-facets-width: grid-column(20%) !default;
$filter-sub-menu-filter-container-active-width-large: null !default;
$filter-sub-menu-filter-container-border-large: null !default;
$filter-menu-header-active-background: null !default;
$filter-menu-header-active-border: null !default;
$filter-menu-header-active-border-bottom: $filter-border !default;
$filter-menu-header-active-padding-top: null !default;
$filter-sort-menu-white-space: null !default;
$filter-sub-menu-filter-container-active-margin-top: null !default;
$top-bar-sub-menu-option-border-bottom: null !default;
$top-bar-sub-menu-option-span-margin-right: $column-gutter/4 !default;
$top-bar-sub-menu-option-span-padding-left: null !default;
$top-bar-sub-menu-option-label-text--num-products-padding: null !default;
$filter-padding: null !default;
$filter-z-index: null !default;
$filter-float: null !default;
$filter-action-padding: null !default;
$filter-sub-menu-actions-clear-filter-padding: null !default;
$filter-sub-menu-actions-clear-filter-margin-left: null !default;
$filter-sub-menu-actions-clear-filter-font-size: null !default;
$filter-sub-menu-actions-clear-filter-font-weight: null !default;
$filter-sub-menu-actions-clear-filter-letter-spacing: null !default;
$filter-sub-menu-actions-clear-filter-line-height: null !default;
$filter-sub-menu-actions-clear-filter-margin-top: null !default;
$filter-sub-menu-actions-action-apply-padding-medium: null !default;
$filter-sub-menu-actions-action-apply-width: null !default;
$filter-sub-menu-actions-action-apply-width-medium: null !default;
$filter-sub-menu-actions-button-primary-letter-spacing: null !default;
$filter-sub-menu-actions-button-primary-height: null !default;
$filter-sub-menu-actions-button-primary-margin-top: null !default;
$filter-sub-menu-actions-button-primary-medium-font-size: null !default;
$filter-sub-menu-actions-button-primary-medium-font-weight: null !default;
$filter-sub-menu-actions-button-primary-medium-margin-bottom: null !default;
$filter-sub-menu-actions-button-primary-medium-margin-top: null !default;
$filter-sub-menu-actions-button-primary-medium-height: null !default;
$facet-sub-menu-actions-align-items: null !default;
$filter-sub-menu-option-label-font-size: null !default;
$facet-sub-menu-actions-display: null !default;
$facet-sub-menu-actions-padding-bottom: null !default;
$facet-sub-menu-actions-padding-top: null !default;
$facet-sub-menu-actions-width: null !default;
$filter-sub-menu-actions-margin-top: null !default;
$filter-sub-menu-actions-display: null !default;
$filter-menu-header-active-margin-large: null !default;
$filter-sub-menu-filter-container-active-margin-top-large: null !default;
$filter-sub-menu-filter-container-active-index-large: -1 !default;
$filter-sub-menu-actions-clear-filter-background: null !default;
$filter-sub-menu-actions-clear-filter-color: null !default;
$filter-sub-menu-actions-mobile-background: $white !default;
$filter-sub-menu-actions-mobile-padding: 1em 0 !default;
$filter-option-selected-color-swatch-display: null !default;
$filter-option-color-swatch-display: null !default;
$product-sort-radio-select-margin-top: rem-calc(24) !default;
$active-filters-clear-all-link-color: null !default;
$active-filters-clear-all-link-font-size: em-calc(16) !default;
$active-filters-clear-all-link-weight: null !default;
$filter-sub-menu-option-label-align-items: null !default;
$filter-label-flex-centered-span-max-width-medium: null !default;
$filter-label-flex-centered-span-width-medium: null !default;
$top-bar-sub-menu-option-label-text-font-weight: null !default;
$top-bar-sub-menu-option-label-text-text-transform-first: null !default;
$top-bar-sub-menu-option-label-text-text-transform-second: null !default;
$stuck-to-top-active-filters-display-small: null !default;
$stuck-to-top-active-filters-display-large: null !default;
$topnav-sticky-minimized--height__large: $topnav-sticky-minimized--height__medium !default;
$stuck-to-top-active-filters-display-medium: $stuck-to-top-active-filters-display-large !default;
$filter-option-checked-after-content: null !default;
$filter-menu-checkbox-container-padding-left: null !default;
$plp-sticky-sidebar__inner-transform: none !default;
$plp-sticky-sidebar__inner-will-change: unset !default;
$filter-menu__sub-menu-medium-width: null !default;

@mixin filtersResets {

	ul {

		li {
			list-style-type: none;
		}
	}

}

@mixin filterMobileContainer {
	.filter-mobile-container {
		background: $filter-small-screen-container-background;
		overflow: hidden;
		padding-top: $filter-small-screen-header-height;
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 20;
	}
}

@mixin filterMenu {

	.filter-menu {

		&-selector {
			align-content: center;
			border-top: $filter-border;
			cursor: pointer;
			display: flex;
			flex-direction: row;
			justify-content: center;
			line-height: $filter-option-height;
			text-align: center;

			&__sort-icon::before,
			&__filter-icon::before {
				font-size: 20px;
				line-height: $filter-option-height;
				margin-left: 10px;
			}

			&:first-child {
				border-right: $filter-border;
			}
		}

		&-container {
			margin: 0;
			@include clearfix;
		}

		&__title-icon {
			color: $dark-gray;

			&.icon-minus {
				&::before {
					@include icomoonFont($icon-arrow-up-indicator-open);
				}
			}

			&.icon-plus {
				&::before {
					@include icomoonFont($icon-arrow-down-indicator-open);
				}
			}
		}

		// .filter-menu-header
		&-header {
			@include clearfix;
			background: $white;
			border-bottom: $filter-border;

			&__type {
				float: left;
				width: grid-column(10);
			}

			&__close-btn {
				float: right;
				position: relative;
				width: grid-column(2);

				span {
					cursor: pointer;
					line-height: $filter-small-screen-header-height;
					position: absolute;
					right: 10px;
				}
			}

			&--open {
				background: $filter-menu-background;
				border-bottom: $filter-border;
				font-family: $facets-main-font-family_medium;
				font-size: 24px;
				height: 51px;
				line-height: $filter-small-screen-header-height;
				padding-left: 12px;
				position: fixed;
				top: 95px;
				width: 100%;
				z-index: z-index(topnav) + 1;

				@include breakpoint(medium) {
					height: 0;
					padding-left: 17px;
					top: 0;
				}
			}
		}

		// .filter-menu__sub-menu
		&__sub-menu {
			@include breakpoint(large) {
				background: $filter-menu-background;
				padding-left: 0;
				padding-right: 0;
				margin: $top-bar-sub-menu-margin;
				z-index: z-index(filtersubmenu);
				position: relative;
				width: $top-bar-sub-menu-width;
				min-width: $top-bar-sub-menu-min-width;
				height: $top-bar-sub-menu-max-height;

				@include breakpoint(xlarge) {
					min-width: $top-bar-sub-menu-min-width-xlarge;
				}

				&.active {
					z-index: z-index(filtersubmenu) + 1;
				}

				&.medium-1-of-5 {
					width: $top-bar-sub-menu-4-facets-width;
				}

			}
			@include breakpoint(medium) {
				&.medium-2 {
					width: $filter-menu__sub-menu-medium-width;
				}
			}
		}

		&__sub-menu-header {
			@include clearfix;
			background: $filter-menu-header-background;
			border-top: $filter-border;
			color: $filter-color;
			cursor: pointer;
			display: $filter-display;
			outline: $filter-menu-title-outline;
			padding: 10px 0;

			.filter-menu__title-text {
				font-size: $filter-menu-title-font-size;
				text-align: $filter-menu-title-text-align;
				transition: all 0.35s;

				@include breakpoint(medium) {
					font-size: $filter-menu-title-font-size-medium;
				}

				@include breakpoint(large) {
					font-size: $filter-menu-title-font-size-large;
				}

				@include breakpoint(xlarge) {
					font-size: $filter-menu-title-font-size-xlarge;
				}
			}

			&.active {
				border: $filter-menu-header-active-border;
				border-bottom: $filter-menu-header-active-border-bottom;
				background: $filter-menu-header-active-background;
				margin: $filter-menu-header-active-margin-large;
				padding-top: $filter-menu-header-active-padding-top;

				.filter-menu__title-text {
					color: $filter-menu-active-title-text;
				}
			}

			&.filter-sort-menu {
				border-right: 0;
				white-space: $filter-sort-menu-white-space;
			}

			@include breakpoint(large) {
				border: $filter-border;
				border-left: 0;
			}
		}

		&__mobile-active-filters {
			display: block;
			padding-top: 0.5em;

			@include breakpoint(large) {
				display: none;
			}

		}

		&__mobile-active-filters--active-filter {
			color: get-color(secondary);
			display: inline-block;
			font-family: $facets-main-font-family;
			line-height: 1.2;
			max-width: 130px;
			overflow-x: hidden;
			text-overflow: ellipsis;
			text-transform: none;
			white-space: nowrap;
		}

		&__title-icon {
			transition: all 1s ease-in-out;

			&::before {
				position: absolute;
				right: $column-gutter/2;
			}
		}

		&__sub-menu-options {
			background: $white;
			clear: both;
			z-index: 11; // above color swatches on PLP product blocks

		    @include breakpoint(large) {
		    	border: $filter-border;
				border-top: 0;
		    	position: absolute;
		    	left: -1px;
		    	padding-bottom: $column-gutter/2;
		    	padding-top: $column-gutter/2;
		    	top: $filter-option-height;
		    	width: calc(100% + 1px);
		    	z-index: -1;
		    }

		}

		&__sub-menu--filter-container {
			max-height: 0;
			overflow: hidden;
			transition: max-height 0.5s;

			&.active {
				max-height: $filter-small-screen-options-max-height;
				overflow-y: auto;
				transition: max-height 1s;
			}

			@include breakpoint(large) {
				border: $filter-sub-menu-filter-container-border-large;
				display: none;
				transition: none;

				&.active {
					display: block;
					max-height: $filter-small-screen-options-max-height;
					transition: none;
					width: $filter-sub-menu-filter-container-active-width-large;
					margin-top: $filter-sub-menu-filter-container-active-margin-top-large;
					z-index: $filter-sub-menu-filter-container-active-index-large;
				}
			}
		}

		&__sub-menu--sort-container {
			padding-top: 0;

			@include breakpoint(large) {
				display: none;
				padding-top: $column-gutter/2;

				&.active {
					display: block;
					overflow: hidden;
				}
			}
		}

	}
}

@mixin filterSubMenuOptions {
	.sub-menu-options {
		background-color: $top-bar-sub-menu-option-background-color;
		border-bottom: $top-bar-sub-menu-option-border-bottom;
		margin-bottom: -1px;
		margin-left: 0;

		@include breakpoint(large) {
			background-color: transparent;
		}

		&__selection {

			@include clearfix;
			border-top: $filter-border;
			cursor: pointer;

			&:first-child {
				border-top: 0;
			}

			span {
				float: left;
				margin-right: $top-bar-sub-menu-option-span-margin-right;
				padding-left: $top-bar-sub-menu-option-span-padding-left;

				&.sub-menu-options__label-text {
					font-weight: $top-bar-sub-menu-option-label-text-font-weight;
					text-transform: $top-bar-sub-menu-option-label-text-text-transform-second;
					&:first-letter {
						text-transform: $top-bar-sub-menu-option-label-text-text-transform-first;
					}
				}

				&.sub-menu-options__label-text--num-products {
				padding: $top-bar-sub-menu-option-label-text--num-products-padding;
				}
			}

			@include breakpoint(large) {
				border: 0;
                display: flex;
                align-items: center;
                margin-bottom: 0.5em;
			}

      .selected {
        .sub-menu-options__color-swatch {
          @include filtersColorSwatch(30px);
		  display: $filter-option-selected-color-swatch-display;
        }
      }

		}

		&__label {
			align-items: center;
			cursor: pointer;
			display: flex;
		    flex-direction: row;
		    float: right;
			padding-left: 0;
			width: grid-column(10.5);

			@include breakpoint(large) {
				align-items: $filter-sub-menu-option-label-align-items;
				font-size: $filter-sub-menu-option-label-font-size;
				width: grid-column(9.5);
			}
		}

		&__checkbox-container {
			float: left;
			justify-content: $filter-menu-checkbox-container-justify-content;
			margin: $filter-menu-checkbox-container-margin;
			width: 100%;
			padding-left: $filter-menu-checkbox-container-padding-left;
		}

		&__checkbox,
		&__checkbox input[type="checkbox"] {
			display: $filter-option-display;
			height: $filter-option-checkbox-height;
			margin-bottom: 0;
			opacity: $filter-option-opacity;
			position: $filter-option-position;
			width: $filter-option-checkbox-width;
		}

		&__checkbox {
			&:checked::after {
				content: $filter-option-checked-after-content;
			}
			&:focus {
				+ span {
					outline: $defaultFocusColor auto 1px;
				}
			}
		}

		&__label-text,
		&__color-swatch {
			display: inline-block;
			line-height: $filter-option-height;
			padding: 0 0.25em 0 0;

			&.disabled {
				color: $filter-option-height-disabled-color;
			}
		}

		&__color-swatch {
			@include filtersColorSwatch;
			display: $filter-option-color-swatch-display;
			margin-right: 1em;
		}

        @include breakpoint(large) {
          &__label-text {
            line-height: $filter-option-text-height-medium-screen;
          }

          &__color-swatch {
            line-height: $filter-option-line-height-large-screen;
          }
        }

    }
}

@mixin filterSubMenuActions {
	.sub-menu-actions {

		display: $filter-sub-menu-actions-display;
		margin-top: $filter-sub-menu-actions-margin-top;

		&--mobile {
			background: $filter-sub-menu-actions-mobile-background;
			border-top: $filter-border;
			bottom: 0;
			float: $filter-float;
			padding: $filter-sub-menu-actions-mobile-padding;
			position: fixed;
			z-index: $filter-z-index;

			.sub-menu-actions__action {
				width: 100%;

				&--apply {
					padding-bottom: $column-gutter/2;
				}
			}
		}

		&__action {
			padding: $filter-action-padding;

			span {
				margin-bottom: 0;
			}

			.clear-filters {
				background: $filter-sub-menu-actions-clear-filter-background;
				color: $filter-sub-menu-actions-clear-filter-color;
				cursor: pointer;
				display: inline-block;
				font-size: $filter-sub-menu-actions-clear-filter-font-size;
				font-weight: $filter-sub-menu-actions-clear-filter-font-weight;
				line-height: $filter-sub-menu-actions-clear-filter-line-height;
				margin-left: $filter-sub-menu-actions-clear-filter-margin-left;
				margin-top: $filter-sub-menu-actions-clear-filter-margin-top;
				padding: 15px 0 0 0;
				text-decoration: underline;

			}

			&.sub-menu-actions__action--apply {
				width: $filter-sub-menu-actions-action-apply-width;
				 @include breakpoint(medium) {
					 padding: $filter-sub-menu-actions-action-apply-padding-medium;
					 width: $filter-sub-menu-actions-action-apply-width-medium;
				 }

				.button.primary {

						height: $filter-sub-menu-actions-button-primary-height;
						letter-spacing: $filter-sub-menu-actions-button-primary-letter-spacing;
						margin-top: $filter-sub-menu-actions-button-primary-margin-top;

					@include breakpoint(medium) {
						font-size: $filter-sub-menu-actions-button-primary-medium-font-size;
						font-weight: $filter-sub-menu-actions-button-primary-medium-font-weight;
						height: $filter-sub-menu-actions-button-primary-medium-height;
						margin-bottom: $filter-sub-menu-actions-button-primary-medium-margin-bottom;
						margin-top: $filter-sub-menu-actions-button-primary-medium-margin-top;
					}
				}
			}
		}
	}
}

@mixin activeFilters {
	.active-filters {

		line-height: 1.5;

		&-display {
			overflow: hidden;

			@include breakpoint(large) {
				padding-top: $column-gutter/2;
			}

		}

		&-container {
			@include clearfix;
			background: $active-filters-container-background;
			border-bottom: $filter-border;

			@include breakpoint(large) {
				background: $white;
			}
		}

		&-list {
			@include clearfix;
			margin: 0;
			max-height: $active-filters-list-max-height;
			overflow: hidden;

            @include breakpoint(large) {
            	min-height: 45px;
            }

		}

		&-list__active-filter {
			align-items: center;
			background: $active-filters-background;
			border: $active-filters-border;
			border-radius: $active-filters-border-radius;
			color: $facet-menu-sort-font-color;
			display: flex;
			flex-direction: row;
			float: left;
			height: $filter-option-height - 5px;
			margin: 0 10px 10px 0;
			padding: 0 10px;
			width: auto;

			.active-filter-label {
				display: inline-block;
				padding-right: 5px;
			}

			.active-filter-remove {
				cursor: pointer;
			}

			.active-filter-color {
				@include filtersColorSwatch(20px);
				margin-right: 5px;
			}
		}

		&__visibility-toggle {

			@include clearfix;
			align-items: center;
			color: get-color(primary);
			display: flex;
			font-family: $facets-main-font-family_medium;
			flex-direction: row;
			height: 30px;
			justify-content: center;
			margin-bottom: 5px;
			width: 100%;

			&--more,
			&--less  {
				cursor: pointer;
			}
		}

		&-meta {
			display: flex;
			flex-direction: row;
			font-family: $facets-main-font-family_medium;
			justify-content: center;
			line-height: $filter-option-height;
			text-transform: uppercase;

			@include breakpoint(large) {
				display: block;
			}

			> div {
				cursor: pointer;
			}
		}

		&__active-text {
			text-align: left;
		}

		&__clear-all {
			background-color: transparent;
			color: get-color(secondary);
			font-family: $facets-main-font-family_medium;
			font-size: em-calc(13);
			margin-bottom: 0;
			text-align: right;
			white-space: nowrap;

			&:hover {
				color: get-color(primary);
			}

			&--link {
				border: $filter-border;
				color: $active-filters-clear-all-link-color;
				cursor: pointer;
				float: left;
				font-family: get-font('NeueHelveticaVFDP');
				font-size: $active-filters-clear-all-link-font-size;
				font-weight: $active-filters-clear-all-link-weight;
				letter-spacing: 0.26px;
				line-height: 35px;
				padding: 0 10px;
				text-align: left;
				text-transform: none;
			}

		}

		&__item-count {
			font-family: $facets-main-font-family_medium;
			font-size: em-calc(18);
		}

		&__counter-label {
			font-family: $facets-main-font-family_medium;
			text-transform: none;
		}

	}
}

@mixin filtersColorSwatch($size: 25px) {
	display: inline-block;
	height: $size;
	margin-right: 0.25em;
	outline: $filter-border;
	width: $size;
}

@mixin filterLabels {
	label {
	    font-style: normal;
	    height: $filter-option-height;
		margin-bottom: 0;
		width: 100%;

		&.flex-centered {
			align-items: center;
			display: flex;
			flex-direction: row;
			margin-left: 15px;

			@include breakpoint(medium) {
				span {
					font-family: $sort-filter-font-family-medium;
					max-width: $filter-label-flex-centered-span-max-width-medium;
					width: $filter-label-flex-centered-span-width-medium;
				}
			}

			.product_sort_radio_select {
				margin-top: $product-sort-radio-select-margin-top;

				&:focus {
					+ span {
						outline: $defaultFocusColor auto 1px;
					}
				}

			}
		}

		@include breakpoint(large) {
			height: $filter-option-height-large-screen;
			margin: $filter-option-margin-large-screen;
		}
	}
}

@mixin zeroProductsRetunedMessage {
	.zero-products-returned-message {
		padding: 6em 0;
		text-align: center;
	}
}

@mixin animateSlideInDown {
	animation-name: slideInDown;
	animation-duration: 1s;
	animation-fill-mode: both;
}

@mixin animateMaxHeightOpen {
	animation-name: maxHeightOpen;
	animation-duration: 1s;
	animation-fill-mode: both;
}

@mixin animateMaxHeightCollapse {
	animation-name: maxHeightCollapse;
	animation-duration: 0.5s;
	animation-fill-mode: both;
}

@mixin animateFadeIn {
	animation-name: fadeIn;
	animation-duration: 1s;
	animation-fill-mode: both;
}

@mixin animateFadeOut {
	animation-name: fadeOut;
	animation-duration: 1s;
	animation-fill-mode: both;
}

@mixin animateSlideIn {
	animation-name: slide_in;
	animation-duration: $facets-animate-time;
	animation-fill-mode: both;
}

@mixin animateSlideOut {
	animation-name: slide_out;
	animation-duration: $facets-animate-time;
	animation-fill-mode: both;
}

@mixin animateContainerOpen {
	animation-name: container_open;
	animation-duration: $facets-animate-time;
	animation-fill-mode: both;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@keyframes maxHeightOpen {
	0% {
		max-height: $active-filters-list-max-height;
	}

	100% {
		max-height: $filter-small-screen-options-max-height;
	}
}

@keyframes maxHeightCollapse {
	0% {
		max-height: $filter-small-screen-options-max-height;
	}

	100% {
		max-height: $active-filters-list-max-height;
	}
}

@keyframes slideInDown {
	0% {
  		top: -200px;
	}

  	100% {
  		top: $filter-option-height;
  	}
}

@keyframes slideOutUp {
	0% {
  		top: $filter-option-height;
	}

  	100% {
  		top: -200px;
  	}
}

@keyframes slide_in {
  0% {
    margin-top: -45px;
    opacity: 0;
  }

  100% {
    margin-top: 0;
    opacity: 1;
  }
}

@keyframes container_open {
	0% {
		top: -50px;
	}

	100% {
		top: 0;
	}
}

@keyframes slide_out {
  0% {
    margin-top: 0;
    opacity: 1;
  }

  100% {
    margin-top: -45px;
    opacity: 0;
  }

}

@if $include-html-top-bar-filters-classes != false {

	.off-canvas-content.product-finder-active .body-container {
		.top-bar-facets,
		.off-canvas-filter-content {
			display: none;
		}
	}

	// building the facets styles
	.top-bar-facets {
		margin-top: $top-bar-filter-facet-margin-top;
		min-height: $top-bar-filter-facet-min-height;
		z-index: $top-bar-filter-facet-Zindex;

		&.stuck-to-top {
			margin-top: 0;
			position: fixed;
			left: 0;
			right: 0;
			top: $topnav-sticky-minimized--height__small;
			@include breakpoint(medium) {
				top: $topnav-sticky-minimized--height__medium;
			}
			@include breakpoint(large) {
				top: $topnav-sticky-minimized--height__large;
			}
			.active-filters-container {
				display: $stuck-to-top-active-filters-display-small;
				@include breakpoint(medium) {
					display: $stuck-to-top-active-filters-display-medium;
				}
				@include breakpoint(large) {
					display: $stuck-to-top-active-filters-display-large;
				}
			}
		}


		@include breakpoint(large) {
			padding: 0 $column-gutter/2;
			position: relative;
			@include grid-row;
		}
	}

	.facets-container {
		@include filtersResets;
		@include filterMobileContainer;
		@include filterLabels;
		@include filterMenu;
		@include filterSubMenuOptions;
		@include filterSubMenuActions;
		@include activeFilters;
	}

	// attaching animations to elements
	.filter-menu__sub-menu--filter-container,
	.filter-menu__sub-menu--sort-container {

		@include breakpoint(large) {
			position: relative;
			@include animateMaxHeightOpen;
		}
	}

	.filter-mobile-container.animate-open,
	.filter-mobile-container.animate-open > * {
		@include animateSlideIn;
	}

	.filter-mobile-container.animate-close,
	.filter-mobile-container.animate-close > * {
		@include animateSlideOut;
	}

	.active-filters-container {

		@include breakpoint(large) {

			&.animate-open {
				@include animateContainerOpen;
			}
		}
	}

	.active-filters-list {
		clear: both;
		display: $active-filters-list-display;
		@include animateMaxHeightCollapse;

		&.active-visible {
			@include animateMaxHeightOpen;
		}
	}

	.active-filters-list__active-filter {
		cursor: pointer;

		&.animate-add {
			@include animateSlideIn;
		}

		&.animate-remove {
			@include animateSlideOut;
		}
	}

	.left-aligned  {
		@include breakpoint(large) {
			padding-left: 0;

			&::before,
			&::after {
				display: inline;
			}

			& ~ section#catalog-container.catalog-container {
				width: 100 - $left-facet-width;
			}

			.facets-container .filter-menu__sub-menu-header.active .filter-menu__title-text {
				color: get-color(secondary);
			}

			.plp-sticky-sidebar {
				float: left;
				width: $left-facet-width;
			}

			.left-filter-component {
				display: flex;
				flex-flow: column;
				float: left;
				left: 0;
				padding: $column-gutter/2 $column-gutter 0 0;
				position: relative;
				top: 0;

				.filter-menu-container {
					order: 2;
				}

				.filter-menu__sub-menu {
					border-bottom: $filter-border;
					width: 100%;

					&-header,
					&-header.active {
						border: 0;
						border-bottom: 0;
					}

					&-options {
						border: 0;
						left: 0;
						position: relative;
						top: 0;
						width: 100%;
					}
				}
			}

			.active-filters-container {
				display: flex;
				flex-flow: column;
			}

			.active-filters-display {
				overflow: visible;
				width: 100%;

				.active-filters-list {
					overflow: visible;
				}
			}

			.active-filters-list-container {
				order: 2;
			}

			.clear-all-container {

				.active-filters__item-count,
				.active-filters__counter-label {
					display: none;
				}
			}

			.left-sort-component {
				border-bottom: $filter-border;
				display: block;
				float: right;
				padding: $column-gutter/2;
				width: 100 - $left-facet-width;

				.left-sort-product-count {
					float: left;
					line-height: $left-facet-sort-bar-height;
				}

				.filter-menu__sub-menu {
					float: right;
					width: 5 + $left-facet-width;
				}
			}
		}
	}

	.left-filter-component .filter-menu-header__close-btn {
		background: transparent;
		color: $black;
		height: 100%;
		margin: 0;
		padding: 0;
		transition: none;

		.icon-close {
			font-size: 24px;
			right: 8px;
			top: 0;
		}

		&:focus,
		&:hover {
			color: $black;
		}
	}

	.plp-sticky-sidebar__inner {
		transform: $plp-sticky-sidebar__inner-transform;
		will-change: $plp-sticky-sidebar__inner-will-change;
	}

	@include breakpoint(large) {
		.plp-sticky-sidebar__inner {
			transform: translate3d(0, 0, 0);
			will-change: position, transform;
		}
	}

	@include zeroProductsRetunedMessage;

	.sub-menu-actions {
		align-items: $facet-sub-menu-actions-align-items;
		width: $facet-sub-menu-actions-width;
	}
}
