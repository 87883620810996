@import 'util/common-mixins';

@mixin napa-custom-tooltip($color: get-color(nobel), $position: absolute) {
  color: $color;
  float: right;
  font-size: em-calc(16);
  margin: 0;
  text-decoration: none;

  &::before {
    @include icomoonFont($icon-info);
  }

  @if $position == absolute {
    position: $position;
    right: 8px;
    top: 36px;

    @include breakpoint(medium) {
      right: -24px;
      top: 34px;
    }
  } @else {
    bottom: 46px;
    position: $position;
    right: 10px;

    @include breakpoint(medium) {
      bottom: 42px;
      right: -24px;
    }
  }
}

@mixin napa-circular-color-swatch($size, $sizeMedium) {
  border: 2px solid $white !important;
  border-radius: 50%;
  box-shadow: 0 0 0 rem-calc(1) $light-gray;
  box-sizing: border-box;
  // Need to force the height and width since we're changing that on a surface level.
  height: $size !important; // sass-lint:disable-line no-important
  margin: rem-calc(1) rem-calc(12) rem-calc(12) rem-calc(1);
  width: $size !important; // sass-lint:disable-line no-important

  @include breakpoint(medium) {
    height: $sizeMedium !important; // sass-lint:disable-line no-important
    margin: rem-calc(1) rem-calc(10) rem-calc(10) rem-calc(1);
    width: $sizeMedium !important; // sass-lint:disable-line no-important
  }

  &.selected {
    border: inherit;
    box-shadow: 0 0 0 rem-calc(1) $black;
  }
}
