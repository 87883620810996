// =============================================================================
// quickview.scss
// styles for Quickview
// mobile-first
// =============================================================================
$productImgH: 74px !default;
$productDrawerEnabled: false !default;
$productActionPriceEnabled: false !default;
$facetBoxHoverColor: null !default;
$facetBoxSelectedBorder: 2px solid $facetBoxSelectedColor !default;
$productViewWidthOnQuickView:  50% !default;
$wcsVideoPlayerAltImgSizeForDestktop: 72px !default;
$altImgSizeCheckoutQuickviewDesktop: $productImgH !default;
$altImgCheckoutQuickviewSizeReducedBy: 26px !default;
$altImgSizeCheckoutQuickviewTablet:  $altImgSizeCheckoutQuickviewDesktop - $altImgCheckoutQuickviewSizeReducedBy !default;
$productBadgeVisibilityOnQuickView: none !default;
$productRatingSizeOnQuickview: 1.6em !default;
$productRatingLineHeightOnQuickview:  1.6em !default;
$quickshopBtnSizeBgColor: null !default;
$quickshopBtnSizeBgColorOutOfStock: null !default;
$quickshopBtnSizeColorOutOfStock: null !default;
$attrSizeBoxBorder: 1px solid $brandColorPalette3 !default;
$attrSizeBoxFontSize: null !default;
$quickViewColorSwatchContainerMargin: null !default;
$quickViewColorSwatchButtonMargin: 0 0 7px !default;
$outOfStockSizeButtonBackground: null !default;
$quick-view-product-view-promo-badge-display: null !default;
$quickViewProductViewsContainerDisplay: none !default;
$quickViewProductActionButtonsMargin: 15px 5px 15px 0 !default;
$quickViewProductActionButtonsMarginMedium: 15px 5px 15px 0 !default;
$quickViewValidationMsgTopPadding: null !default;
$quickViewContainerControlbarBottom: null !default;
$quickViewContainerControlbarPadding: null !default;
$quickViewContainerCanvasTop: null !default;
$quickViewContainerSwatchTop: null !default;
$quickViewContainerZoomviewHeight: null !default;
$quickViewContainerS7panbuttonTop: null !default;
$quickViewContainerZoomviewFullscreenDisplay: null !default;

// quickview box
$quickview-box-margin-bottom: null !default;
$quickview-box-margin-bottom-medium: null !default;

// quickview
$quickview-product-actions-primary-position: null !default;
$quickview-product-actions-primary-position-left: null !default;
$quickview-product-actions-primary-position-bottom: null !default;
$quickview-product-actions-primary-width: null !default;
$quickview-product-actions-primary-padding: null !default;
$quickview-product-actions-primary-margin-bottom: null !default;
$quickview-product-actions-primary-background-color: null !default;
$quickview-product-actions-primary-box-shadow: null !default;
$quickview-product-action-primary-add-to-cart-button-margin: null !default;

// quickview medium and up
$quickview-product-actions-primary-background-color-medium: null !default;
$quickview-product-actions-primary-position-bottom-medium: null !default;
$quickview-product-actions-primary-box-shadow-medium: null !default;
$quickview-product-actions-primary-position-left-medium: null !default;
$quickview-product-actions-primary-padding-medium: null !default;
$quickview-product-actions-primary-position-medium: null !default;
$quickview-product-actions-primary-width-medium: null !default;
$quickview-product-actions-primary-margin-bottom-medium: null !default;
$quickview-product-size-and-fit-header-width: 40% !default;

$revealOverlayMultipleOpenedTop: null !default;
$revealOverlayMultipleOpenedWidth: null !default;
$quickview-original-price-color: $originalPriceColor !default;
$product-price-span-font-size: null !default;
$description-section-margin-bottom: null !default;

@import 'product/wcs-pdp-video-styles';
@import 'global/z-indexes';

#quickview-box {
	@include grid-row;
	background: $quickViewBGColor;
	margin-bottom: $quickview-box-margin-bottom;
		@include breakpoint(medium) {
			margin-bottom: $quickview-box-margin-bottom-medium;
		}

	@import 'product/image-viewer'; //mobile-first
	@import 'product/product-form'; //mobile-first
	@import 'product/authorized-retailer';  // not mobile-first but no need to convert
	@import 'product/availability-notification'; // mobile-first
	@import 'product/product-detail-drawer'; //mobile-first

    #product-content-container {
        padding: 0;
        @include breakpoint(medium) {
        	float: $global-right;
            width: 50%;
        }
        @include breakpoint(large) {
			float: $global-right;
			width: grid-column($pdpContentWidth);
        }
    } // #product-content-container

    #product-form {
        width: 100%;

        .product-attr-form>section.step-container.attr-color {
            @include breakpoint(medium) {
                padding-top: 20px;
            }
            @include breakpoint(large) {
                padding-top: initial;
            }

            .step-content {
            	margin: $quickViewColorSwatchContainerMargin;
            }

            .attr-box {
				margin: $quickViewColorSwatchButtonMargin;
		 	}
        }

        section {
            float: left;
            width: 100%;

            &.step-container:not(.attr-color) {
                .step-header {
                    width: $quickview-product-size-and-fit-header-width;
                }

                .step-content {
                    width: 60%;
                }
            }

            &.step-container.size-step-container .step-header {
                span,
                h2 {
                    line-height: get-form-input-height();
                }
            }
        }

		.product-actions-primary {
			background: $quickview-product-actions-primary-background-color;
			bottom: $quickview-product-actions-primary-position-bottom;
			box-shadow: $quickview-product-actions-primary-box-shadow;
			left: $quickview-product-actions-primary-position-left;
			padding: $quickview-product-actions-primary-padding;
			position: $quickview-product-actions-primary-position;
			width: $quickview-product-actions-primary-width;

			@include breakpoint(medium) {
				background: $quickview-product-actions-primary-background-color-medium;
				bottom: $quickview-product-actions-primary-position-bottom-medium;
				box-shadow: $quickview-product-actions-primary-box-shadow-medium;
				left: $quickview-product-actions-primary-position-left-medium;
				padding: $quickview-product-actions-primary-padding-medium;
				position: $quickview-product-actions-primary-position-medium;
				width: $quickview-product-actions-primary-width-medium;
			}

			.product-content-form-add-to-bag {
				margin: $quickview-product-action-primary-add-to-cart-button-margin;
					@include breakpoint(medium) {
						margin-bottom: $quickview-product-actions-primary-margin-bottom-medium;
					}
			}
		}
    } //#product-form

	.product-reviews-container {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		padding-bottom: $pdpSpacingLarge;
	}

	.product-content-info-rating {
		font-size: $productRatingSizeOnQuickview;
		margin-right: 0.5rem;
	}

	.product-content-info-review-count-link,
	.product-content-info-write-review-link {
		line-height: $productRatingLineHeightOnQuickview;
	}

	#product-nav {
		display: none;

		@include breakpoint(large) {
			@include grid-column(12, 0);
			border-top: 1px solid $brandColorPalette3;

			figure {
				@include grid-column(2);
			}

			span {
				position: relative;
				top: 20px;
			}

			.title-nav {
				@include grid-column(10, 0);
				line-height: 25px;
			}
		}
	} //#product-nav

	#product-views-container {
		display: $quickViewProductViewsContainerDisplay;

        @include breakpoint(medium) {
            display: block;
            width: $productViewWidthOnQuickView;
            float: left;
            background-color: $quickViewBGColor;
            padding: 0 $pdpSpacingLarge $pdpSpacingLarge 0;

            .product-img-full {
                height: auto;
                width: auto;

                img {
                    width: 100%;
                }
            }
        }

        @include breakpoint(large) {
            @include grid-column($pdpViewerWidth);
            padding: 0 $pdpSpacingLarge $pdpSpacingLarge 0;
        }

		.product-img-alt-view {
			margin: $pdpSpacingLarge 0;
		}

		.product-video-thumb {
			span.icon-youtube,
			span.icon-arrow-right-indicator {
				line-height: $altImgSizeForTablet;

				@include breakpoint(large) {
					line-height: $wcsVideoPlayerAltImgSizeForDestktop;
				}
			}
		}

		.product-views-images {
			position: relative;
		}

		.youtube-product-video-container-dimensions {
			&.vjs-fullscreen {
                margin-top: 0;
            }
		}

		.product-views-promo-badge {
			display: $quick-view-product-view-promo-badge-display;
		}
	} //#product-views-container

	.more-details {
		padding: 0 0 $pdpSpacingLarge $pdpSpacingLarge;
	}

	.description-section {
		margin-bottom: $description-section-margin-bottom;
		margin-top: $description-section-margin-bottom;
	}
	.product-price {
		margin-bottom: $description-section-margin-bottom;
		.current,
		span {
			color: $priceFontColor;
			font-size: $product-price-span-font-size;
			line-height: normal;

			&.offer-price {
				color: $offerPriceColor;
				display: block;
			}

			&.original-price {
				color: $quickview-original-price-color;
				display: block;
			}

			&.old-price {
				text-decoration: line-through;
			}
		} // .current, span
	} //.product-price

	.prev-nav,
	.next-nav {
		@include grid-column(6, 0);

		& img {
			padding-bottom: 1px;
		}

		.next-btn,
		.prev-btn {
			@include grid-column(1, 0);
			height: 20px;
			text-indent: -999%;
		}

		a {
			@include grid-column(11, 0);
			display: block;
		}
	}

	.prev-btn {
		left: 10px;
	}

	.next-nav .title-nav {
		text-align: right;
	}

	.social-media div {
		float: left;
	}

	.link-button {
		@include setFontStyle($LinkbtnFontStyle);
	}
} //#quickview-box

#quickview-box.pdp-swatches,
#quickview-box.pdp-drawer {
	#product-content-container {
		margin: 1em;

		@include breakpoint(medium) {
			margin: 30px 0;
		}

		.validation-message {
			padding-top: $quickViewValidationMsgTopPadding;
		}
	} //#product-content-container

	#product-form {
		 .attr-color .attr-box {
			background: none;
			margin: $quickViewColorSwatchButtonMargin;
			padding: 0;
		 }

		 > .step-container .step-content {
		 	width: 100%;
		 }

		 .step-content {
		 	button {
		 		background-color: transparent;
				color: #e72b1e;
				height: auto;
				padding: 0;
		 	}
		 }

		 .attr-size .attr-box {
			background-color: $quickshopBtnSizeBgColor;
			border: $attrSizeBoxBorder;
			float: left;
			font-size: $attrSizeBoxFontSize;
			font-weight: bold;
			height: 45px;
			letter-spacing: 0;
			line-height: 45px;
			margin: 0 10px 10px 0;
			min-width: 45px;
			padding: 0;
			text-align: center;

			&.out-of-stock {
				background: $outOfStockSizeButtonBackground;
				background-color: $quickshopBtnSizeBgColorOutOfStock;
				color: $quickshopBtnSizeColorOutOfStock;
			}

			&.selected {
				border: 2px solid $facetBoxHoverColor;
			}

			&.selected.clicked,
			.clicked {
				background-color: $facetBoxSelectedBackroundColor;
				border: $facetBoxSelectedBorder;

				&.out-of-stock {
					background-color: $quickshopBtnSizeBgColorOutOfStock;
				}
			}
		 }

		.quantity,
		.online-dealer-section {
			display: none;
		}

		.product-actions-secondary .link-button {
			float: left;
		}

		.product-actions .button {
			margin: $quickViewProductActionButtonsMargin;
			padding: 16px 15px 18px;

			@include breakpoint(medium) {
				margin: $quickViewProductActionButtonsMarginMedium;
			}
		}

		.large {
			width: 120px;
		}
	} //#product-form

	#product-views-container .product-img-full .promo-badge {
 		display: $productBadgeVisibilityOnQuickView;
 	}

 	.size-chart {
		display: none;
		text-align: center;

		.close-button {
			position: relative;
			top: 0;
			right: 0;
			float: right;
			font-size: initial;
		}

		.icon-close {
			font-size: 1.5em;
		}

	} //.size-chart

  //This is needed for checkout edit hopup//
	&.product-exchange-select-item-quickview {
		.product-img-alt-view .attr-box {
			@include breakpoint(medium) {
				height: $altImgSizeCheckoutQuickviewTablet;
				width: $altImgSizeCheckoutQuickviewTablet;
			}

			@include breakpoint(large) {
				height: $altImgSizeCheckoutQuickviewDesktop;
				width: $altImgSizeCheckoutQuickviewDesktop;
			}
		}
	}
} //#quickview-box.pdp-swatches

//Exchange Shop Cart (Quickview Hopup)
	#quickview-box #product-form .product-attr-form > section.step-container.product-exchange:not(.attr-color) .step-header {
		width: 100%;
	}

//Product Action Prices
@if ($productActionPriceEnabled) {
	//tablet above
    @include breakpoint(medium) {
    	#quickview-box .product-actions-product-price-container {
    		display: none;
    	}
    }
}

.s7container {

	[mode="fullscreen"] .s7zoomview {
		 > div {
			 &:nth-last-child(3) {
				 display: $quickViewContainerZoomviewFullscreenDisplay;
			 }
		}
	}

	.s7zoomview {
		height: $quickViewContainerZoomviewHeight;
	}

	canvas {
		top: $quickViewContainerCanvasTop;
	}

	.s7swatches {
		top: $quickViewContainerSwatchTop;
	}

	.s7controlbar {
		bottom: $quickViewContainerControlbarBottom;
		padding: $quickViewContainerControlbarPadding;
	}
}

.vfdp-s7-viewer {
	.s7panleftbutton,
	.s7panrightbutton {
     top: $quickViewContainerS7panbuttonTop;
	}
}

@include breakpoint(medium) {
	.reveal-overlay {
		.multiple-opened {
			top: $revealOverlayMultipleOpenedTop;
			width: $revealOverlayMultipleOpenedWidth;
		}
		.quickview-modal {
			top: $revealOverlayMultipleOpenedTop;
		}
	}
}